<template>
  <v-input
    :rules="[cbRule]"
    v-model="answer"
    ref="question"
  >
    <div style="width: 100%">
      <template v-for="(item, index) in choices">
        <v-checkbox 
          class="mt-0"
          v-model="selections"
          :key = "index"
          :label = "item.label"
          :value = "item.value"
          :disabled="disabled"
          multiple
        ></v-checkbox>
      </template>
      <v-text-field
        v-model="other_value"
        :rules="[required]"
        label="Please explain"
        :disabled="disabled"
        v-if="selections.indexOf('_other') > -1"
      ></v-text-field>    
    </div>
  </v-input>
</template>

<script>

  import { questionMixin } from "../mixins.js"

  export default {
    name: "qCheckboxes",
    mixins: [questionMixin],
    other_value: null,
    data: function() {
      return {
        default_rules: [],
        has_other: false,
        selections: [],
        other_value: null,
        required(v) {
          return !!v || "You must answer this question"
        },
        cbRule(v) {
          if(v == undefined) v = []
          if(v.length == 0) {
            return "You must select at least one answer"
          }
          if(v.indexOf(null) > -1 || v.indexOf("") > -1) {
            return "Please explain your answer of 'Other'"
          }
          return true
        }
      }
    },
    computed: {
      choices: function() {
        let x = this.question.options
        let has_other = false
        if(Object.keys(x).indexOf("choices") > -1) {
          x = this.question.options.choices
          if(Object.keys(this.question.options.include_other) > -1) {
            has_other = this.question.options.include_other
          }
        }
        if(typeof x[0] === "string") {
          x = x.map(y => {return {label: y, value: y}})
        } 
        if(has_other) {
          x.push({label: "Other", value: "_other"})
        } 
        return x
      },
    },
    methods: {
      preprocessAnswer(v) {
        let selections
        if(!Array.isArray(v)) {
          selections = [...this.makeArray(v)]
        } else {
          selections = [...v]
        }
        let other_value_index = selections.findIndex(x => this.question.choices.indexOf(x) == -1)
        if(other_value_index > -1) {
          this.other_value = selections[other_value_index]
          selections.splice(other_value_index, 1)
          selections.push("_other") 
        }
        this.selections = selections
        return this.makeArray(v)
      },
      makeAnswer(selections = [], other_value = null) {
        let answer = [...selections]
        let other_index = answer.findIndex(x => x === "_other")
        if(other_index > -1) {
          answer.splice(other_index, 1)
          answer.push(other_value)
        }
        this.answer = answer
      },
      makeArray: function(x) {
        if(!Array.isArray(x)) {
          if(typeof x != "undefined" && x !== null) {
            x = [x]
          } else {
            x = []
          }
        }
        return x
      }
    },
    watch: {
      selections(v, o) {
        if(v.toString() !== o.toString()) {
          if(v.length > o.length) {
            let n = v.find(x => o.indexOf(x) == -1)
            if(n == "None") {
              v = ["None"]
            } else {
              let ni = v.findIndex(x => x == "None")
              if(ni > -1) {
                v.splice(ni, 1)
              }
            }
          }
          this.makeAnswer(v, this.other_value)
        }
      },
      other_value(v, o) {
        if(v !== o) {
          this.makeAnswer(this.selections, v)
        }
      }
    }
  }
</script>
<template>
  <v-bottom-sheet 
    v-model = "visible" 
    no-click-animation
    class="pa-5"
    style="background-color: white"
  >
    <v-sheet class = "pa-8">
      <v-row justify="center">
        <v-spacer></v-spacer>
        <v-col>       
          {{ $t('consent.attest.select-choice') }}
          <v-radio-group v-model='status'>
            <v-radio value = "consented">
              <template slot="label">
                <span v-html="$t('consent.attest.consent')"></span>
              </template>
            </v-radio>
            <v-radio value = "declined"> 
              <template slot = "label">
                <span v-html="$t('consent.attest.decline')"></span>
              </template></v-radio>
            <v-radio value = "questions">                    
              <template slot = "label">
                <span v-html="$t('consent.attest.questions')"></span>
              </template>
            </v-radio> 
          </v-radio-group>
          <v-btn 
            @click="$emit('submit')" 
            block 
            color="accent"
            :disabled="status===null"
          >
            {{ $t('gen.submit') }}
          </v-btn>     
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>

export default {
  name: "consentBottomSheet",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(x) {
        this.$emit('input', x)
      }
    },
    status: {
      get() {
        return this.$store.getters['consent/status']
      },
      set(x) {
        this.$store.commit("consent/SET_STATUS", x)
      }
    }
  }
}
</script>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","max-width":"1000px","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"id":"fullConsentCard"}},[_c('p-card-title',{attrs:{"title":_vm.title,"icon":"fa-signature"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.printConsent}},[_c(VIcon,[_vm._v("fa-print")])],1)]},proxy:true}])}),_c(VDivider),_c(VCardText,{staticClass:"pt-6",attrs:{"id":"consent","height":"80%"}},[_c('span',{staticClass:"full-consent mb-5",domProps:{"innerHTML":_vm._s(_vm.consent_body)}})]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"px-8",attrs:{"color":"accent","rounded":"","id":"fullConsentCloseBtn"},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t('gen.close')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app id="ppmi_participant_portal">
    <v-app-bar
      app
      color="primaryDark"
      dark
      clipped-left
      elevate-on-scroll
      style = "z-index: 100;"
    >
    <v-app-bar-nav-icon @click="sidebar = !sidebar" v-if="$vuetify.breakpoint.mobile" aria-label="View Menu" id="sidebarBtn"></v-app-bar-nav-icon>
      <v-toolbar-title :style="{'padding-left': $vuetify.breakpoint.mobile ? '5px' : null}">
        <div>
          <router-link router :to="{name: 'home'}" style="text-decoration: none; color: white">
            <img id="titleLogo" :src = "require('@/assets/logo_white.png')" :height="$vuetify.breakpoint.mobile ? '40px' : '50px'" style="vertical-align: middle" alt="titlebar logo" />
            <span id="titleText" class="ml-3 mr-5" style = "font-family: 'Acherus Grotesque Regular'; font-weight: bold; line-height: 30px;">
              PPMI 
              <span class="font-weight-light" v-if="$vuetify.breakpoint.mobile">
                {{ $t('gen.portal') }}
              </span>
              <span class="font-weight-light" v-else>
                {{ $t('gen.prt-portal') }}
              </span>
            </span>
          </router-link>
        </div>
      </v-toolbar-title>
      <v-toolbar-items>
        <template v-for="(item, i) in menu_items">
          <v-btn
            v-if="checkBtnVis(item, 'appBar')"
            text
            :router="isLink(item)"
            :link="!isLink(item)"
            :to="isLink(item) ? item.link : null"
            @click="!isLink(item) ? takeAction(item.action) : null"
            class="ml-5"        
            :key="i"
            :id="'appbar'+item.name+'btn'"
          ><v-icon left>{{item.icon}}</v-icon>{{item.text}}</v-btn>
        </template>
        <p-participant-reset v-if="loggedIn && (dev || stg)"></p-participant-reset>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <locale-switcher v-if="!hideSwitcher" />
      <span v-if="loggedIn" id="appBarGreeting">
        <span v-if="!$vuetify.breakpoint.mobile">
          {{ $t('gen.welcome') }} {{user}}!
        </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn id="logoutButton" icon @click="logout" v-bind="attrs" v-on="on" aria-label="Log out">
              <v-icon>fa-sign-out-alt</v-icon>
            </v-btn>          
          </template>
          <span>{{ $t('gen.logout') }}</span>
        </v-tooltip>
      </span>
      <span v-else>
        <v-btn id = "appBarLoginBtn" text @click="login" class = "mr-3" aria-label="Log in">
          <span v-if="!$vuetify.breakpoint.mobile">{{ $t('gen.login') }}</span>
          <v-icon class = "ml-2">fa-sign-in-alt</v-icon>
        </v-btn>
      </span>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebar"
      v-if="$vuetify.breakpoint.mobile && sidebar"
      app
      clipped
      color="grey lighten-4"
      style="z-index: 200;"
    >
      <v-list
        dense
        class="grey lighten-4"
      >
        <v-list-item v-if="loggedIn">
          <v-list-item-content>
            {{ $t('gen.welcome') }} {{user}}!
          </v-list-item-content>
          <v-list-item-action right>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="logout" v-bind="attrs" v-on="on"><v-icon>fa-sign-out-alt</v-icon></v-btn>
              </template>
              <span>{{ $t('gen.logout') }}</span>
            </v-tooltip>
          </v-list-item-action>  
        </v-list-item>
        <template v-for="(item, i) in menu_items">
          <span v-if="isLink(item)" :key="i">
          <v-list-item
            v-if="checkBtnVis(item,'sideBar')"
            link
            :router="isLink(item)"
            :to="isLink(item) ? item.link : null"
            :id="'sidebar'+item.name+'link'"
          >
            <v-list-item-action>
              <v-icon color="secondary">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </span>
          <span v-else :key="i">
          <v-list-item
            v-if="item.auth ? loggedIn : true"
            link
            @click='takeAction(item.action)'
            :id="'sidebar'+item.name+'link'"
          >
            <v-list-item-action>
              <v-icon color="secondary">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </span>
        </template>
      </v-list>
      <div style="font-size: 0.7em; color: #DDD">v{{appVersion}}</div>
    </v-navigation-drawer>
    <v-main style = 'z-index: 99' id="main" ref="main">
      <router-view></router-view>
    </v-main>
    <span style = "z-index: 99">
      <question-box @problem="problemDialog=true" v-model="helpDialog" />
      <!-- <problem-box v-model="problemDialog" /> -->
      <snackbar-alerts></snackbar-alerts>
    </span>
    <div style = "height: 30px"></div>
    <v-footer app color="primary" style="z-index: 100;" dark v-if="!$vuetify.breakpoint.mobile">
      <v-spacer></v-spacer>
        <span class="mx-2" id="copyright">&#169;2020-{{currentYear}} Indiana University</span>
          | 
        <span @click="showPrivacyNotice = true" class="fake-link mx-2" id="privacyNoticeLink">{{ $t('gen.privacy') }}</span> 
          | 
        <span @click="showDisclaimer = true" class="fake-link mx-2" id="disclaimerLink">{{ $t('gen.disclaimer') }}</span> 
      <v-spacer></v-spacer>
      <span id="version" style="position: absolute; font-size: 0.7em; color: #DDD">v{{appVersion}}</span>
    </v-footer>
    <div id = "watermark-wrapper" :class="{'branch-warn': branch == 'stg' || branch == 'master'}">
      <div class = 'text-center' id = "watermark" v-if="watermark" v-html="watermark"></div>
    </div>
    <p-privacy-notice v-model="showPrivacyNotice"></p-privacy-notice>
    <p-disclaimer v-model="showDisclaimer"></p-disclaimer>
    <v-progress-circular
      v-if="loading"
      class = "centered"
      indeterminate
      color="primaryDark"
      size="100"
      width="5"
    >{{ $t('gen.loading') }}</v-progress-circular>  
  </v-app>
</template>

<script>
import localeSwitcher from './ppmi-portal-components/src/components/localeSwitcher.vue';
import browserLocales from "@/config/browser-locales"

export default {
  components: { localeSwitcher },
  name: 'App',
  data: () => ({
    navigationAvailable: true,
    showPrivacyNotice: false,
    showDisclaimer: false,
    sidebar: false,
    helpDialog: false,
    problemDialog: false,
    loading: false,
    // menu_items: [
    //   {name: "home", icon: "fa-fw fa-home", text: "Home", link: "home", auth: false},
    //   {name: "aboutme", icon: "fa-fw fa-user", text: "About Me", link: "contact-info", auth: true},
    //   {name: "faqs", icon: "fa-fw fa-question", text: "FAQs", link: "/faq", auth: false},
    //   {name: "contactus", icon: "fa-fw fa-comments", text: "Contact Us", action: "showHelp", auth: false, appBar: false},
    //   {name: "privacynotice", icon: "fa-fw fa-lock", text: "Privacy Notice", action: "showPrivacyNoticeDialog", auth: false, appBar: false},
    //   {name: "disclaimer", icon: "fa-fw fa-exclamation", text: "Disclaimer", action: "showDisclaimerDialog", auth: false, appBar: false}
    // ],
  }),
  computed: {
    menu_items() {
      return [
          {name: "home", icon: "fa-fw fa-home", text: this.$t('gen.home'), link: "/", auth: false},
          {name: "aboutme", icon: "fa-fw fa-user", text: this.$t('gen.aboutme'), link: "contact-info", auth: true},
          {name: "faqs", icon: "fa-fw fa-question", text: this.$t('gen.faqs'), link: "/faq", auth: false},
          {name: "contactus", icon: "fa-fw fa-comments", text: this.$t('gen.contact'), action: "showHelp", auth: false, appBar: false},
          {name: "privacynotice", icon: "fa-fw fa-lock", text: this.$t('gen.privacy'), action: "showPrivacyNoticeDialog", auth: false, appBar: false},
          {name: "disclaimer", icon: "fa-fw fa-exclamation", text: this.$t('gen.disclaimer'), action: "showDisclaimerDialog", auth: false, appBar: false}
      ]
    },
    dev() {
      return process.env.NODE_ENV === "development"
    },
    stg() {
      return process.env.VUE_APP_WATERMARK === "Staging"
    },
    branch() {
      return process.env.VUE_APP_GIT_BRANCH || ""
    },
    appVersion() {
      return process.env.PACKAGE_VERSION
    },
    watermark: function() {
      if(process.env.VUE_APP_WATERMARK) {
        let wm = ` ${process.env.VUE_APP_WATERMARK}`
        if(process.env.NODE_ENV == "development") {
          wm = ` ${process.env.VUE_APP_GIT_BRANCH} `
        }
        return wm.repeat(500);
      } else {
        return null
      }
    },
    loggedIn: function() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    consented: function() {
      return this.$store.getters['participant/isConsented'];
    },
    user: function() {
      return this.$store.getters["participant/first_name"]
    },
    user_status: function() {
      return this.$store.getters["participant/status"]
    },
    currentYear: function() {
      let d = new Date()
      return d.getFullYear()
    },
    hideSwitcher(){
      if(this.loggedIn && this.$store.getters["participant/preferred_lang"]){
        return true
      }else{
        return false
      }
    }
  },
  updated() {
    this.loading = false
  },
  async beforeUpdate() {
    try {
      if(!this.$store.getters["auth/loggedIn"]) {
        switch(this.$store.getters['auth/logoutReason']) {
          case 'user_initiated':
            this.$store.dispatch('snackbar/display', {message: "You have been logged out."})
            break
          case null: 
            break;
          default:
            this.$store.dispatch("snackbar/display", {message: "<p>There was a problem with login. We are sorry for the inconvenience.</p>If the problem persists please contact us for help.", color: 'error'})
            break
        }
        this.$store.commit("auth/SET_LOGOUT_REASON", null)
      }
    } catch(err) {
      this.$log.error(err)
    }
  },
  methods: {
    checkBtnVis(item, loc = "appBar") {
      let show = true

      if(loc === "appBar") {
        if(Object.keys(item).indexOf("appBar") < 0) {
          item.appBar = true
        }          
        if(!item.appBar) {
          show = false
        } else if(this.$vuetify.breakpoint.mobile) {
          show = false
        } else if(item.auth) {
          if(this.user_status !== "Active" || !this.consented || !this.loggedIn) {
            show = false
          }
        }        
      } else if(loc === "sideBar") {
        if(Object.keys(item).indexOf("sideBar") < 0) {
          item.sideBar = true
        }          
        if(!item.sideBar) {
          show = false
        } else if(item.auth) {
          if(this.user_status !== "Active" || !this.consented || !this.loggedIn) {
            show = false
          }
        }    
      } else {
        this.$log.error("`loc` should be either 'sideBar' or 'appBar'")
        show = false
      }

      return show
    },
    showPrivacyNoticeDialog() {
      this.showPrivacyNotice = true
    },
    showDisclaimerDialog() {
      this.showDisclaimer = true
    },
    login() {
      this.$store.dispatch("auth/LOGIN")
    },
    logout() {
      this.$store.dispatch('auth/LOGOUT', {reason: "user_initiated"});
      if(this.$router.currentRoute.name !== "home") {
        this.$router.push({name: 'home'})
      }
    },
    isLink(item) {
      return Object.keys(item).indexOf("link") > -1
    },
    takeAction(action) {
      this[action]()
    },
    showHelp() {
      this.helpDialog = true
    },
    getBrowserLang(){
      let browser = window.navigator.language
      browser = browser.split('-')[0]
      if(browser in browserLocales){
        this.$store.commit("userSettings/SET_CURRENT_LOCALE", browserLocales[browser])
        this.$i18n.locale = browserLocales[browser]
      }else{
        this.$store.commit("userSettings/SET_CURRENT_LOCALE", 'en-US')
        this.$i18n.locale = 'en-US'
      }
    }
  },
  mounted(){
    //check store for locale being set
    if(this.$store.getters["userSettings/current_locale"]){
      this.$i18n.locale = this.$store.getters["userSettings/current_locale"]
    }else{
      this.getBrowserLang()
    }
  }
};
</script>

<style>

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    z-index: 300;
  }

  strong {
    font-size: 1.2em;
    font-weight: bold;
    color: #f7931e !important;
  }

  p, li {
    color: black !important;
  }

  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }

  .switch-center {
    display: flex;
    justify-content: center;
  }

  .fake-link {
    cursor: pointer;
    text-decoration: underline;
  }

  #watermark-wrapper {
    z-index: 50;
    opacity: 0.15;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  #watermark {
    width: 100%;
    height: 100%;
    word-spacing: 40pt;
    line-height: 40pt;
    font-family: sans-serif;
    font-size: 10pt;
    font-weight: bold;
  }

  .branch-warn {
    color: red;
    opacity: 0.5 !important;
  }

</style>
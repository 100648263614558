<template>
  <v-window-item value="cover">
    <v-card-text align='center'>
      <v-img width="67%" :src="img" style="border: solid 1px black"></v-img>  
      <p class="mt-3" v-html="$t('upsit.cover', {bookNo: bookNo})">
        </p>
    </v-card-text>
  </v-window-item> 
</template>

<script>

  export default {
    name: "upsitBookletCover",
    props: {

      bookNo: {
        type: Number,
        required: true
      },
      img: {
        type: String,
        required: true
      }
    },
    data: function() {
      return {

      }
    }
  }
  
</script>
import validation from "./validation.js"
import Vue from 'vue'

var defaults = {
  checkboxes: {
    rules: []
  },
  conditional_text: {
    rules: ["not_null", "required"]
  },
  date: {
    rules: ["not_null"]
  },
  text: {
    rules: ["not_null", "required"]
  },
  full_name: {
    rules: ["not_null"]
  },
  select: {
    rules: ["not_null"]
  },
  radio: {
    rules: ["required"]
  },
  numeric: {
    rules: ["not_zero"]
  },
  contact: {
    rules: ['required']
  }
}

defaults
export const questionMixin = {
  props: {
    question: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  computed: {
    validationRules() {
      let rules
      if(Object.keys(this.question).indexOf('rules') > -1) {
        rules = this.question.rules
      } else {
        rules = defaults[this.question.type].rules
      }
      return Array.from(rules, x => validation[x])
    },
    answer: {
      get() {
        let answer = this.preprocessAnswer(this.question.answer)
        return answer
      },
      set(x) {
        this.$store.dispatch("questionnaire/setAnswer", {ques_guid: this.question.guid, answer: x})
        this.$emit("answer", this.question) 
      }
    },
  },
  methods: {
    preprocessAnswer(a) {
      return a
    },
    async validate() {
      await this.$refs.question.validate()
      Vue.nextTick(() => {
        this.$store.commit("questionnaire/SET_VALID", {
          ques_guid: this.question.guid,
          valid: this.$refs.question.valid
        })       
      })
    }
  },
  watch: {
    answer() {
      this.validate()
    }
  }
}
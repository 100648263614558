import Vue from 'vue'
import '@/components.js'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import {sync} from 'vuex-router-sync'

import '@/services/print'
import '@/services/logger'
import axios from "./plugins/axios"
import i18n from "./i18n"

import modules from '@/modules.js'
modules(store)

sync(store, router)

Vue.use(axios)

Vue.config.productionTip = false

  // eslint-disable-next-line  
  delete L.Icon.Default.prototype._getIconUrl  
  // eslint-disable-next-line  
  L.Icon.Default.mergeOptions({  
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
    iconUrl: require('leaflet/dist/images/marker-icon.png'),  
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
  })
  
const vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
})

vm.$mount('#ppmi_participant_portal')

window.$vm = vm

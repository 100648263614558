<template>
  <span>
    <v-tooltip color="accent" left v-if="!$vuetify.breakpoint.mobile">
      <template v-slot:activator="{ on }">
        <v-btn
          bottom
          color="primary"
          dark
          fab
          fixed
          right
          v-on='on'
          @click="dialog = !dialog"
          :aria-label="asklabel"
          class = "mb-10"
          id="questionBoxBtn"
        >
          <v-icon>{{icon}}</v-icon>
        </v-btn>      
      </template>
      <span>{{ $t('questionbox.ask') }}</span>
    </v-tooltip>    
    <v-dialog
      v-model="dialog"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        max-width="800"
        class='mx-auto'
        id="questionBox"
      >
        <p-card-title
          :title="qntitlelabel"
          :subtitle="qnsubtitlelabel"
          :icon="icon"
        ></p-card-title>
        <v-card-text class = "mt-5">
          <v-btn block color="accent" x-large :href="`tel:${phone}`" class = "mb-5"><v-icon class="mr-3">fa-phone</v-icon> {{ phone }}</v-btn>
          <v-btn block color="accent" x-large :href="`mailto:${email}`" class="mb-5"><v-icon class="mr-3">fa-at</v-icon> {{ email }}</v-btn>
          <!-- <v-btn block color="accent" x-large @click="showProblemBox"><v-icon class="mr-3">fa-bug</v-icon>{{ $t('questionbox.reportprob') }}</v-btn> -->
        </v-card-text>
        <!-- <p-card-title
          title="Need Help?"
          subtitle="Ask a question or leave a comment and we will get back to you as soon as possible"
          :icon="icon"
        ></p-card-title>
        <v-card-text>
          <v-textarea
            v-model="question"
            label="Briefly describe your question/problem"
          ></v-textarea>          
          <v-select
            v-model="contact_type"
            :items="['phone', 'email']"
            label = "Preferred Contact Method"
            persistent-hint
            hint = "We will attempt to contact you by your preferred method first."
          ></v-select>
        </v-card-text> -->
        <v-card-actions absolute>
          <v-spacer></v-spacer>
          <v-btn color='secondary' @click="cancel" id="questionBoxCancelBtn">{{ $t('gen.okay')}}</v-btn>
          <!-- <v-btn :disabled="question === ''" color='primary' @click="submit">Submit</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: "questionBox",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      question: "",
      contact_type: "phone",
      icon: "mdi-comment-question"
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      }
    },
    phone() { return process.env.VUE_APP_PHONE_NUMBER },
    email() { return process.env.VUE_APP_EMAIL_ADDRESS },
    asklabel(){
      return this.$t('questionbox.ask')
    },
    qntitlelabel() {
      return this.$t('questionbox.qntitle')
    },
    qnsubtitlelabel() {
      return this.$t('questionbox.qnsubtitle')
    }
  },
  methods: {
    showProblemBox() {
      console.log("Hello World")
      this.$emit("problem", true)
      this.dialog=false
    },
    cancel: function() {
      this.question = ""
      this.contact_type = "phone"
      this.dialog = false
    },
    // submit: function() {
    //   let message = {
    //     text: this.question,
    //     contact_type: this.contact_type,
    //     current_page: this.$store.state.route.meta.title,
    //     prt_guid: this.$store.getters["participant/guid"],
    //     userid: this.$store.getters["participant/userid"],
    //     timestamp: new Date().toISOString()
    //   }
    //   message;
    //   this.cancel();
    //   this.$store.dispatch("snackbar/display", {message: this.$t('questionbox.qnsubmitted')});
    // }
  },
  watch: {
    // value(v) {
    //   if(v) {
    //     this.$store.dispatch("questionBox/takeScreenshot", {target: document.body})
    //   }
    // }
  }
}
</script>
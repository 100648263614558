const state = {
  visible: false,
  message: "",
  timeout: 15000,
  color: "accent"
}

const mutations = {
  SET_VISIBLE: (state, payload) => state.visible = payload,
  SET_MESSAGE: (state, payload) => state.message = payload,
  SET_TIMEOUT: (state, payload) => state.timeout = payload,
  SET_COLOR  : (state, payload) => state.color   = payload,
}

const actions = {
  display({ commit }, payload) {

    let color = payload.color
    if(!color) {
      color = 'accent'
    }
    let timeout = payload.timeout
    if(timeout == 0) {
      timeout = -1
    } else if(!timeout) {
      timeout = 15000
    } else if(timeout < 1000 && timeout > 0) {
      timeout = timeout * 1000
    }
    commit("SET_TIMEOUT", timeout)
    commit('SET_MESSAGE', payload.message)
    commit('SET_VISIBLE', true)
    commit("SET_COLOR", color)
  },
  close({ commit }) {
    commit('SET_VISIBLE', false);
    window.setTimeout(function() {
      commit('SET_MESSAGE', '');
      commit('SET_COLOR', 'accent')
    }, 500)
  }
}

const getters = {
  visible: state => state.visible,
  message: state => state.message,
  timeout: state => state.timeout,
  color: state => state.color,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

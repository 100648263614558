import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#F2A900',
        primaryLight: "#F2A900",
        primaryDark: "#f7931e",
        secondary: '#3F4443',
        accent: '#246D8F', 
      }
    }
  }
});

//
<template>
  <v-tooltip bottom>
    <template v-slot:activator="{on, attrs}">
      <v-text-field 
        v-model="date"
        type="date"
        :dense="dense"
        :label="label"
        v-on="on"
        v-bind="attrs"
        validate-on-blur
        :outlined="outlined"
        :readonly="readonly"
        :rules="[required, inRange]"
        :suffix="showAge ? age : null"
      ></v-text-field>
    </template>
    <span v-if="readonly">{{ $t('participant.birthdate.cantupdate') }}</span>
    <span v-else>{{ $t('participant.birthdate.format') }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: "pBirthdateInput",
    props: {
      value: {
        required: false,
        default: ""
      },
      editing: {
        type: Boolean,
        required: false,
        default: true
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      },
      dense: {
        type: Boolean,
        required: false,
        default: true
      },
      maxDate: {
        type: String,
        default: new Date(new Date().setFullYear(new Date().getFullYear() - 17)).toISOString().substr(0,10)
      },
      minDate: {
        type: String,
        default: "1900-01-01"
      },
      showAge: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      validateOnBlur: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      stored_birthdate: false
    }),
    computed: {
      required() {
        return (v) => {
          return !!v || this.$t('gen.fieldreq')
        }
      },
      readonly() {
        return !!this.stored_birthdate
      },
      date: {
        get() {
          if(this.value) {
            return this.value.substr(0,10)
          } else {
            return ""
          }
        },
        set(v) {
          this.$emit("input", v)
        },
      },
      age() {
        let x = new Date()
        let y = new Date(this.date)
        let age = Math.floor((x - y) / (1000 * 60 * 60 * 24 * 365.25))
        if(this.date !== "" && this.date !== null && !isNaN(age)) {
          return this.$t('participant.birthdate.age') + ": " + age
        } else {
          return ""
        }
      },
      inRange() {
        let x = new Date()
        let y = new Date(this.date)
        let age = Math.floor((x - y) / (1000 * 60 * 60 * 24 * 365.25))
        if(age != null && age > 17 && age < 120){
          return true
        }else{
          return "Please set your correct age. Try tapping the year to select again."
        }
      },
      label() {
        return this.$t('participant.progressdialog.labelsObj.birth_date')
      }
    },
    mounted() {
      if(this.$store.getters['participant/generalInfo'].birth_date)
        this.stored_birthdate = true
      }
  }
</script>

<style scoped>

  .not-editable {
    pointer-events: none;
  }

</style>
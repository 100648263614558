<template>
  <v-btn
    text
    @click="resetParticipant"
  ><v-icon left>fa-undo</v-icon>{{ $t('gen.reset') }}</v-btn>
</template>

<script>
  export default {
    name: "pParticipantReset",
    data() {
      return {}
    },
    computed: {
      dev() {
        return process.env.NODE_ENV === "development"
      },
      loggedIn: function() {
        return this.$store.getters["auth/isLoggedIn"];
      },      
    },
    methods: {
      async resetParticipant() {
        let ppmi_id = this.$store.getters['auth/user_id'];
        let resp = await fetch(`https://nform.iu.edu/remote/api/participant/${ppmi_id}/reset`, {
          method: "PUT"
        })
        if(resp.status == 200) {
          this.$store.dispatch("auth/LOGOUT")
          this.$store.dispatch("snackbar/display", {message: this.$t('snackbar.reset')})
        } else {
          this.$store.dispatch("snackbar/display", {message: this.$t('snackbar.reset-fail'), color: "error"})
        }
      }
    }
  }
</script>
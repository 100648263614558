export default {
  not_zero: function(v) {
    return v != 0 || "Must be greater than 0"
  },
  required: function(v) {
    return !!v || "You must answer this question"
  },
  not_null: function(v) {
    return v !== null || "You must answer this question"
  }
}
<template>
  <v-radio-group 
    v-model="answer" 
    ref = "question"
    :rules="validationRules"  
  >
    <v-radio v-for="(item, index) in choices"
      :key = "index"
      :label = "item.label"
      :value = "item.value"
      :disabled="disabled"
    ></v-radio>
  </v-radio-group>
</template>

<script>

import { questionMixin } from "../mixins.js"

export default {
  name: "qRadio",
  mixins: [questionMixin],
  data: function() {
    return {
      default_rules: ['required']
    }
  },
  computed: {
    choices: function() {
      let x = this.question.options
      if(Object.keys(x).indexOf("options") > -1) {
        x = this.question.options.options
      }
      if(typeof x[0] === "string") {
        x = x.map(y => {return {label: y, value: y}})
      }
      return x
    },
  },
  methods: {

  }
}
</script>
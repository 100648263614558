import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"left":_vm.tooltipLoc=='left',"right":_vm.tooltipLoc=='right',"top":_vm.tooltipLoc=='top',"bottom":_vm.tooltipLoc=='bottom',"disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"px-8",class:_vm.btnClass,attrs:{"dark":_vm.dark,"color":_vm.color,"id":_vm.id,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},'v-btn',attrs,false),on),[(_vm.icon)?_c(VIcon,[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(_vm._s(_vm.label))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])}
var staticRenderFns = []

export { render, staticRenderFns }
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VWindowItem,{staticClass:"pa-0 ma-0"},[_c(VCardText,{staticClass:"pa-0",attrs:{"align":"center"}},[_c(VRadioGroup,{staticClass:"pa-0 ma-0",model:{value:(_vm.radio_answer),callback:function ($$v) {_vm.radio_answer=$$v},expression:"radio_answer"}},[_c(VCard,{staticClass:"rounded-0",attrs:{"color":_vm.colors[_vm.page.color],"flat":""}},[_c(VCardTitle,{staticClass:"d-flex flex-row-reverse"},[_vm._v(" "+_vm._s(_vm.page.pageNo)+". "+_vm._s(_vm.$t('upsit.pagetitle'))+" ")]),_c(VCardText,{staticClass:"page-text d-flex flex-row-reverse"},[_c(VList,{attrs:{"dense":"","color":"transparent","rounded":""}},_vm._l((_vm.page.options),function(o,i){return _c(VListItem,{key:'item'+i,style:(_vm.answerColor(_vm.letters[i])),on:{"click":function($event){return _vm.setAnswer(i)}}},[_c(VListItemContent,{staticClass:"pr-8"},[_vm._v(" "+_vm._s(_vm.letters[i])+". "+_vm._s(o)+" ")]),_c(VListItemAvatar,[_c(VRadio,{key:o,attrs:{"color":"black","label":null,"value":_vm.letters[i],"on-icon":_vm.get_icon(i, true),"off-icon":_vm.get_icon(i, false)}})],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
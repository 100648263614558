import data_api from "@/services/axios-data.js";
// import axios from '@/services/axios'
// const data_api = axios();
import Vue from 'vue'

const upsDate = (dt) => {
  return `${dt.slice(4,6)}/${dt.slice(6)}/${dt.slice(0,4)}`
}
const upsTime = (tm) => {
  return `${tm.slice(0,2)}:${tm.slice(2,4)}`
}

const state = {
  taskData: [],
  taskGroups: [],
  loading: false,
  hasActiveTask: false,
  activeTask: {}
}

const mutations = {
  SET_TASK_DATA: (state, payload) => {
    state.taskData = payload
  },
  SET_TASK_GROUPS: (state, payload) => {
    state.taskGroups = payload
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload
  },
  SET_ACTIVE_TASK: (state, payload) => {
    state.activeTask = payload
  },
  SET_HAS_ACTIVE_TASK: (state, payload) => {
    state.hasActiveTask = payload
  }
}

const actions = {
  async load(context) {
    try {
      context.commit("SET_LOADING", true)
      let prt_guid = await context.rootGetters['participant/participant_guid']
      console.log('getting tasks')
      let resp = await data_api.get(["participants", prt_guid, "tasks"])
      Vue.$log.debug("taskStore load response:", Object.assign({}, resp))
      context.commit("SET_TASK_DATA", resp.data)
      let taskGroups = [...resp.data]
      let firstActiveGroup = true
      context.commit("SET_HAS_ACTIVE_TASK", false)
      context.commit("SET_ACTIVE_TASK", {})
      for(let g = 0; g < taskGroups.length; g++) {

        let tg = taskGroups[g]
        tg.title = tg.group_title || "Please assign a group_title" //
        tg.subtitle = tg.group_subtitle || "Please assign a group_subtitle"
        tg.started = tg.tasks.some(x => {
          return x.started == true
        })
        tg.completed = tg.tasks.every(x => {
          return x.completed == true
        })
        if(tg.started && !tg.completed && firstActiveGroup) {
          tg.active = true
          firstActiveGroup = false
        } else {
          tg.active = false
        }
        if(tg.tasks.some(x => { return x.started == true }) || tg.completed) {
          tg.visible = true
          }else{ tg.visible = false }
        let firstActiveTask = true
        for(let t = 0; t < taskGroups[g].tasks.length; t++) {
          let task = tg.tasks[t]
          task.status = 'future'
          if(!task.completed) {
            if(firstActiveTask) {
              task.status = 'active'
              context.commit("SET_ACTIVE_TASK", task)
              firstActiveTask = false
              if(tg.active) {
                context.commit("SET_ACTIVE_TASK", task)
              }
            }
          } else {
            task.status = "completed"
          }
        }
      }
      if(firstActiveGroup) {
        taskGroups[taskGroups.length - 1].active = true
        // taskGroups[taskGroups.length - 1].visible = true
      }
      context.commit("SET_TASK_GROUPS", taskGroups)
    } catch(err) {
      Vue.$log.error(err)
      throw new Error("cannot load tasks")
    } finally {
      context.commit("SET_LOADING", false)
    }
  },
  async tracking(context, tracking_no) {
    try {
      let resp = await data_api.get(["ups", "track", tracking_no])
      let shipment = resp.data.TrackResponse.Shipment
      if(!Array.isArray(shipment.Package)) {
        shipment.Package = [shipment.Package]
      }
      if(!Array.isArray(shipment.Package[0].Activity)) {
        shipment.Package[0].Activity = [shipment.Package[0].Activity]
      }
      let payload = {
        trackingNo: shipment.Package.TrackingNumber,
        pickupDate: shipment.PickupDate,
        status: resp.data.TrackResponse.Response.ResponseStatus.Description,
        service: shipment.Service.Description,
//        toAddress: shipment.ShipmentAddress[1].Address || {},
        currentActivity: shipment.Package[0].Activity[0].Status.Description,
        activity: shipment.Package[0].Activity.map(activity => {
          try {
            return {
              address: activity.ActivityLocation ? activity.ActivityLocation.Address : "",
              description: activity.Status.Description,
              date: upsDate(activity.Date),
              time: upsTime(activity.Time)
            }
          } catch(err) {
            return {}
          }
        })
      }
      Vue.$log.info("tracking info", resp.data.TrackResponse)
      return payload
    } catch(err) {
      Vue.$log.error(err)
      throw err
    }
  }
}

const getters = {
  taskData: state => state.taskData,
  taskGroups: state => state.taskGroups,
  hasActiveTask: state => state.hasActiveTask,
  activeTask: state => state.activeTask,
  orderedTasks: (state) => {
    let tasks = [];
    Object.keys(state.taskData).forEach(tk => {
      let t = state.taskData[tk]
      tasks.push({
        key: tk,
        completed: t.completed,
        task_order: t.task_order
      })
    })
    tasks.sort((a,b) => {
      if(a > b) {
        return 1
      } else {
        return -1
      }
    })
    return tasks
  },
  currentTrackingNo: (state, getters) => {
    if(getters.activeTask.ups_tracking_number) return getters.activeTask.ups_tracking_number

    let task_key = `${getters.activeTask.task_group}_${getters.activeTask.task_key}`
    let task_keys = Object.keys(state.taskData)
    let prevTask = state.taskData[task_keys[task_keys.indexOf(task_key)-1]]
    if(prevTask) {
      if(prevTask.ups_tracking_number) return prevTask.ups_tracking_number
    }
  },
  loading: (state) => state.loading
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default function validateGuid(guid) {

  try {
    let re = RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

    if(typeof guid !== "string") return false
    if(!re.test(guid)) return false

    return true
  } catch(e) {
    e
    return false
  }

}
<template>
  <v-row justify="center">
    <v-dialog v-model='value' persistent max-width="800">
      <v-card>
        <p-card-title
          :title="title"
          :icon="icon"
        ></p-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <component 
            :is="inputComponent"
            ref="formComponent"
            v-model="Record"
            :valid.sync="validForm"
          ></component>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <p-action-button
            color = "error" 
            dark
            icon="mdi-close"
            @click="discardChanges"
            :label = cancelLabel
          ></p-action-button>            
          <p-action-button 
            color = "success" 
            :dark='validForm'
            btnClass="ml-3"
            :disabled="!validForm"
            @click="saveChanges" 
            icon = "mdi-check"
            :label = saveLabel
          ></p-action-button>   
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default {
  name: "pDataUpdateDialog",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      required: false
    },
    record_type: {
      type: String,
      required: true
    },
    update_type: {
      type: String,
      default: "insert"
    },
    inputComponent: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      validForm: false,
      Record: this.record
    }
  },
  computed: {
    cancelLabel() {
      return this.$t('gen.cancel')
    },
    saveLabel() {
      return this.$t('gen.save')
    }
    // Record: {
    //   get() {
    //     return this.record
    //   },
    //   set(v) {
    //     this.$emit("update:record", v)
    //   }
    // }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
    },
    discardChanges() {
      this.closeDialog()
      this.resetForm()
    },
    async saveChanges() {
      if(!this.Record.guid) {
        this.$emit('insert', {type: this.record_type, data: this.Record})
      } else {
        this.$emit('update', {type: this.record_type, data: this.Record})
      }
      this.resetForm()
      this.closeDialog()
    },
    clearValidation() {
      let frm = this.$refs.formComponent
      if(frm !== undefined) {
        Vue.nextTick(() => {
          frm.clear()
        })
      }
    },
    resetForm() {
      let frm = this.$refs.formComponent
      this.Record = {}
      if(frm !== undefined) {
        Vue.nextTick(() => {
          frm.reset()
        })
      }      
    }
  },
  watch: {
    value(v) {
      if(v) {
        if(this.update_type == "insert") {
          this.resetForm()
        } else {
          this.Record = this.record
        }
      }
    },
    record(v) {
      this.Record = v || {}
    }
  },
}
</script>
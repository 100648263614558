import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-info-card',{attrs:{"title":_vm.title,"subtitle":_vm.subtitle,"titleIcon":"fa-edit","loading":_vm.loading,"progress":_vm.progress,"minHeight":"400px","actions":false,"textClass":"ma-0 pa-0","cardText":false}},[_c(VWindow,{ref:"upsitWindow",attrs:{"touchless":""},model:{value:(_vm.stepIndex),callback:function ($$v) {_vm.stepIndex=$$v},expression:"stepIndex"}},[_c('upsit-intro',{on:{"continue":_vm.introComplete}}),_c(VWindowItem,{attrs:{"value":"booklets"}},[_c(VCardText,{staticClass:"ma-0 pa-0"},[_c(VStepper,{attrs:{"flat":""},model:{value:(_vm.bookNo),callback:function ($$v) {_vm.bookNo=$$v},expression:"bookNo"}},[_c(VStepperHeader,[_vm._l((_vm.books),function(book,i){return [_c(VStepperStep,{key:'header'+i,attrs:{"complete":_vm.bookNo > book.bookNo,"step":book.bookNo}},[_vm._v(_vm._s(_vm.$t('upsit.booklet'))+" "+_vm._s(book.bookNo))]),(book.bookNo < _vm.books.length)?_c(VDivider,{key:'div'+i}):_vm._e()]})],2),_c(VStepperItems,_vm._l((_vm.books.length),function(n){return _c(VStepperContent,{key:n,staticClass:"pa-0",attrs:{"step":n}},[_c('upsit-booklet',{attrs:{"bookNo":n},on:{"complete":_vm.bookComplete}})],1)}),1)],1)],1)],1),_c('upsit-conclusion')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/'

Vue.use(VueRouter)

const beforeEnterHome = async (to, from, next) => {
  const loggedIn = store.getters['auth/isLoggedIn']
  if(loggedIn) {
    store.dispatch("tasks/load")
    next()
  } else {
    if(Object.keys(to.query).indexOf("code") > -1 && Object.keys(to.query).indexOf("state") > -1) {
      let code = to.query.code
      let state = to.query.state
      try {
        await store.dispatch("auth/AUTH_GRANT", {code: code, state: state})
        router.push({name: 'home', query: null})
      } catch(err) {
        await store.dispatch("snackbar/display", {message: this.$t('router.error'), color: 'error'})
        await store.dispatch("auth/LOGOUT")
      } 
    } else {
      next()
    }
  }

}

const routerOptions = [
  {
    path: '/', 
    component: 'Home', 
    name: "home", 
    meta: {title: 'PPMI Participant Portal', auth: false},
    beforeEnter: beforeEnterHome
  },
  {path: "/contact-info", component: "Participant", name: "participant", meta: {title: "Participant - PPMI Participant Portal", auth: true}},
  {path: '/consent/:qnn', component: 'Consent', name: "consent", meta: {title: 'Informed Consent - PPMI Participant Portal', auth: true}},
  {path: "/questionnaire/:qnn", component: "Questionnaire", name: "questionnaire", meta: {title: "Questionnaire - PPMI Participant Portal", auth: true}},
  {path: '/upsit/:qnn', component: 'UPSIT', name: "upsit", meta: {title: 'UPSIT - PPMI Participant Portal', auth: true}},
  {path: '/upsit2/:qnn', component: 'UPSIT', name: "upsit", meta: {title: 'UPSIT - PPMI Participant Portal', auth: true}},
  {path: "/faq", component: "FAQ", name:"faq", meta: {title: "FAQ - PPMI Participant Portal", auth: false}},
  {path: "/shipping", component: "Shipping", name: "shipping", meta: {title: "Shipping Info - PPMI Participant Portal", auth: true}},
  {path: "/healthcheck", component: "Healthcheck", name: "healthcheck", meta: {title: "Healthcheck - PPMI Participant Portal", auth: false}},
  {path: '*', redirect: "/"},
]

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`../views/${route.component}.vue`)
  };
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  const loggedIn = store.getters['auth/isLoggedIn']
  // Refresh token if near expiration
  if(loggedIn) {
    let exp = store.getters['auth/access_payload'].exp
    if(Date.now() / 1000 > exp - 300) {
      Vue.$log.debug("Refreshing Token")
      await store.dispatch("auth/REFRESH_TOKEN")
    }
  }

  // Show survey when necessary
  // if(to.params.task && loggedIn) {
  //   store.dispatch("survey/show", {task_key: to.params.task})
  // }  

  // Make sure options data is loaded
  if(loggedIn && !store.getters['options/loaded']) {
    store.dispatch("options/load", true)
  }

  //make sure that participant is loaded
  if(loggedIn && !store.getters['participant/guid']) {
    await store.dispatch("participant/load", {userid: store.getters['auth/user_id'], source: "beforeEnterHome"})
  }

  // Prevent navigation to secure routes when not logged in
  if(!loggedIn && to.meta.auth) {
    next({name: 'home'})
  } else {
    document.title = to.meta.title;
    next()
  }
  
})

export default router

  // if(store.getters['auth/isLoggedIn']) { // There is a token stored in Vuex
  //   if(!store.getters['participant/loaded'] || to.name == 'home') {
  //     store.dispatch("participant/load", {userid: store.getters['auth/user_id'], source: "router"})
  //   }
  //   if(store.getters['auth/tokenExpired']) { // The current access token is expired
  //     try {
  //       await store.dispatch("auth/refreshToken")
  //     } catch(err) {
  //       if(err.message === "Could not refresh token") {
  //         store.dispatch("snackbar/display", {message: "Login has expired. Please sign in again."})
  //       } else {
  //         store.dispatch("snackbar/display", {message: "An unknown error has occured. Please sign in again."})          
  //       }
  //       store.commit("auth/LOGOUT")
  //       next({name: 'home'})      
  //     }
  //   } else { // The current access token appears to be valid
  //     document.title = to.meta.title;
  //     next()     
  //   }
  // } else { // There is no access token stored in vuex
  //   if(to.meta.auth) { // The to route requires authentication
  //     next({name: "home"})     
  //   } else { // The to route doesn't require authentication
  //     document.title = to.meta.title;
  //     next()
  //   }
  // }

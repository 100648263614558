import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VForm,{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VTextField,{attrs:{"label":"Email Address","rules":_vm.rules.email,"dense":_vm.dense},model:{value:(_vm.record.email),callback:function ($$v) {_vm.$set(_vm.record, "email", $$v)},expression:"record.email"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{attrs:{"items":_vm.types,"label":"Type","rules":_vm.rules.type,"dense":_vm.dense},model:{value:(_vm.record.type),callback:function ($$v) {_vm.$set(_vm.record, "type", $$v)},expression:"record.type"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VuexPersist from 'vuex-persist';
//import localForage from 'localforage';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

//modules
import auth from '@/store/auth';
import userSettings from '@/store/userSettings'
import options from "@/store/options.js"

const vuexLocal = new VuexPersist({
  storage: window.localStorage,
  key: "ppp",
  reducer(val) {
    if(val.auth.loggingIn) {
      return val
    } else if(val.auth.loggedIn === false) {
      return {}
    } else {
      return val
    }
  }
});

export default new Vuex.Store({
  modules: {
    auth: auth,
    userSettings: userSettings,
    options: options
  },
  plugins: [vuexLocal.plugin],
})

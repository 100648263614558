<template>
  <p-info-card
    :title="title"
    :subtitle="subtitle"
    titleIcon="fa-edit"
    :loading="loading"
    :progress="progress"
    minHeight="400px"
    :actions="false"
    textClass="ma-0 pa-0"
    :cardText="false"
  >
    <v-window v-model="stepIndex" touchless ref="upsitWindow">
      <upsit-intro @continue="introComplete"></upsit-intro>
      <v-window-item value = "booklets">
        <v-card-text class = "ma-0 pa-0">
        <v-stepper v-model="bookNo" flat>
          <v-stepper-header>
            <template v-for="(book, i) in books">
              <v-stepper-step :complete="bookNo > book.bookNo" :key="'header'+i" :step="book.bookNo">{{ $t('upsit.booklet') }} {{book.bookNo}}</v-stepper-step>
              <v-divider v-if="book.bookNo < books.length" :key="'div'+i"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content v-for="n in books.length" :step="n" :key="n" class = "pa-0">
              <upsit-booklet :bookNo="n" @complete="bookComplete"></upsit-booklet>            
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        </v-card-text>
      </v-window-item>
      <upsit-conclusion></upsit-conclusion>
    </v-window>     
  </p-info-card>
</template>

<script>

  export default {
    name: "upsitPage",
    data: function() {
      return {
        kit_no: null,
        titleIcon: "fa-edit",
        stepIndex: 0
      }
    },
    created() {
      this.$store.dispatch("upsit/load", this.qnn_guid)
    },
    computed: {
      loading: {
        get() { return this.$store.getters['upsit/loading']},
        set(v) { this.$store.commit("upsit/SET_LOADING", v)}
      },
      books() {
        return this.$store.getters['upsit/books']
      },
      bookNo: {
        get() {
          return this.$store.getters['upsit/bookNo']
        },
        set(x) {
          this.$store.commit("upsit/SET_BOOKNO", x)
        }
      },
      title(){
        return this.$t('upsit.upsittitle')
      },
      subtitle: function() {
        switch(this.stepIndex) {
          case 'intro':
            return this.$t('upsit.upsitsubtitle1')
          case 'conclusion':
            return this.$t('upsit.upsitsubtitle2')
          default:
            return this.$t('upsit.upsitsubtitle3', {bookNo: this.bookNo})
        }
      },
      progress() {
        let bookNo = this.$store.getters["upsit/bookNo"]
        let pageNo = this.$store.getters["upsit/pageNo"]
        if(bookNo === 1 && pageNo === 'cover') return 0
        return (((bookNo - 1)*10) + (pageNo))/40 * 100
      },
      nextPage() {
        return this.$store.getters['upsit/next_unanswered']
      },
      nextBooklet() {
        return this.$store.getters['upsit/next_booklet']
      }
    },
    methods: {
      nextWindow() {
        this.bookNo = this.nextBooklet
        this.$refs.upsitWindow.next()
      },
      bookComplete: function() {
        if(this.bookNo < 4) {
          this.$store.commit("upsit/SET_PAGENO", "cover")
          this.bookNo++
        } else {
          this.$refs.upsitWindow.next()
        }
      },
      introComplete: function(kit_no) {
        this.kit_no = kit_no;
        this.$store.commit("upsit/SET_PAGENO", "cover")        
        this.nextWindow()
      }
    },
  }

</script>

<style>

</style>
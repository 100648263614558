<template>
  <v-expansion-panel>
    <v-expansion-panel-header hide-actions>
      <p-card-title
        :title="taskGroup.title"
        :subtitle="taskGroup.subtitle"
        :iconColor="taskGroup.completed ? 'success' : 'primary'"
        :icon="taskGroup.completed ? 'fa-check' : 'fa-tasks'"
        :loading="loading"
        :fontSizeAdjust="false"        
      >
        <template v-slot:action>
          <v-btn icon>
            <v-icon large v-text='visible ? "mdi-chevron-up" : "mdi-chevron-down"'></v-icon>
          </v-btn>
        </template>
      </p-card-title>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0 ma-0">

      <v-list two-line v-if="!loading" class = "taskList">
        <template v-for="(task, index) in taskGroup.tasks">
          <v-tooltip z-index=200 v-model="tooltip[index]" :top="$vuetify.breakpoint.mobile" :left="!$vuetify.breakpoint.mobile" :key="`${task.task_group}_${task.task_key}_${task.highlighted}`" :color="ttColor(task, index)">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on">
                <v-list-item
                  v-bind="attrs"
                  :style="{cursor: (task.task_type == 'active' || task.task_key == 'return_upsit') ? 'pointer' : 'default'}"
                  :ripple="task.task_type == 'active'"
                  :disabled="!prtActive || task.status!=='active'"
                  @click="makeLink(task)"
                  :id="`${task.task_group}_${task.task_key}`"
                  :class="{notAvailable: !prtActive || task.status=='future', highlighted: highlighted[index]}"
                >
                  <v-list-item-avatar rounded='0' class="pa-6">
                    <v-badge
                      bordered
                      :value="task.status==='completed'"
                      color="green"
                      icon="fa-check"
                      offset-y="15"
                      overlap
                      bottom
                    >
                      <v-icon :color="task.status=='future' ? '#CCC' : null">{{ task.task_icon }}</v-icon>      
                    </v-badge>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="task.task_title"></v-list-item-title>
                    <v-list-item-subtitle v-html="task.status == 'future' ? $t('home.taskGroup.futuregeneric') : task.status == 'completed' ? $t('home.taskGroup.completegeneric') : task.description"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="task.status == 'active' && task.task_type == 'active'">
                    <v-btn icon x-large color="grey darken-1">
                      <v-icon large color="grey darken-1">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>                  
              </span>
            </template>
            <div class="ma-3 ml-1 taskListTooltip"><v-icon dark class="mr-3" v-text="ttIcon(task, index)"></v-icon><span v-html="ttText(task, index)"></span><v-icon dark class="ml-3">{{ $vuetify.breakpoint.mobile ? 'fa-chevron-down' : 'fa-chevron-right'}}</v-icon></div>
          </v-tooltip>
          <v-divider
            v-if="index < taskGroup.tasks.length"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
      <v-skeleton-loader
        v-else
        class="mx-auto"
        :type="Array(taskGroup.tasks.length).fill(this.skeleton).join()"
      ></v-skeleton-loader>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  export default {
    name: "pTaskGroup",
    props: {
      taskGroup: {
        type: Object,
        required: true
      },
      skeleton: {
        type: String,
        default: "list-item-avatar-two-line"
      },
      visible: {
        type: Boolean,
        default: false
      },
      activeTask: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        highlighted: this.taskGroup.tasks.map(() => false),
        tooltip: this.taskGroup.tasks.map(() => false),
        timeoutFn: null
      }
    },
    computed: {
      loading() {
        return this.$store.getters['tasks/loading']
      },
      prtActive() {
        let status = this.$store.getters['participant/status'];
        if(status == null) return true
        if(status.toLowerCase() == 'active') return true
        return false
      },
      activeTaskIndex() {
        return this.taskGroup.tasks.findIndex(task => {
          return task.task_group == this.taskGroup.activeAction.task_group && 
                 task.task_key == this.taskGroup.activeAction.task_key
        })
      }
    },
    async mounted() {
      this.setTaskHighlighting()
    },
    methods: {
      highlightTask(taskIndex) {
        this.highlighted[taskIndex] = true
        this.tooltip[taskIndex] = true
        this.$forceUpdate()
      },
      clearHighlightTask() {
        this.highlighted[this.activeTaskIndex] = false
        this.tooltip[this.activeTaskIndex] = false
        this.$forceUpdate()
      },
      highlightFn() {
        this.$log.debug("The task should have been highlighted")
        if(this.visible) this.highlightTask(this.activeTaskIndex)
      },
      setTaskHighlighting() {
        let activeTask = this.taskGroup.activeAction
        let aT = false
        if(activeTask) {
          if(activeTask.task_type === "active") {
            aT =  true
            if(this.$store.getters['participant/status'] === "Active") {
              if(this.timeoutFn == null) {
                this.timeoutFn = window.setTimeout(this.highlightFn, 15000)
              }    
            }  
          }
        }
        this.$emit("update:activeTask", aT)
      },
      ttColor(task) {
        if(!this.prtActive) {
          return "error"
        }
        if(task.completed) {
          return "success"
        } 
        if(task.status == "future") {
          return "orange"
        }
        return "accent"
      },
      ttIcon(task) {
        if(!this.prtActive) {
          return "fa-times"
        }
        if(task.completed) {
          return "fa-check"
        }
        if(task.status == "future") {
          return "fa-hand-paper"
        }
        if(task.task_type == "tracking") {
          if(task.task_key.search("receive") > -1) {
            return `fa-hourglass-half`
          }      
        }
        return "fa-hand-pointer"
      },
      ttText(task) {
        if(!this.prtActive) {
          return this.$t('home.taskGroup.locked')
        } else if(task.completed) {
          return this.$t('home.taskGroup.complete', { title: task.task_title })
        } else if(task.status == "future") {
          return this.$t('home.taskGroup.future', { title: task.task_title })
        } else if(task.task_type == "active") {
          return this.$t('home.taskGroup.active', { title: task.task_title })
        } else if(task.task_type == "tracking") {
          if(task.task_key.search("receive") > -1) {
            return this.$t('home.taskGroup.await')
          } else if(task.task_key.search("return") > -1) {
            return this.$t('home.taskGroup.shipinfo')
          } else {
            return this.$t('home.taskGroup.trackerror')
          }
        } else {
          return this.$t('home.taskGroup.error')
        }

      },     
      taskColor(task) {
        if(this.prtActive) {
          if(task.status === 'active') {
            return "green"
          } else {
            return "grey"
          }
        } else {
          return "grey"
        }
      },
      makeLink(task) {
        if(task.status === 'active' && task.task_type == "active") {
          let path
          if(task.action.type_guid) {
            path = `${task.action.type}/${task.action.type_guid}?task=${task.task_key}`
          } else {
            path = `${task.action.type}?task=${task.task_key}`
          }
          this.$router.push({path: path})
        } else if(task.task_type == "tracking") {
          if(task.task_key == "return_upsit") {
            this.$router.push({path: "shipping/"})
          }
        }
      },
    },
    watch: {
      visible(v) {
        if(this.taskGroup.activeAction) {
          if(v) {
            this.setTaskHighlighting()
          } else {
            window.clearTimeout(this.timeoutFn)
            this.timeoutFn = null
            this.clearHighlightTask()
          }
        }
      }
    }
  }
</script>

<style>

  .taskListTooltip strong {
    color: white !important;
  }

  .highlighted {
    border: solid #246d8fab 4px;
    background-color: #246d8f3b;
    -webkit-transition: background-color 0.5s, border 0.5s;
    transition: background-color 0.5s, border 0.5s;
  }
  .highlighted .v-icon {
    color: #246d8f;
    font-size: 3em;
  }
  .notAvailable {
    background: -webkit-repeating-linear-gradient(
      135deg,
      #FFF,
      #FFF 15px,
      #EEE 15px,
      #EEE 30px
    );
    background: repeating-linear-gradient(
      -45deg,
      #FFF,
      #FFF 15px,
      #EEE 15px,
      #EEE 30px
    );
  }
</style>
<template>
  <p-info-card
    :title="title"
    :subtitle="subtitle"
    titleIcon="fa-plane"
    :loading="loading"
    id="travelInfo"
    :showTitle="showTitle"
    :flat="!showTitle"
  >
    <v-container>
      <v-row>
        <v-col cols=12 lg=10 style="font-weight: bold;">
          {{ $t('participant.travelprefs.comfort') }}
        </v-col>
        <v-col cols=12 lg=2>
          <v-radio-group v-model="Record.comfortable_traveling">
            <v-radio :label="yeslabel" :value="true"></v-radio>
            <v-radio :label="nolabel" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <template v-if="Record.comfortable_traveling == false">
        <v-row>
          <v-col cols=12>
            <v-divider></v-divider>
            <div style="text-align: center; font-weight: bold; font-size: 1.4em; padding: 10px;">
              {{ $t('participant.travelprefs.staffreach') }} 
            </div>
            <v-divider></v-divider>
          </v-col>
        </v-row>        
      </template>
      <template v-else-if="Record.comfortable_traveling == true">
        <v-divider></v-divider>
        <v-row>
          <v-col cols=12 lg=10  style="font-weight: bold;">
            {{ $t('participant.travelprefs.companion') }}        
          </v-col>
          <v-col cols=12 lg=2>
            <v-radio-group v-model="Record.will_have_companion">
              <v-radio :label="yeslabel" :value="true"></v-radio>
              <v-radio :label="nolabel" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="Record.will_have_companion == true">
          <v-col cols=12 lg=6>
            {{ $t('participant.travelprefs.companiondesc') }}
          </v-col>
          <v-col cols=12 lg=6>
            <v-checkbox v-model="Record.unsure_companion" :label="unsurelabel" ></v-checkbox>          
          </v-col>
          <template v-if="Record.unsure_companion == false || Record.unsure_companion == null">
            <v-col cols=12 lg=6>
              <v-text-field
                v-model="Record.companion_fname"
                :label="cfirstlabel"
              ></v-text-field>
            </v-col>
            <v-col cols=12 lg=6>
              <v-text-field
                v-model="Record.companion_lname"
                :label="clastlabel"
              ></v-text-field>
            </v-col>
            <v-col cols=12 lg=6>
              <v-text-field
                v-model="Record.companion_phone"
                :label="cphonelabel"
              ></v-text-field>
            </v-col>
            <v-col cols=12 lg=6>
              <v-select
                v-model="Record.companion_phone_type"
                :items="phone_types"
                :label="cphonetypelabel"
                :dense="dense"
              ></v-select>
            </v-col>
          </template>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols=12 lg=10  style="font-weight: bold;">
            {{ $t('participant.travelprefs.travelneeds') }}     
          </v-col>
          <v-col cols=12 lg=2>
            <v-radio-group v-model="Record.has_special_needs">
              <v-radio :label="yeslabel" :value="true"></v-radio>
              <v-radio :label="nolabel" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="Record.has_special_needs == true">
          <v-col cols=12>
            
            <v-textarea
              outlined
              v-model="Record.special_needs_text"
              :label="describeneedslabel"
            ></v-textarea>        
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols=12  style="font-weight: bold;">
            {{ $t('participant.travelprefs.emercontact') }}        
          </v-col>
          <v-col cols=12 lg=4>
            <v-text-field
              v-model="Record.emergency_fname"
              :label="firstnamelabel"
            ></v-text-field>
          </v-col>
          <v-col cols=12 lg=4>
            <v-text-field
              v-model="Record.emergency_lname"
              :label="lastnamelabel"
            ></v-text-field>
          </v-col>
          <v-col cols=12 lg=4>
            <v-select
              v-model="Record.emergency_relationship"
              :items="relationship_types"
              :label="relationlabel"
              :dense="dense"
            ></v-select>
          </v-col>
          <v-col cols=12 lg=6>
            <v-text-field
              v-model="Record.emergency_phone"
              :label="phonelabel"
            ></v-text-field>
          </v-col>
          <v-col cols=12 lg=6>
            <v-select
              v-model="Record.emergency_phone_type"
              :items="phone_types"
              :label="phonetypelabel"
              :dense="dense"
            ></v-select>
          </v-col>
          <v-col cols=12 lg=6>
            <v-text-field
              v-model="Record.emergency_alt_phone"
              :label="altphonelabel"
            ></v-text-field>
          </v-col>
          <v-col cols=12 lg=6>
            <v-select
              v-model="Record.emergency_alt_phone_type"
              :items="phone_types"
              :label="altphonetypelabel"
              :dense="dense"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols=12 lg=8  style="font-weight: bold;">
            {{ $t('participant.travelprefs.departaddress') }}        
          </v-col>
          <v-col cols=12 lg=4>
            <v-select
              v-model="travel_address"
              :items="addresses"
            ></v-select>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn 
        :color="saveColor"
        @click="save"
      >
        <v-icon 
          class="mr-2"
          v-text="saveIcon"
        ></v-icon>
        {{saveText}}
      </v-btn>
    </template>
    <p-data-update-dialog
      :title="newaddresslabel"
      icon = "mdi-home-plus"
      v-model = "showAddressDialog"
      :record.sync = "newAddress"
      record_type = "address"
      update_type = "insert"
      inputComponent = "pAddressInput"
      @insert="$emit('insert', $event)"
    ></p-data-update-dialog>
  </p-info-card>

</template>

<script>
export default {
  name: "pTravelPreferencesDialog",
  props: {
    travel: {
      type: Object
    },
    prt_guid: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    travel_task: {
      type: String,
      default: "none"
    }
  },
  data() {

      return {
        Record: this.travel,
        newAddress: {},
        showAddressDialog: false,
        loading: false,
        travel_address: null,
        saveColor: "secondary",
        saveIcon: "fa-save",
        dense: false
      }

  },
  computed: {
    addresses() {
      let x = this.$store.getters['participant/address'].map(y => {return {text: `${y.line1}, ${y.city} ${y.state_prov}`, value: y.guid}})
      x.push("Add New Address")
      return x
    },
    phone_types() {
      return this.$store.getters['options/phone_type']
    },
    relationship_types() {
      return this.$store.getters['options/emergency_relationship']
    },
    saveText() {
      return this.travel_task == "none" ? this.$t('gen.update') : this.$t('gen.save')
    },
    yeslabel() {
      return this.$t('gen.yes')
    },
    nolabel(){
      return this.$t('gen.no')
    },
    unsurelabel() {
      return this.$t('participant.travelprefs.unsurelabel')
    },
    cfirstlabel() {
      return this.$t('participant.travelprefs.cfirstlabel')
    },
    clastlabel() {
      return this.$t('participant.travelprefs.clastlabel')
    },
    cphonelabel() {
      return this.$t('participant.travelprefs.cphonelabel')
    },
    cphonetypelabel() {
      return this.$t('participant.travelprefs.cphonetypelabel')
    },
    describeneedslabel() {
      return this.$t('participant.travelprefs.describeneeds')
    },
    firstnamelabel() {
      return this.$t('participant.generalinfo.firstname')
    },
    lastnamelabel() {
      return this.$t('participant.generalinfo.lastname')
    },
    relationlabel() {
      return this.$t('participant.travelprefs.relation')
    },
    phonelabel() {
      return this.$t('gen.phone')
    },
    phonetypelabel() {
      return this.$t('gen.phonetype')
    },
    altphonelabel() {
      return this.$t('participant.travelprefs.altphone')
    },
    altphonetypelabel() {
      return this.$t('participant.travelprefs.alttype')
    },
    newaddresslabel() {
      return this.$t('participant.travelprefs.newaddresstitle')
    }
  },
  methods: {
    async load() {
      if(this.travel) {
        this.Record = Object.assign({}, this.travel);
      } else if(this.prt_guid) {
        try {
          let resp = await this.$axios.get(["participants", this.prt_guid, "travel"])
          this.Record = resp.data
        } catch(err) {
          err
          this.Record = {}
        }
      } else {
        this.$store.dispatch("snackbar/display", {message: "There was a problem loading the participant's travel preferences", color: "error"})
      }
    },
    async save() {
      try {
        this.loading = true
        if(this.travel) {
          await this.$store.dispatch("participant/saveTravel", this.Record)
        } else {
          await this.$axios.post(["participants", this.prt_guid, "travel"], this.Record)
        }
        this.saveColor = 'success'
        this.saveIcon = 'fa-check'
        this.saveText = "Saved"
        if(this.travel_task == "travel_screening") {
          this.$store.dispatch("snackbar/display", {message: "Thank you for providing your travel preferences. Study staff will contact you with next steps."})
          this.$router.push({name: "home", params: {complete: true, task: this.$store.state.route.query.task}})
        }
        this.$emit("updated", this.Record)
      } catch(err) {
        err
        this.saveColor = 'error'
        this.saveIcon = 'fa-times'
      } finally {
        this.loading = false
      }
    },
    setTravelAddress(v) {
      if(v.from_address) {
        this.travel_address = v.from_address
      } else {
        this.travel_address = this.addresses[0]
      }
    }
  },
  async mounted() {
    await this.load()
    this.setTravelAddress(this.travel)
  },
  watch: {
    travel_address(v, o) {
      console.log(v, o)
      if(v == "Add New Address") {
        if(o !== null) {
          this.showAddressDialog = true
        }
      } else {
        this.Record.from_address = v
      }
    },
    Record: {
      handler(v) {
        this.setTravelAddress(v)
        this.saveColor = "secondary"
        this.saveIcon = "fa-save"
        this.saveText = "Save"        
      },
      deep: true
    },
    travel: {
      handler(v) {
        this.Record = Object.assign({}, v)
      },
      deep: true
    }
  }
}
</script>
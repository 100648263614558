// Vuex module
import store from './store.js'

// Main questionnaire Component
import questionnaire from "./Questionnaire.vue"

// Support Components
import questionnaireIntro from "./components/Intro.vue"
import questionnaireQuestion from "./components/Question.vue"
import questionnaireReview from "./components/Review.vue"
import questionnaireConclusion from "./components/Conclusion.vue"

// Input types
import radio from './inputs/radio.vue'
import numeric from './inputs/numeric.vue'
import conditionalText from './inputs/conditionalText.vue'
import checkboxes from './inputs/checkboxes.vue'
import text from './inputs/text.vue'
import contact from './inputs/contact.vue'

const questionnairePlugin = {
  install(Vue, options) {
    // Array of support components
    let components = [questionnaireIntro, questionnaireQuestion, questionnaireReview, questionnaireConclusion]
    // Array of inputs
    let inputs = [radio, numeric, conditionalText, checkboxes, text, contact]

    //load the main component 
    Vue.component(questionnaire.name, questionnaire)
    components.forEach(component => Vue.component(component.name, component))
    inputs.forEach(input => Vue.component(input.name, input))

    //register the vuex module
    options.registerModule("questionnaire", store)

  }
}

export default questionnairePlugin

// Automatic installation if Vue has been added to the global scope.
/*
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(MyPlugin)
}
*/
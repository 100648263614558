//import data_api from "@/services/axios-data.js";
import axios from '@/services/axios'
const data_api = axios(process.env.VUE_APP_API_URL);
import validateGUID from "@/services/guids"
import Vue from 'vue'
import i18n from "@/i18n"

const state = {
  windowIndex: 0,
  qnn_guid: null,
  bookNo: 1,
  pageNo: 'cover',
  kit_no: null,
  books: [],
  completion_date: new Date().toISOString().substr(0,10),
  completion_date_guid: null,
  prt_kit_no: null,
  prt_kit_no_guid: null,
  deliver_date: null,
  letters: ["a", "b", "c", "d"],
  colors: {
    blue:   "#a3a3c2",
    yellow: "#ffff4d",
    pink:   "#ff9966",
    green:  "#75d795",
    white:  "#FFFFFF",
    orange: "#ff8c1a"
  },
  loading: false
}

const mutations = {
  SET_QNN_GUID(state, payload) {
    if(validateGUID(payload)) {
      state.qnn_guid = payload.toLowerCase()
    } else {
      state.qnn_guid = payload
    }
  },
  SET_BOOKS(state, payload) {
    state.books = payload
  },
  SET_KIT_NO(state, payload) {
    state.kit_no = payload
  },
  SET_PRT_KIT_NO(state, payload) {
    state.prt_kit_no = payload
  },
  SET_PRT_KIT_NO_GUID(state, payload) {
    state.prt_kit_no_guid = payload
  },
  SET_WINDOWINDEX(state, payload) {
    state.windowIndex = payload
  },
  SET_BOOKNO(state, payload) {
    state.bookNo = payload
  },
  SET_PAGENO(state, payload) {
    state.pageNo = payload
  },
  SET_ANSWER(state, payload) {
    if(payload.ques_guid == state.prt_kit_no_guid) {
      state.prt_kit_no = payload.answer
    } else if(payload.ques_guid == state.completion_date_guid) {
      state.completion_date = payload.answer
    } else {
      for(let b = 0; b < state.books.length; b++) {
        let found = false
        for(let p = 0; p < state.books[b].pages.length; p++) {
          if(state.books[b].pages[p].guid == payload.ques_guid) {
            state.books[b].pages[p].answer = payload.answer
            found = true
            break;
          }
        }
        if(found) break
      }
    }
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_DELIVER_DATE(state, payload) {
    state.deliver_date = payload
  },
  SET_COMPLETION_DATE(state, payload) {
    state.completion_date = payload
  },
  SET_COMPLETION_DATE_GUID(state, payload) {
    state.completion_date_guid = payload
  }
}

const actions = {
  async load({rootGetters, rootState, commit}, qnn_guid) {
    try {
      commit("SET_LOADING", true)
      let guid = qnn_guid || rootState.route.params.qnn || 'default_guid'
      let prt_guid = await rootGetters['participant/participant_guid']
      let locale = '?locale=' + rootGetters["userSettings/current_locale"]
      let resp = await data_api.get(['participants', prt_guid, 'upsit', guid, locale])
      Vue.$log.debug("upsit load response", resp)
      let d = resp.data
      commit("SET_QNN_GUID", d.qnn_guid)
      commit("SET_BOOKS", d.books)
      commit("SET_KIT_NO", d.kitNo)
      commit("SET_PRT_KIT_NO", d.prt_kitNo.value)
      commit("SET_PRT_KIT_NO_GUID", d.prt_kitNo.guid)
      commit("SET_WINDOWINDEX", 0)
      commit("SET_BOOKNO", 1)
      commit("SET_PAGENO", 'cover')
      commit("SET_LOADING", false),
      commit("SET_COMPLETION_DATE", d.completion_date.value)
      commit("SET_COMPLETION_DATE_GUID", d.completion_date.guid)
      commit("SET_DELIVER_DATE", d.deliver_date)
      return resp.data
    } catch(err) {
      commit("SET_LOADING", false)
      Vue.$log.error(err)
    }
  },
  async setAnswer(context, payload) {
    try {
      context.commit("SET_LOADING", true)
      let prt_guid = await context.rootGetters['participant/participant_guid']
      let req_payload = {
        answer: payload.answer
      }
      let resp = await data_api.post(["participants", prt_guid, "upsit", context.state.qnn_guid, "questions", payload.ques_guid, "answer"], req_payload)
      // let resp = await data_api.post(["participants", prt_guid, "upsit", context.state.qnn_guid ,"book", payload.bookNo, "page", payload.pageNo, "answer"], req_payload)
      Vue.$log.debug("upsit setAnswer response:", resp)
      context.commit("SET_ANSWER", resp.data)
      context.commit("SET_LOADING", false)
      return resp.data
    } catch(err) {
      context.commit("SET_LOADING", false)
      context.dispatch("snackbar/display", {message: i18n.t('upsit.storeerror'), color: 'error', timeout: 15}, {root: true})
      Vue.$log.error(err)
    }
  },
  async submitBooklet(context, bookNo) {
    try {
      context.commit("SET_LOADING", true)
      let prt_guid = await context.rootGetters['participant/participant_guid']
      let locale = '?locale=' + context.rootGetters["userSettings/current_locale"]
      let booklet = {
        bookNo: context.state.books[bookNo-1].bookNo,
        pages: context.state.books[bookNo-1].pages
      }
      let resp = await data_api.post(['participants', prt_guid, 'upsit', context.state.qnn_guid, locale], booklet)
      Vue.$log.debug("upsit submitBooklet response", resp)
      context.commit("SET_LOADING", false)
      return resp.data
    } catch(err) {
      context.commit("SET_LOADING", false)
      Vue.$log.error(err)
      throw err
    }
  },
}

const getters = {
  bookNo: state => state.bookNo,
  pageNo: state => state.pageNo,
  windowIndex: state => state.windowIndex,
  kit_no: state => state.kit_no,
  prt_kit_no: state => state.prt_kit_no,
  prt_kit_no_guid: state => state.prt_kit_no_guid,
  books: state => state.books,
  completion_date: state => state.completion_date,
  completion_date_guid: state => state.completion_date_guid,
  deliver_date: state => state.deliver_date,
  book: (state) => {
    return function(bookNo)  {
      return state.books[bookNo-1]
    }
  },
  page: (state, getters) => {
    return function(bookNo, pageNo) {
      let pageIndex = (pageNo-1) - ((bookNo - 1)*10)
      try {
        return getters.book(bookNo).pages[pageIndex]
      } catch(e) {
        return null
      }
    }
  },
  answer: (state) => {
    return function(bookNo, pageNo) {
      if(typeof bookNo == 'number') {
        let pageIndex = (pageNo-1) - ((bookNo - 1)*10)
        return state.books[bookNo-1].pages[pageIndex].answer
      } else {
        return null
      }
    }
  },
  next_unanswered: (state) => {
    for(let bookNo = 0; bookNo < state.books.length; bookNo++) {
      for(let pageNo = 0; pageNo < state.books[bookNo].pages.length; pageNo++) {
        if(['a', 'b', 'c', 'd'].indexOf(state.books[bookNo].pages[pageNo].answer) == -1) {
          return {
            bookNo: bookNo,
            pageNo: pageNo
          }
        }
      }
    }
    return null
  },
  next_booklet: (state) => {
    for(let bookNo = 0; bookNo < state.books.length; bookNo++) {
      if(!state.books[bookNo].completed) {
        return bookNo + 1
      }
    }
    return null
  },
  letters: state => state.letters,
  colors: state => state.colors,
  loading: state => state.loading
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

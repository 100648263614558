<template>
  <v-card :flat="flat">
    <p-card-title
      v-if="showTitle"
      :title="title"
      :mobileTitle="mobileTitle"
      :subtitle="subtitle"
      :icon="titleIcon"
      :loading="loading"
      :fontSizeAdjust="adjustableFont"
      :progress="progress"
    ></p-card-title>
    <v-card-text :class="{textClass, 'pa-0': !cardText}" :style="{'font-size': fontSize + 'em', 'line-height': lineHeight + 'em', 'min-height': minHeight}">
      <slot></slot>
    </v-card-text>
    <v-card-actions v-if="actions">
      <slot name = "actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "pInfoCard",
  props: {
    title: {
      type: String,
      required: true
    },
    mobileTitle: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false,
    },
    titleIcon: {
      type: String,
      required: false
    },
    titleIconColor: {
      type: String,
      default: "primary"
    },
    adjustableFont: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: String,
      required: false,
      default: null
    },
    textClass: {
      type: String,
      required: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Boolean,
      default: true
    },
    cardText: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    fontSize() {
      if(this.adjustableFont) {
        return this.$store.getters['userSettings/font_size']
      } else {
        return null
      }
    },
    lineHeight() {
      if(this.adjustableFont) {
        return this.$store.getters['userSettings/line_height']
      } else {
        return null
      }
    }
  }
}
</script>
const state = {
  font_size: 1.0,
  font_size_increment: 0.1,
  current_locale: null
}

let mutations = {
  SET_FONT_SIZE: (state, payload) => {
    state.font_size = payload
  },
  SET_FONT_SIZE_INCREMENT: (state, payload) => {
    state.font_size_increment = payload
  },
  ADJUST_FONT_SIZE: (state, payload) => {
    if(payload > 0) {
      if(state.font_size < 1.6) {
        state.font_size += state.font_size_increment
      }
    } else {
      if(state.font_size > 1) {
        state.font_size -= state.font_size_increment
      }
    }    
  },
  SET_CURRENT_LOCALE: (state, payload) => {
    state.current_locale = payload
  }
}

let actions = {

}

let getters = {
  font_size: (state) => state.font_size,
  line_height: (state) => {
    return (state.fontSize + 1.6) / 2
  },
  current_locale: (state) => state.current_locale
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c('p-card-title',{attrs:{"title":_vm.deletelabel,"icon":"mdi-alert","iconColor":"warning"}}),_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm.$t('participant.confirmdelete.sure')))]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.description))])]),_c('p',[_vm._v(_vm._s(_vm.$t('participant.confirmdelete.notundone')))])]),_c(VCardActions,[_c(VSpacer),_c('p-action-button',{attrs:{"color":"error","dark":"","icon":"mdi-close","label":_vm.cancellabel},on:{"click":_vm.close}}),_c('p-action-button',{attrs:{"color":"success","dark":"","btnClass":"ml-3","icon":"mdi-check","label":_vm.confirmlabel},on:{"click":_vm.deleteData}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span>
    <v-tooltip top v-if="isTask" v-model="showTooltip">
      <template v-slot:activator="{ on }">
        <v-btn
          bottom
          :color="progressColor"
          dark
          fab
          fixed
          left
          v-on='on'
          id="testingBtn"
          :readonly="progressDisabled"
          @click="progressButton"
          class = "mb-10"
        >
          <v-progress-circular
            v-if="progressPercent < 100"
            :value="progressPercent"
          >{{progressPercent}}</v-progress-circular>
          <v-icon v-if="progressPercent == 100">fa-check</v-icon>
        </v-btn>      
      </template>
      <span v-html="progressText"></span>
    </v-tooltip>
    <div class='text-center'>
      <v-dialog
        v-model="showDialog"
        width="50%"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-card id = "progressCard">
          <p-card-title
            :title="title"
            :subtitle="subtitle"
            icon="fa-question"
            iconColor="accent"
          ></p-card-title>
          <v-card-text class = "mt-5">

            <template v-if="stage == 'firstOpen'">
              <p v-html="$t('participant.progressdialog.firstopen1')">
                </p>
              <p>
                {{ $t('participant.progressdialog.firstopen2') }}
              </p>
            </template>
            
            <p v-if="stage === 'giOpen'">{{ $t('participant.progressdialog.clicksave') }}</p>
            
            <template v-if="stage === 'giRequired'">
              <p v-html="$t('participant.progressdialog.editgeneral')"></p>
              <ul>
                <li v-for="(field, index) in missingFields" v-text="field" :key="index"></li>
              </ul>
            </template>

            <p v-if="stage === 'addressRequired'" v-html="$t('participant.progressdialog.addressreq')"></p>
            <p v-if="stage === 'emailRequired'" v-html="$t('participant.progressdialog.emailreq')"></p>
            <p v-if="stage === 'phoneRequired'" v-html="$t('participant.progressdialog.phonereq')"></p>
            <p v-if="stage === 'complete'" v-html="$t('participant.progressdialog.complete')"></p>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              @click="closeDialog"
              id="progressCardClose"
              v-text="stage==='complete' ? $t('participant.progressdialog.returnhome') : $t('gen.ok')"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </span>
</template>

<script>

//  import { pauseMixin } from './pauseMixin.js'

  export default {
    name: "progressDialog",
//    mixins: [pauseMixin],
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      editingGeneral: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        tooltip: true,
        tooltipText: "Testing",
        tooltipTarget: "#generalEditBtn",
        timeout: 10000,
        firstOpen: true,
        showTooltip: false,
        progressDisabled: true,
        infoComplete: false,
        timeoutFunc: null,
        requiredGeneral: ['first_name', 'last_name', 'sex_at_birth', 'birth_date', 'race', 'ethnicity', 'birth_city', 'birth_country', 'preferred_contact'],
      }
    },

    computed: {
      showDialog: {
        get() {return this.visible},
        set(v) {this.$emit("update:visible", v)}
      },
      missingFields() {
        let that = this
        return this.missingValues.filter(v => {
            return that.requiredGeneral.includes(v)
          }).map(v => {
            return this.generalLabels[v]
          })
      },
      stage() {
        if(this.firstOpen) {
          return "firstOpen"
        } else if(this.infoComplete) {
          return "complete"
        } else {
          let that = this
          let needsGeneral = this.requiredGeneral.some(function(rG) {
            return that.missingValues.includes(rG)
          })
          if(needsGeneral) { // if they haven't filled out all the required general information
            if(this.editingGeneral) {
              return 'giOpen'
            } else {
              return 'giRequired'
            }    
          } else { // if they have filled in all the required fields for general info
            if(this.missingValues.includes("postal_address")) {// Check for address
              return "addressRequired"
            } else if(this.missingValues.includes("phone_number")) { // Check for phone number
              return "phoneRequired"
            } else if(this.missingValues.includes("email_address")) { // Check for email
              return "emailRequired"
            } else {
              return "complete"
            }
          }
        }
      },
      progressColor() {
        if(this.progressPercent < 50) {
          return "red"
        } else if(this.progressPercent < 100) {
          return "yellow"
        } else {
          return "green"
        }
      },
      progressText() {
        if(this.progressPercent < 50) {
          return this.$t('participant.progressdialog.progress1')
        } else if(this.progressPercent < 100) {
          return this.$t('participant.progressdialog.progress2')
        } else {
          return this.$t('participant.progressdialog.progress3')
        }
      },
      progressPercent() {
        let general = this.$store.getters['participant/generalInfo']
        let complete = this.requiredGeneral.map(v => {
          let valid = !!general[v]
          return valid
        }).reduce((pv, cv) => {
          return pv + cv
        })
        if(this.$store.getters['participant/address'].length >= 1) complete++
        if(this.$store.getters['participant/phone'].length >= 1) complete++
        if(this.$store.getters['participant/email'].length >= 1) complete++
        return parseInt(complete/(this.requiredGeneral.length+3) * 100)
      },
      isTask() {
        let t = !!this.$store.state.route.query.task
        return t
      },
      missingValues() {
        let general = this.$store.getters['participant/generalInfo']
        let missing = this.requiredGeneral.filter(v => {
          return !general[v] || general[v] == []
        })
        if(this.$store.getters['participant/address'].length < 1) missing.push("postal_address")
        if(this.$store.getters['participant/phone'].length < 1) missing.push("phone_number")
        if(this.$store.getters['participant/email'].length < 1) missing.push("email_address")
        return missing
      },
      title() {
        return this.$t('participant.progressdialog.title')
      },
      subtitle(){
        return this.$t('participant.progressdialog.subtitle')
      },
      generalLabels() {
          return this.$t('participant.progressdialog.labelsObj')
      },
    },
    methods: {
      returnHome() {
        this.$router.push({name: "home", params: {complete: true, task: this.$store.state.route.query.task}})   
      },
      progressButton() {
        if(this.progressPercent < 50) {
          this.showDialog=true
        } else if(this.progressPercent < 100) {
          this.showDialog=true
        } else {
          this.returnHome()        
        }        
      },
      handlePause() {
        this.showDialog = true
      },
      clearPauseHandler() {
        window.clearTimeout(this.timeoutFunc)
      },
      setPauseHandler() {
        this.clearPauseHandler()
        this.timeoutFunc = window.setTimeout(this.handlePause, 60000)
      },
      closeDialog() {
        this.firstOpen = false
        if(this.stage === "complete") {
          this.returnHome()
        } else {
          this.showDialog = false
        }
      }
    },
    watch: {
      progressPercent(v) {
        if(v >= 100) {
          this.infoComplete = true
        }
      },
      stage(v) {
        this.clearPauseHandler()
        if(v === 'complete') {
          this.showDialog = true
        } else {
          this.setPauseHandler()
        }
      }
    }
  }

</script>

<style scoped>

  strong {
    font-weight: bold;
    font-size: 1.3em;
  }

</style>
<template>
  <p-info-card
    :title="title"
    :subtitle="subtitle"
    :titleIcon="icon"
    :loading="loading"
    :showTitle='showTitle'
    :flat="flat"
  >
    <template v-slot:default>
      <v-card-text v-if="state == 'no_records'"> 
        <p v-html="$t('participant.datainput.norecord', {desc_record_type: recordDescriptions[record_type], record_type: recordDescriptions[record_type]})"></p>
      </v-card-text>
      <v-card-text v-else-if="state == 'ready'" :style="{padding: $vuetify.breakpoint.mobile ? '4px' : null}">
        <slot></slot>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader
          class="mx-auto"
          type="table-row@4"
        ></v-skeleton-loader>
      </v-card-text>
      <p-confirm-delete-dialog
        v-model = "deleteDialog.show"
        :record.sync = "Record"
        :record_type = "record_type"
        :record_description_field = "description_field"
        @remove="$emit('remove', $event)"
      ></p-confirm-delete-dialog>
      <p-data-update-dialog
        :title = "updateDialog.title"
        :icon = "updateDialog.icon"
        v-model = "updateDialog.show"
        :record.sync = "Record"
        :record_type = "record_type"
        :update_type = "updateDialog.type"
        :inputComponent = "inputComponent" 
        @insert="$emit('insert', $event)"
        @update="$emit('update', $event)"
      ></p-data-update-dialog>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <p-action-button
        color = "secondary"
        :label = "actionBtnLabel"
        @click = "newRecord"       
      ></p-action-button>
      <v-spacer v-if="$vuetify.breakpoint.mdAndDown"></v-spacer>
    </template>
  </p-info-card>
</template>

<script>
export default {
  name: "pDataInputCard",
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: true
    },
    progress: {
      type: Number,
      required: false
    },    
    state: {
      type: String,
      default: 'loading'
    },
    inputComponent: {
      type: String,
      required: true
    },


    record_type: {
      type: String,
      required: true
    },    
    record: {
      type: Object
    },
    records: {
      type: Array
    },
    blank: {
      type: Object
    },
    updateDialog: {
      type: Object,
      required: true
    },
    deleteDialog: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      
    }
  },
  computed: {
    Record: {
      get() { return this.record },
      set(v) { this.$emit("update:record", v)}
    },
    description_field() {
      return {
        "address": "line1",
        "phone": "number",
        "email": "email"
      }[this.record_type]
    },
    loading() {
      return this.$store.getters['participant/loading'][this.record_type]
    },
    recordDescriptions() {
      return {
        address: this.$t('gen.postal'),
        email: this.$t('gen.email'),
        phone: this.$t('gen.phone')
      }},
      actionBtnLabel() {
        return `${this.$t('participant.datainput.addnew')} ${this.recordDescriptions[this.record_type]}`
      }
  },
  methods: {
    newRecord() {
      let updateDialog = {
        title: `${this.$t('participant.datainput.addnew')} ${this.recordDescriptions[this.record_type]}`,
        icon: "mdi-home-plus",
        show: true,
        type: 'insert'
      }
      this.$emit("update:record", {})
      this.$emit("update:record", this.blank)
      this.$emit("update:updateDialog", updateDialog)
    }
  },
  watch: {
    loading(v) {
      if(v == false) {
        this.$emit("loaded", this.record_type)
      }
    },
  },
}
</script>
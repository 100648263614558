import data_api from "@/services/axios-data.js";
import Vue from "vue"
import validateGUID from "@/services/guids"

const state = function() {
  return {
    page: 'intro',
    qnn_guid: null,
    title: "",
    subtitle: null, 
    intro: "",
    conclusion: "",
    questions: [],
    loading: false,
    complete: false,
    inReview: false,
    success: null,
    task_key: null,
    notes: null,
  }
}

const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_QNN_GUID(state, payload) {
    if(validateGUID(payload)) {
      state.qnn_guid = payload.toLowerCase()
    } else {
      state.qnn_guid = null
    }
  },
  SET_TITLE(state, payload) {
    state.title = payload
  },
  SET_SUBTITLE(state, payload) {
    state.subtitle = payload
  },
  SET_INTRO(state, payload) {
    state.intro = payload
  },
  SET_CONCLUSION(state, payload) {
    state.conclusion = payload
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload.map(x => {
      x.valid = !!x.answer
      x.skipped = false
      return x
    })
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_COMPLETE(state) {
    let isComplete = true
    for(let i = 0; i < state.questions.length; i++) {
      let q = state.questions[i]
      if(!q.skipped && !q.valid) {
        isComplete = false
        break
      }        
    }
    state.complete = isComplete
  },
  SET_INREVIEW(state, payload) {
    state.inReview = payload
  },
  SET_ANSWER(state, payload) {
    let i = state.questions.findIndex(x => {
      return x.guid.toLowerCase() == payload.ques_guid.toLowerCase()
    });
    let question = state.questions[i]
    Vue.set(question, 'answer', payload.answer) 
  },
  SET_VALID(state, payload) {
    let i = state.questions.findIndex(x => x.guid.toLowerCase() == payload.ques_guid.toLowerCase());
    state.questions[i].valid = payload.valid
  },
  SET_SKIPPED(state) {
    for(let i = 0; i < state.questions.length; i++) {
      let question = state.questions[i]
      let isSkipped = false
      if(typeof question.conditions != 'undefined') {
        let c = question.conditions.map(condition => {
          let data
          if(condition.type === "question") {
            data = state.questions.find(q => q.id === condition.id).answer
          } else if(condition.type == "demographics") {
            data = this.state.participant.generalInfo[condition.id]
          } else {
            return true
          }
          if(data !== undefined) {
            let x = Object.keys(condition)
            console.log(question.id, x)
            if(x.indexOf("equals") > -1) {
              return data == condition.equals
            } else if(x.indexOf("in") > -1) {
              return condition.in.indexOf(data) > -1
            } else if(x.indexOf("contains") > -1) {
              return data.indexOf(condition.contains) > -1
            } else if(x.indexOf("not_empty") > -1) {
              if(condition.not_empty) {
                if(Array.isArray(data)) {
                  return data.length > 0
                } else {
                  return !!data
                }
              }
            } else if(x.indexOf("not_contains") > -1) {
              console.log(x)
              console.log(data)
              console.log(condition)
              return data.indexOf(condition.not_contains) == -1 
            }
          } else {
            return true
          }
        })
        isSkipped = c.some(x => !x)
      }   
      Vue.set(question, 'skipped', isSkipped)    
    }
  },
  SET_SUCCESS(state, payload) {
    state.success = payload
  },
  LOGOUT(state) {
    state.page = 'intro'
    state.qnn_guid = null
    state.title = ""
    state.subtitle = null
    state.intro = ""
    state.conclusion = ""
    state.questions = []
    state.complete = false
    state.inReview = false
    state.success = null
  },
  SET_TASK_KEY(state, payload) {
    state.task_key = payload
  },
  SET_NOTES(state, payload) {
    state.notes = payload
  }
}

const actions = {

  async load(context, payload) {
    try {
      let guid = payload.qnn_guid || context.rootState.route.params.qnn
      let prt_guid = payload.prt_guid
      context.state.loading = true
      if(!prt_guid) {
        prt_guid = await context.rootGetters['participant/participant_guid']
      }
      let resp = await data_api.get(['participants', prt_guid, 'questionnaire', guid])
      Vue.$log.debug("load questionnaire resp", resp)
      let d = resp.data
      context.commit("SET_QNN_GUID", d.qnn_guid)
      context.commit("SET_TITLE", d.title)
      context.commit("SET_SUBTITLE", d.subtitle);
      context.commit("SET_INTRO", d.intro);
      context.commit("SET_CONCLUSION", d.conclusion);
      context.commit("SET_QUESTIONS", d.questions);
      context.commit("SET_SKIPPED")
      context.commit("SET_COMPLETE")
      context.commit("SET_SUCCESS", null)
      context.commit("SET_TASK_KEY", d.task_key);
      context.state.loading = false
      return resp.data
    } catch(err) {
      context.state.loading = false
      Vue.$log.error(err)
      throw new Error("Couldn't load questionnaire")
    }
  },
  async loadNotes(context, payload) {
    try {
      let prt_guid = payload.prt_guid
      context.state.loading = true
      if(!prt_guid) {
        prt_guid = await context.rootGetters['participant/participant_guid']
      }
      let resp = await data_api.get(['participants', prt_guid, 'notes'], {task_key: payload.task_key})
      Vue.$log.debug("load notes resp", resp)
      let d = resp.data
      context.commit("SET_NOTES", d)
    } catch(err) {
      Vue.$log.error(err)
      throw new Error("Couldn't load notes")     
    } finally {
      context.state.loading = false
    }
  },
  async setAnswer(context, payload) {
    context.commit("SET_ANSWER", payload)
    context.commit("SET_SKIPPED")
    context.commit("SET_COMPLETE")
  },
  async submitAnswer({state, rootGetters, getters, dispatch}, payload) {
    let answer = getters.answers.filter(a => a.id === payload.id)
    if(answer.length !== 1) {
      Vue.$log.error("Something went wrong with finding the answer...")
    }
    answer = answer[0]
    let prt_guid = payload.prt_guid
    if(!prt_guid) {
      prt_guid = await rootGetters['participant/participant_guid']
    }
    let body = {
      answer: answer.answer
    }
    try {
      let resp = await Vue.$axios.post(["participants", prt_guid.toLowerCase(), "questionnaire", state.qnn_guid.toLowerCase() ,"question", answer.ques_guid.toLowerCase(), "answer"], body)
      Vue.$log.debug('questionnaire submitAnswer response', resp)
      dispatch("setAnswer", {
        answer: resp.data.answer,
        ques_guid: answer.ques_guid
      })
    } catch(err) {
      Vue.$log.error(err)
      throw new Error("Answer submission error")
    }  
  },
  async submitNotes(context, payload) {
    try {
      Vue.$log.debug("questionnaire submitNotes payload", payload)
      let resp = await Vue.$axios.post(["participants", payload.prt_guid.toLowerCase(), "notes"], {task_key: payload.task_key, notes: payload.notes})
      Vue.$log.debug("questionnaire submitNotes response", resp)
    } catch(err) {
      Vue.$log.error(err)
      throw new Error("submitNotes Error")
    }
  },
  async submit(context) {
    let body = {
      questions: context.state.questions
    }
    try {
     let prt_guid = await context.rootGetters['participant/participant_guid']
     let locale = '?locale=' + context.rootGetters["userSettings/current_locale"]
     let resp = await data_api.post(["participants", prt_guid, "questionnaire", context.state.qnn_guid.toLowerCase(), locale], body)
     context.commit("SET_SUCCESS", true)
     Vue.$log.debug("questionnaire submit response", resp)
    } catch(err) {
      Vue.$log.error(err)
      context.commit("SET_SUCCESS", false)
      throw new Error("Unable to submit questionnaire")
    }
  },
  gotoPage({state}, id) {
    state.page = id
  }
}

const getters = {
  page: state => state.page,
  color: state => state.color,
  qnn_guid: state => state.qnn_guid.toLowerCase(),
  title: state => state.title,
  subtitle: state => state.subtitle,
  intro: state => state.intro,
  conclusion: state => state.conclusion,
  questions: state => state.questions,
  loading: state => state.loading,
  complete: state => state.complete,
  inReview: state => state.inReview,
  success: state => state.success,
  task_key: state => state.task_key,
  notes: state => {
    try {
      let notes_obj = state.notes[0].notes_obj
      return notes_obj[notes_obj.length - 1].notes
    } catch(err) {
      return ""
    }
  },
  
  question: state => {
    return function(id) {
      return state.questions.find(q => q.id == id)
    }
  },
  answer: (state, getters) => {
    return function(id) {
      return getters.question(id).answer
    }
  },
  skipped: (state, getters) => {
    return function(id) {
      return getters.question(id).skipped
    }
  },
  valid: (state, getters) => {
    return function(id) {
      return getters.question(id).valid
    }
  },

  progress: (state) => {
    let progress = 0
    try{
      let num, den
      if(state.questions.length > 0) {
        num = state.questions
          .map(x => {
            let y = x.valid && !x.skipped
            return y
          })
          .reduce((acc, cur) => {
            return cur ? ++acc : acc 
          })
        den = state.questions
          .map(q => {
            return !q.skipped
          })
          .reduce((acc, cur) => {
            return cur ? ++acc : acc 
          })
          progress = num/den*100
        } else {
          num = 0
          den = 1
          progress = 0
        }
    } catch(err) {
      Vue.$log.error(err)
      throw err
    }
    return Math.floor(progress)
  },
  currentQuestion: state => {
    return state.questions.find(q => q.id === state.page)
  },
  questionnaire: (state) => {
    return state;
  },

  answers: (state) => state.questions.map(q => {
    return {ques_guid: q.guid.toLowerCase(), id: q.id, answer: q.answer}
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

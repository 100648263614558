import Vue from 'vue'
import questionnaire from "./ppmi-portal-components/src/modules/Questionnaire"
import consent from "./ppmi-portal-components/src/modules/Consent"
import upsit from "./ppmi-portal-components/src/modules/Upsit"
import participant from "./ppmi-portal-components/src/modules/Participant"
import questionBox from "./ppmi-portal-components/src/modules/questionBox"
import snackbarAlerts from "./ppmi-portal-components/src/modules/snackbarAlerts"
import Home from "./ppmi-portal-components/src/modules/Home"

const modules = [
  questionnaire,
  consent,
  upsit,
  participant,
  questionBox,
  snackbarAlerts,
  Home
]

export default (store) => {
  modules.forEach(module => {
    Vue.use(module, store)
  })
}


<template>
  <v-container>
    <v-input
      :rules="validationRules"
      v-model="answer"
      ref="question"
    >
      <div>
        <v-row dense>
          <v-col cols="12" md="6" lg="4">
            <v-text-field :label="fnamelabel" v-model="contact.first_name"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field :label="lnamelabel" v-model="contact.last_name"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="contact.relationship"
              :label="relationlabel"
              :items="relationitems"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field :label="streetlabel" v-model="contact.street"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="8">
            <v-text-field :label="citylabel" v-model="contact.city"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-select
              :label="statelabel"
              v-model="contact.state"
              :items="['AL', 'CA', 'IN', 'WY']"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-text-field :label="ziplabel" v-model="postal"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field :label="phonelabel" v-model="phone"></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-input>
  </v-container>
</template>

<script>

import { questionMixin } from "../mixins.js"

export default {
  name: "qContact",
  mixins: [questionMixin],
  data: function() {
    return {
      default_rules: []
    }
  },
  computed: {
    contact: {
      get() {
        return {
          first_name: this.question.first_name || "",
          last_name: this.question.last_name || "",
          relationship: this.question.relationship || null,
          street: this.question.street || "",
          city: this.question.city || "",
          state: this.question.state || null,
          postal: this.question.postal|| "",
          phone: this.question.phone|| ""
        }
      },
      set() {
        this.answer = {
          first_name: this.contact.first_name || "",
          last_name: this.contact.last_name || "",
          relationship: this.contact.relationship || null,
          street: this.contact.street || "",
          city: this.contact.city || "",
          state: this.contact.state || null,
          postal: this.contact.postal|| "",
          phone: this.contact.phone|| ""          
        }
      }
    },
    fnamelabel(){
      return this.$t('participant.generalinfo.firstname')
    },
    lnamelabel(){
      return this.$t('participant.generalinfo.lastname')
    },
    relationlabel() {
      return this.$t('qnn.inputs.relationship')
    },
    relationitems(){
      return this.$t('qnn.inputs.relationitems')
    },
    streetlabel(){
      return this.$t('qnn.inputs.streetaddress')
    },
    statelabel(){
      return this.$t('qnn.inputs.state')
    },
    citylabel(){
      return this.$t('qnn.inputs.city')
    },
    ziplabel(){
      return this.$t('qnn.inputs.zip')
    },
    phonelabel(){
      return this.$t('gen.phone')
    }
  },
  methods: {
    preprocess_answer(answer) {
      if(answer === null) {
        answer = {}
      }
      return answer
    }
  },
  watch: {
    contact(v) {
      this.question = v
    }
  }
}
</script>
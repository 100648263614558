<template>
  <div>
  <p-info-card
    :title="title"
    :subtitle="subtitle"
    titleIcon="fa-signature"
    :adjustableFont="true"
    :loading="loading"
    :progress="progress"
    id="consentCard"
  >
    <template v-slot:default>
      <v-window v-model="page" ref="page_window">
        <v-window-item
          v-for="(p, i) in pages"
          :key="i"
          :value="i == 0 ? 'intro' : i == pages.length - 1 ? 'view' : i"
        >
          <component
            :is="pageType(p)"
            :page="p"
            :fontSize="fontSize"
            :lineHeight="lineHeight"
            :id="`consentPage${i}`"
          ></component>
        </v-window-item>
        <attestation-page
          v-if="page_counter > 0"
          :fontSize="fontSize"
          :lineHeight="lineHeight"
          :allowSubmit="allowSubmit"
          @submit="dialog=true"
          @show-consent="fullConsentDialog=true"
          id="attestationPage"
        ></attestation-page>
        <consent-conclusion 
          v-if="page_counter > 0"
          :fontSize="fontSize"
          :lineHeight="lineHeight"
          id="conclusionPage"
        ></consent-conclusion>
      </v-window>
    </template>
    <template v-slot:actions>
      <v-btn 
        ref="backButton"
        v-if="page != 'attest'"
        :disabled="disableBack" 
        class="px-8" 
        color="accent" 
        rounded 
        id="prevPage"
        @click="prevPage()"
      >{{ $t('gen.back') }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn 
        ref="forwardButton"
        v-if="page != 'attest'"
        class="px-8" 
        color="accent" 
        rounded 
        @click="nextPage()"
        id="nextPage"
      >{{forwardButtonLabel}}</v-btn>
    </template>
  </p-info-card>
    <consent-bottom-sheet 
      v-model="showBottomSheet"
      @submit="dialog=true"
    ></consent-bottom-sheet>
    <consent-dialog-consent
      :show="dialog && ((status=='consented') || (status=='consented_no_gt'))"
      v-on:closeDialog="dialog=false"
      v-on:response="submit"
      :allowSubmit="allowSubmit"
    ></consent-dialog-consent>
    <consent-dialog-decline 
      :show="dialog && status=='declined'"
      v-on:closeDialog="dialog=false"
      v-on:response="submit"
      :allowSubmit="allowSubmit"
    ></consent-dialog-decline>
    <consent-dialog-question 
      :show="dialog && status=='questions'"
      v-on:closeDialog="dialog=false"
      v-on:response="submit"
      :allowSubmit="allowSubmit"
    ></consent-dialog-question>    
    <v-row justify="center">
    <p-full-consent
      v-model="fullConsentDialog"
      :consent_body="consent_body"
      @close="closeConsent"
    >
    </p-full-consent>
  </v-row>  
  </div>
</template>

<script>

  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters } = createNamespacedHelpers('consent')

  export default {
    name: "consentPage",
    props: {
      qnn_guid: {
        type: String,
        required: false,
      }
    },
    async created() {
      try {
        await this.$store.dispatch("consent/load", this.qnn_guid)
        // if(consent.participant_data !== null) {
        //   this.$store.dispatch("snackbar/display", {message: "You already completed this consent."})
        //   this.$router.push({name: "home"})
        // }
        // if(consent.participant_data) {
        //   if(consent.participant_data.status) {
        //     this.$store.dispatch("snackbar/display", {message: "You have already completed the Informed Consent. If you would like to change your consent status or have questions please contact study staff."})
        //     this.$router.push({name: 'home'})
        //   }
        // }
      } catch(err) {
        this.$store.dispatch("snackbar/display", {message: "There was a problem loading your Informed Consent. <br />Please try again later. If the problem persists contact study staff for help."})
        this.$router.push({name: "home"})
      }
    },
    data() {
      return {
        subtitle: null,
        showBottomSheet: false,
        dialog: false,
        fullConsentDialog: false,
        page_counter: 0,
        allowSubmit: true,
      }
    },
    computed: {
      ...mapGetters(['pages', 'consent_body']),
      status: {
        get() {
          return this.$store.getters['consent/status']
        },
        set(v) {
          this.$store.commit("consent/SET_STATUS", v);
        }
      },
      fontSize() {
        return this.$store.getters["userSettings/font_size"]
      },
      disableBack() {
        return this.page === 'intro' || this.page === "conclusion"
      },
      progress() {
        return this.page_counter / (this.pages.length-1) * 100
      },
      page: {
        get() {
          return this.$store.getters["consent/page"]
        },
        set(x) {
          this.$store.commit("consent/SET_PAGE", x)
        }
      },
      loading: {
        get() {
          return this.$store.getters['consent/loading']
        },
        set(v) {
          this.$store.commit("consent/loading", v)
        }
      },
      lineHeight() {
        return (this.fontSize + 1.6) / 2
      },
      forwardButtonLabel() {
        switch(this.page) {
          case 'conclusion':
            return this.$t('participant.progressdialog.returnhome')
          case 'view':
            return this.$t('consent.viewConsent')
          case 'attest':
            return this.$t('gen.continue')
          default:
            return this.$t('gen.next')
        }
      },
      title(){
        return this.$t('consent.fullConsent.fullconsenttitle')
      }
    },
    methods: {
      pageType(p) {
        if(this.$vuetify.breakpoint.smAndDown) {
          return 'pageImageNone'
        } else if(Object.keys(p).indexOf('image') == -1) {
          return 'pageImageNone'
        } else {
          return Math.random() > 0.5 ? 'pageImageLeft' : "pageImageRight"
        }
      },
      setFont(dir) {
        if(dir > 0) {
          if(this.fontSize < 1.6) {
            this.fontSize += 0.1
          }
        } else {
          if(this.fontSize > 1) {
            this.fontSize -= 0.1
          }
        }
      },
      closeConsent() {
        this.fullConsentDialog = false
        this.page = "attest"
      },
      nextPage() {
        this.page_counter++;
        if(this.page === 'confirm') {
          this.showBottomSheet = true
        } else if(this.page === 'conclusion') {
          this.$router.push({name: "home", params: {complete: true, task: this.$store.state.route.query.task}})   
        } else if(this.page === "view") {
          this.fullConsentDialog = true
        } else {
          this.$refs.page_window.next()
        }
      },
      prevPage: function() {
        this.$refs.page_window.prev()
      },
      async submit(x) {
        this.showBottomSheet = false
        try {
          this.allowSubmit = false
          const submission = await this.$store.dispatch("consent/submit", Object.assign(x, {status: this.status}))
          this.dialog = false
          if(submission != 'error'){
            this.$refs.page_window.next()  
          } else {
            this.allowSubmit = true
            this.status = "error"
          }
        } catch(err) {
          this.allowSubmit = true
          this.status = "error"
        }
      }
    },
    watch: {
      qnn_guid() {
        this.$store.dispatch("consent/load", this.qnn_guid)
        this.page = 0
      }
    }
  }
</script>
import data_api from "@/services/axios-data.js";
import validateGUID from "../../services/guids"
import Vue from "vue"
import i18n from "@/i18n"
import store from '@/store/'

const null_state = {
  guid: null,
  track: null,
  status: null,
  hold_comments: null,
  follow_up_date: null,
  last_login: null,
  consent_version: null,
  consent_status: null,
  travel_eligible: null,
  generalInfo: {
    first_name: null,
    middle_name: null,
    last_name: null,
    birth_first_name: null,
    birth_middle_name: null,
    birth_last_name: null,
    sex_at_birth: null,
    birth_date: null,
    race: [],
    ethnicity: null,
    birth_city: null,
    birth_country: null,
    preferred_contact: null,
    preferred_lang: null
  },
  travel_preferences: {
    comfortable_traveling: null,
    will_have_companion: null,
    unsure_companion: null,
    companion_fname: null,
    companion_lname: null,
    companion_phone: null,
    companion_phone_type: null,
    has_special_needs: null,
    special_needs_text: null,
    emergency_fname: null,
    emergency_lname: null,
    emergency_phone: null,
    emergency_phone_type: null,
    emergency_alt_phone: null,
    emergency_alt_phone_type: null,
    emergency_relationship: null,
    from_address: null
  },
  address: [],
  email: [],
  phone: [],
  loading: {
    general: false,
    address: false,
    phone: false,
    email: false,
    travel: false
  },
  prt_flags: {

      "DAT Scan": false,
      "PPMI Remote": false,
      "PPMI Remote: Genetic": false,
      "PPMI Remote: UPSIT": false,
  }
}

const state = Object.assign({}, null_state);

let blanks = {
  address: {
    line1: null,
    line2: null,
    city: null,
    state_prov: null,
    country: null,
    zipcode: null,
    is_primary: false
  },
  phone: {
    allow_text: false,
    allow_vm: false,
    is_primary: false,
    number: "",
    type: null
  },
  email: {
    email: null,
    is_primary: false,
    type: null
  }
}

const mutations = {
  SET_GUID: (state, payload) => {
    if(validateGUID(payload)) {
      state.guid = payload.toLowerCase()
    } else {
      Vue.$log.error(`Invalid GUID: ${payload}`)
      state.guid = null
    }
  },
  SET_TRACK: (state, payload) => {
    state.track = payload
  },
  SET_STATUS: (state, payload) => {
    state.status = payload
  },
  SET_HOLD_COMMENTS: (state, payload) => {
    state.hold_comments = payload
  },
  SET_FOLLOW_UP_DATE: (state, payload) => {
    state.follow_up_date = payload
  },
  SET_LAST_LOGIN: (state, payload) => state.last_login = payload,
  SET_TRAVEL_ELIGIBLE: (state, payload) => state.travel_eligible = payload,
  SET_TRAVEL_PREFERENCES: (state, payload) => {
    let keys = Object.keys(null_state.travel_preferences)
    keys.forEach(k => {
      state.travel_preferences[k] = payload[k]
    })
  },
  SET_GENERAL_INFO: (state, payload) => {
    let g = {}
    Object.keys(state.generalInfo).forEach(key => g[key] = payload[key])
    let race = payload.race || null
    if(!Array.isArray(race)) { // Special case for Race arrays
      if(typeof race === "string") {
        race = [race]
      } else {
        race = []
      }
    } else {
      race = race.filter(obj => obj !== null)
    }
    g.race = race
    state.generalInfo = g
  },
  SET_ADDRESS: (state, payload) => {
    if(payload === null) {
      payload = []
    } else if(!Array.isArray(payload)) {
      if(typeof payload === "object") {
        payload = [payload]
      } else {
        payload = []
      }
    } else {
      payload = payload.filter(obj => obj !== null)
    }
    Vue.set(state, "address", payload)
  },
  SET_PHONE: (state, payload) => {

    if(payload === null) {
      payload = []
    } else if(!Array.isArray(payload)) {
      if(typeof payload === "object") {
        payload = [payload]
      } else {
        payload = []
      }
    } else {
      payload = payload.filter(obj => obj !== null)
    }
    Vue.set(state, "phone", payload)
  },
  SET_EMAIL: (state, payload) => {

    if(payload === null) {
      payload = []
    } else if(!Array.isArray(payload)) {
      if(typeof payload === "object") {
        payload = [payload]
      } else {
        payload = []
      }
    } else {
      payload = payload.filter(obj => obj !== null)
    }
    Vue.set(state, "email", payload)
  },
  SET_LOADING: (state, payload) => {
    Vue.set(state.loading, payload.type, payload.value)
  },
  SET_CONSENT_VERSION: (state, payload) => {
    state.consent_version = payload
  },
  SET_CONSENT_STATUS: (state, payload) => {
    state.consent_status = payload
  },
  SET_PRT_FLAGS: (state, payload) => {

    let keys = Object.keys(null_state.prt_flags)

    let valRef = payload;

    if(valRef === null) valRef = null_state.prt_flags;

    keys.forEach(k => {
      state.prt_flags[k] = (valRef[k] === "true")
    })
  },
  LOGOUT: (state) => {
    state.guid = null
    state.track = null
    state.status = null
    state.last_login = null
    state.travel_eligible = null
    state.consent_version = null
    state.consent_status = null
    state.generalInfo.first_name = null
    state.generalInfo.middle_name = null
    state.generalInfo.last_name = null
    state.generalInfo.birth_first_name = null
    state.generalInfo.birth_middle_name = null
    state.generalInfo.birth_last_name = null
    state.generalInfo.sex_at_birth = null
    state.generalInfo.birth_date = null
    state.generalInfo.race = null
    state.generalInfo.ethnicity = null
    state.generalInfo.birth_city = null
    state.generalInfo.birth_country = null
    state.generalInfo.preferred_contact = null
    state.generalInfo.preferred_lang = null
    state.travel_preferences = {}
    state.address = []
    state.email = []
    state.phone = []
    state.prt_flags = {}
  }
}

Object.keys(state.generalInfo).forEach(key => {
  mutations[`SET_${key.toUpperCase()}`] = (state, payload) => {
    Vue.set(state.generalInfo, key, payload)
  }
})

const actions = {
  async load(context, payload) {
    try {
      context.state.loading.general = true
      context.state.loading.phone = true
      context.state.loading.email = true
      context.state.loading.address = true
      let resp = await data_api.get(['participants', payload.userid])
      Vue.$log.debug("load participant response", resp)
      await context.dispatch("setParticipant", resp.data)
//      if(resp.data.travel_eligible == true) {
        // let tr_resp = await data_api.get(['participants', resp.data.guid, "travel"])
        // Vue.$log.debug("load participant travel resp", tr_resp)
        // context.commit("SET_TRAVEL_PREFERENCES", tr_resp.data)
//      } 
      // context.commit("SET_CONSENT_VERSION", resp.data.consent_version)
      // context.commit("SET_GUID", resp.data.guid)
      // context.commit("SET_STATUS", resp.data.prt_status)
      // context.commit("SET_TRACK", resp.data.prt_track)
      // context.commit("SET_LAST_LOGIN", resp.data.last_login)
      // context.commit("SET_GENERAL_INFO", resp.data)
      // context.commit("SET_ADDRESS", resp.data.address)
      // context.commit("SET_PHONE", resp.data.phone)
      // context.commit("SET_EMAIL", resp.data.email)
      context.state.loading.general = false
      context.state.loading.phone = false
      context.state.loading.email = false
      context.state.loading.address = false
    } catch(err) {
      let logout = true
      if(!payload.retry) {
        if(err.response) {
          if(err.response.status == 401) {
            if(context.rootState.refresh_token) {
              Vue.$log.info("Attempting to use refresh token.")
              if(await context.dispatch("auth/REFRESH_TOKEN", null, {root: true})) {
                logout = false
              }
            }
          }
        }
      }
      if(!logout) {
        context.dispatch("load", Object.assign(payload, {retry: true}))
      } else {
        context.dispatch("snackbar/display", {message: i18n.t('participant.store.loaderror'), color: "red"}, {root: true})
        context.state.loading.general = false
        context.state.loading.phone = false
        context.state.loading.email = false
        context.state.loading.address = false
        context.dispatch("auth/LOGOUT", null, {root: true})
        throw err
      }
    }
    return true
  },

  async setParticipant(context, prt) {
    context.commit("SET_CONSENT_VERSION", prt.consent_version)
    context.commit("SET_CONSENT_STATUS", prt.consent_status)
    context.commit("SET_GUID", prt.guid)
    context.commit("SET_STATUS", prt.prt_status)
    context.commit("SET_HOLD_COMMENTS", prt.hold_comments)
    context.commit("SET_FOLLOW_UP_DATE", prt.follow_up_date)
    context.commit("SET_TRACK", prt.prt_track)
    context.commit("SET_LAST_LOGIN", prt.last_login)
    context.commit("SET_TRAVEL_ELIGIBLE", prt.travel_eligible)
    context.commit("SET_GENERAL_INFO", prt)
    context.commit("SET_ADDRESS", prt.address)
    context.commit("SET_PHONE", prt.phone)
    context.commit("SET_EMAIL", prt.email)
    context.commit("SET_PRT_FLAGS", prt.prt_flags)
    if(prt.preferred_lang != null){
      // console.log('not null, setting')
      context.dispatch("cleanLocale", prt.preferred_lang)
    }
  },

  async cleanLocale(context, locale){
    if(locale == context.rootGetters["userSettings/current_locale"]){
      return
    }
    let arr = []
    let cleaned = ''

    arr = locale.split('-')
    if(arr.length < 2){
      arr = locale.split('_')
    }
    if(arr.length < 2){
      return
    }else{
      cleaned = arr[0].toLowerCase() + '-' + arr[1].toUpperCase()
    }
    i18n.locale = cleaned
    store.commit("userSettings/SET_CURRENT_LOCALE", cleaned, { root: true })
    store.dispatch("options/load", true)
    store.dispatch("tasks/load")
  },

  async updateGeneral(context, payload) {
    try {    
      context.state.loading.general = true
      payload.data.guid = context.state.guid
      payload.data.userid = context.state.userid
      delete payload.data.name_different
      let resp = await data_api.put(['participants', context.state.guid], payload.data)
      Vue.$log.debug("update generalInfo response", resp)
      context.commit("SET_GENERAL_INFO", resp.data)
      if(resp.data.preferred_lang != null){
        context.dispatch("cleanLocale", resp.data.preferred_lang)}
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.infoupdated')}, {root: true})
    } catch(err) {
      Vue.$log.error(err)
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.infoerror'), color: 'error', timeout: 15}, {root: true})
    } finally {
      context.state.loading.general = false 
    }
  },
  async addContact(context, payload) {
    try {
      context.state.loading[payload.type] = true
      if(context.state[payload.type].length == 0 || context.state[payload.type][0] === null) { // If this is their first address, phone, or email automatically set it to primary
        payload.data.is_primary = true
      }
      let resp = await data_api.post(["participants", context.state.guid, payload.type], payload.data)
      context.commit("SET_" + payload.type.toUpperCase(), resp.data)
      context.state.loading[payload.type] = false
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.contactupdated')}, {root: true})
    } catch(err) {
      context.state.loading[payload.type] = false
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.contacterror'), color: 'error', timeout: 15}, {root: true})
    }
  },
  async updateContact(context, payload) {
    try {
      context.state.loading[payload.type] = true
      let resp = await data_api.put(["participants", context.state.guid, payload.type, payload.data.guid], payload.data)
      Vue.$log.debug("updateContact response", resp)
      context.commit("SET_" + payload.type.toUpperCase(), resp.data)
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.infoupdated')}, {root: true})
    } catch(err) {
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.contacterror'), color: 'error', timeout: 15}, {root: true})
    } finally {
      context.state.loading[payload.type] = false
    }
  },
  async deleteContact(context, payload) {
    try {
      context.state.loading[payload.type] = true
      let resp = await data_api.delete(["participants", context.state.guid, payload.type, payload.data.guid])
      context.commit("SET_" + payload.type.toUpperCase(), resp.data)
      context.state.loading[payload.type] = false
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.infodeleted')}, {root: true})     
    } catch(err) {
      context.state.loading[payload.type] = false
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.deletederror'), color: 'error', timeout: 15}, {root: true})
    }
  },
  async saveTravel(context, payload) {
    try {
      context.state.loading['travel'] = true
      payload.prt_guid = context.state.guid 
      let resp = await data_api.post(['participants', context.state.guid, 'travel'], payload)
      Vue.$log.debug("saveTravel resp", resp)
      context.commit("SET_TRAVEL_PREFERENCES", resp.data)
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.prefsaved')}, {root: true})     
      return resp.data
    } catch(err) {
      context.dispatch("snackbar/display", {message: i18n.t('participant.store.preferror'), color: 'error', timeout: 15}, {root: true})
      throw err
    } finally {
      context.state.loading['travel'] = false
    }
  }
}

const getters = {
  guid: state => state.guid,
  status: state => {
    if(state.status === null) {
      return "Active"
    }
    return state.status
  },
  hold_comments: state => state.hold_comments,
  follow_up_date: state => state.follow_up_date,
  track: state => state.track,
  last_login: state => state.last_login,
  prt_guid: state => state.guid,
  travel_eligible: state => state.travel_eligible,
  travel_preferences: state => state.travel_preferences,
  generalInfo: state => {
    let gi = state.generalInfo
    gi.name_different = (!!state.generalInfo.birth_first_name || !!state.generalInfo.birth_last_name || !!state.generalInfo.birth_middle_name)
    return gi
  },
  address: state => state.address,
  email: state => state.email,
  phone: state => state.phone,
  loading: state => state.loading,
  record: (state) => {
    return function(type, guid) {
      let r
      if(guid === null) {
        r = null
      } else {
        r = state[type].find(x => x.guid == guid)
      }
      if(typeof r === 'undefined' || r === null) {
        r = blanks[type]
      }
      return Object.assign({}, r)      
    }
  },
  name_different: (state) => {
    return (!!state.generalInfo.birth_first_name || !!state.generalInfo.birth_last_name || !!state.generalInfo.birth_middle_name)
  },
  loaded: state => validateGUID(state.guid),
  missingFields: state => {
    let required_fields = ['first_name', 'last_name', 'sex_at_birth', 'birthdate', 'race', 'ethnicity', 'birth_city', 'birth_country', 'preferred_contact']
    let missing_fields = required_fields.filter(field => {
      return !state.generalInfo[field] || (Array.isArray(state.generalInfo[field]) && state.generalInfo[field].length == 0)
    })
    return missing_fields
  },
  blanks: () => blanks,
  firstLogin: (state => state.last_login == null),
  consent_version: state => state.consent_version,
  isConsented: state => {
    return (state.consent_status == "consented")
  },
  participant_guid: state => {
    return new Promise(function (resolve) {
      (function waitForGUID(){
          if (state.guid !== null) {
            return resolve(state.guid)
          }
          setTimeout(waitForGUID, 100)
      })();
    });
  },
  prt_flags: state => state.prt_flags,
  preferred_lang: state => state.generalInfo.preferred_lang
}

Object.keys(state.generalInfo).forEach(key => {
  getters[key] = state => state.generalInfo[key]
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

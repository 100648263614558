<template>
  <v-row class="mt-5"> 
    <v-col>
      <!-- <v-slider
        v-model="answer"
        thumb-label="always"
        :min="options.min" 
        :max="options.max" 
        :step="options.step"
        class="align-center"
        lazy-validation
        hint="Slide the pointer to select you answer or enter it in the text box to the right"
        persistent-hint          
      >
        <template v-slot:append> -->
          <v-text-field 
            ref="question"
            :rules="validationRules"
            type="number" 
            v-model="answer" 
            class="mt-0 pt-0"
            :min="options.min" 
            :max="options.max" 
            single-line
            :step="options.step"
            style="width: 90px"
            @keypress.enter="nextPage"
            :disabled="disabled"
          ></v-text-field>                  
        <!-- </template>
      </v-slider>            -->
    </v-col>
  </v-row>
</template>

<script>

import { questionMixin } from "../mixins.js"

export default {
  name: "qNumeric",
  mixins: [questionMixin],
  data: function() {
    return {
      default_rules: ['not_zero', 'required']
    }
  },
  computed: {
    options() {
      return this.question.options
    }
  },
  methods: {
    nextPage(ev) {
      ev.preventDefault()
    }
  }
}
</script>
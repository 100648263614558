<template>
  <p-info-card
    :title="title"
    :subtitle="subtitle"
    titleIcon="fa-edit"
    :loading="loading"
    :progress="progress"
    minHeight="400px"
  >
    <template v-slot:default>
      <v-window v-model="page" touchless ref="question_window">
        <questionnaire-intro></questionnaire-intro>
        <questionnaire-question
          v-for="(question) in questions"
          :ref="question.id"
          :key="question.id"
          :question="question"
        ></questionnaire-question>                  
        <questionnaire-review v-if="started"></questionnaire-review>
        <questionnaire-conclusion v-if="started"></questionnaire-conclusion>
      </v-window>
    </template>
    <template v-slot:actions>
      <v-spacer v-if="$vuetify.breakpoint.mdAndDown"></v-spacer>
      <v-btn 
        ref="backButton"
        :disabled="disableBack" 
        class="px-8" 
        color="accent" 
        rounded 
        @click="prevPage()"
      >{{ $t('gen.back') }}</v-btn>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>
      <v-btn
        v-show="showReviewButton"
        ref="reviewButton"
        color="accent"
        rounded
        class="px-8" 
        @click="$store.commit('questionnaire/SET_PAGE', 'review')"
      >
        {{ $t('gen.review') }}
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>
      <v-btn 
        ref="forwardButton"
        class="px-8" 
        :disabled="disableForward"
        color="accent" 
        rounded 
        @click="nextPage()"
      >{{forwardButtonLabel}}</v-btn>
      <v-spacer v-if="$vuetify.breakpoint.mdAndDown"></v-spacer>      
    </template>
  </p-info-card>
</template>

<script>

  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters } = createNamespacedHelpers('questionnaire')

  export default {
    name: "questionnairePage",
    props: {
      qnn_guid: {
        type: String,
        required: false
      }
    },
    async created() {
      try {
        await this.$store.dispatch("questionnaire/load", {qnn_guid: this.qnn_guid})
      } catch(err) {
        this.$log.error(err)
        this.$store.dispatch("snackbar/display", {message: this.$t('qnn.questionnaire.loaderror')})
        this.$router.push({name: 'home'})
      }
    },
    data: function() {
      return {
        status: [],
        started: false,
      }
    },
    computed: {
      ...mapGetters(['title', 'subtitle', 'questions', 'currentQuestion', 
                     'valid', 'progress', 'complete', 'inReview']),
      page: {
        get() {
          return this.$store.getters['questionnaire/page']
        },
        set(v) {
          this.$store.commit('questionnaire/SET_PAGE', v)
        }
      },
      loading: {
        get() {
          return this.$store.getters['questionnaire/loading']
        },
        set(v) {
          this.$store.commit("questionnaire/SET_LOADING", v)
        }
      },
      showReviewButton() {
        return this.inReview && this.complete && ['review', 'intro', 'conclusion'].indexOf(this.page) == -1
      },
      disableBack() {
        if(this.loading) {
          return true
        } else {
          switch(this.page) {
            case 'intro':
              return true
            case 'conclusion':
              return true
            default:
              return false
          }
        }
      },
      disableForward() {
        if(this.loading) {
          return true
        } else {
          switch(this.page) {
            case 'intro':
              return false
            case 'review':
              return !this.complete
            case 'conclusion':
              return false
            default:
              return !this.currentQuestion.valid
          }
        }
      },
      forwardButtonLabel() {
        switch(this.page) {
          case 'intro':
            return this.$t('gen.begin')
          case 'review':
            return this.$t('gen.submit')
          case 'conclusion':
            return this.$t('participant.progressdialog.returnhome')
          default:
            return this.$t('gen.next')
        }
      }
    },
    methods: {
      async nextPage() {
        this.started = true
        switch(this.page) {
          case 'intro': 
            this.$refs.question_window.next()
            break
          case 'conclusion':
            this.$router.push({name: "home", params: {completed: true, task: this.$store.state.route.query.task}})
            break
          case 'review':
            await this.$store.dispatch("questionnaire/submit");
            this.$refs.question_window.next()
            break
          default:
            try {
              await this.$store.dispatch("questionnaire/submitAnswer", {id: this.page})
              this.$refs.question_window.next()
            } catch(err) {
              this.$store.dispatch("snackbar/display", {message: this.$t('qnn.questionnaire.submiterror'), color: 'error'})
            }            
        }
      },
      prevPage: function() {
        this.$refs.question_window.prev()
      },
    },
    watch: {
      async qnn_guid() {
        await this.$store.dispatch("questionnaire/load", this.qnn_guid)
        this.page = "intro"
      },
      page(v) {
        if(v == 'review') {
          this.$store.commit("questionnaire/SET_INREVIEW", true)
        }
      }
    }
  }
  
</script>
<template>
  <v-dialog v-model='show' :fullscreen="$vuetify.breakpoint.mobile" persistent max-width="1000px" eager>
    <v-card>
      <v-card-title class = "headline">{{ $t('consent.questionDialog.title') }}</v-card-title>
      <v-card-text>
        {{ $t('consent.questionDialog.contacting') }}
        <v-textarea
          v-model = "question"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="cancel">{{ $t('gen.cancel') }}</v-btn>
        <v-btn color="primary darken-1" :disabled="disabled" text @click="save">{{ $t('gen.submit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: "consentDialogQuestion",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      allowSubmit: {
        type: Boolean,
        default: true
      },
    },
    data: function() {
      return {
        question: "",
      }
    },
    computed: {
      disabled: function() {
        return this.question === "" || !this.allowSubmit;
      }
    },
    methods: {
      save: function() {
        this.$emit("response", {
          question: this.question
        })
        // this.$emit("closeDialog");
      },
      cancel: function() {
        this.$emit("closeDialog")
      }
    },
  }

</script>
import axios from 'axios';
import store from '@/store';
import Vue from 'vue'

export default function(base_url) {

  const instance = axios.create({
    baseURL: base_url || process.env.VUE_APP_API_URL,
  })

  function makeURL(url) {
    if(Array.isArray(url)) {
      url = url.join("/");
    }
    return url;
  }
  
  return {
    instance: instance,
    base_url: base_url || process.env.VUE_APP_API_URL,
    get: function(url, payload) {
      url = makeURL(url);
      Vue.$log.info("GET " + url + " payload:", payload)
      return instance.get(url, {params: payload, headers: {"Authorization": store.getters['auth/authorization_header']}});    
    },
    post: function(url, payload) {
      url = makeURL(url);
      Vue.$log.info("POST " + url + " payload:", payload)
      return instance.post(url, payload, {headers: {"Authorization": store.getters['auth/authorization_header']}})
    },
    put: function(url, payload) {
      url = makeURL(url);
      Vue.$log.info("PUT " + url + " payload:", payload)
      return instance.put(url, payload, {headers: {"Authorization": store.getters['auth/authorization_header']}});
    },
    delete: function(url, payload) {
      url = makeURL(url);
      Vue.$log.info("DELETE " + url + " payload:", payload)
      return instance.delete(url, {headers: {"Authorization": store.getters['auth/authorization_header']}});
    }
  }

}
<template>
  <v-dialog v-model='show' :fullscreen="$vuetify.breakpoint.mobile" persistent max-width="1000px" eager>
    <v-card id="consentConsentDialog">
      <v-card-title class = "headline">{{ $t('gen.thankyou') }}</v-card-title>
      <v-card-text>
        {{ $t('consent.consentDialog.entername') }}
        <v-form ref="form">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
              <v-text-field
                v-model="full_name"
                :rules="[not_empty]"
                :label="nameLabel"
                id="consentFullName"
              ></v-text-field>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
              <v-text-field
                v-model="sign_date"
                :label="dateLabel"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style = "text-align: center;">
              <VueSignaturePad
                id="consentSignature"
                style="border: dotted 2px #BBB; border-bottom: none;"
                width="100%"
                height="200px"
                ref="signaturePad"
                :options="{backgroundColor: '#FFF', penColor: 'blue'}"
              /><div :style="{color: sig_color}" style="font-size: 1em; width: 100%; border-top: solid 1px black;">{{sig_hint}}</div>      
              <v-btn v-if="$vuetify.breakpoint.mobile" :disabled="disableClear" color="primary darken-1" text @click='clearSig' id="clearSignature">{{ $t('consent.consentDialog.clear') }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!$vuetify.breakpoint.mobile">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" :disabled="disableClear" text @click='clearSig' id="clearSignature">{{ $t('consent.consentDialog.clear') }}</v-btn>
        <v-btn color="primary darken-1" :disabled="disableCancel" text @click="cancel" id="cancelConsent">{{ $t('gen.cancel') }}</v-btn>
        <v-btn color="primary darken-1" :disabled="disableSubmit" text @click="save" id="submitConsent">{{ $t('gen.submit') }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" :disabled="disableCancel" @click="cancel" id="cancelConsent">{{ $t('gen.cancel') }}</v-btn>
        <v-btn color="primary darken-1" :disabled="disableSubmit" @click="save" id="submitConsent">{{ $t('gen.submit') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import Vue from "vue"
  import VueSignaturePad from "vue-signature-pad";
  Vue.use(VueSignaturePad)

  export default {
    name: 'consentDialogConsent',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      allowSubmit: {
        type: Boolean,
        default: true
      },
    },
    data: function() {
      return {
        full_name: "",
        sign_date: new Date().toISOString().substr(0,10),
        sig_color: "#555",
        disabled: false,
      }
    },
    watch: {
      show: function() {
        setTimeout(function(that) {
          that.$refs.signaturePad.resizeCanvas();
        }, 200, this)         
      }
    },
    computed: {
      disableAll() {
        return this.disabled || !this.allowSubmit
      },
      sigEmpty() {
        console.log("checking")
        try {
          return this.$refs.signaturePad.isEmpty()
        } catch(err) {
          return true
        }
      },
      signature() {
        let data = undefined
        try {
          const {d} = this.$refs.signaturePad.saveSignature()
          data = d
        } catch(err) {
          data = undefined
        }
        return data
      },
      disableSubmit() {
        return this.full_name === "" || this.sigEmpty || this.disableAll;
      },
      disableClear() {
        return this.disableAll;
      },
      disableCancel() {
        return this.disableAll;
      },
      not_empty() {
          return (v) => {
            return !!v || this.$t('consent.consentDialog.fullname')
        }},
      sig_hint(){
        return this.$t('consent.consentDialog.signature')
      },
      nameLabel() {
        return this.$t('gen.fullname')
      },
      dateLabel() {
        return this.$t('gen.date')
      }
    },
    methods: {
      clearSig: function() {
        this.$refs.signaturePad.undoSignature();
      },
      save: function() {

        this.disabled = true

        const {data} = this.$refs.signaturePad.saveSignature()

        let valid = this.$refs.form.validate()

        if(data === undefined) {
          this.sig_hint = this.$t('consent.consentDialog.sig_hint1')
          this.sig_color = "red"
          valid = false
        } else {
          this.sig_hint = this.$t('consent.consentDialog.sig_hint2')
          this.sig_color = "#555"
        }

        if(valid) {
          this.$emit("response", {
            prt_full_name: this.full_name,
            prt_date: new Date(this.sign_date).toUTCString(),
            prt_sig: data
          })
          this.disabled = false
          // this.$emit("closeDialog")
        } else {
          this.disabled = false
        }
        
      },
      cancel: function() {
        this.$emit("closeDialog")
      }
    },
  }

</script>
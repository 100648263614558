<template>
  <span>
  <v-form ref="form" v-model="validForm">
    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="record.email"
            label="Email Address"
            :rules="rules.email"
            :dense="dense"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="record.type"
            :items="types"
            label="Type"
            :rules="rules.type"
            :dense="dense"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  </span>
</template>

<script>

  import { formMixin } from "./mixin.js"

  export default {
    name: "pEmailInput",
    mixins: [formMixin],
    data: function() {
      let not_empty = (v) => {
        if(v == undefined) return true
        return v.length > 0 || this.$t('gen.notempty')
      }
      return {
        rules: {
          email: [not_empty],
          type: [not_empty],
        }
      }
    },
    computed: {
      emaillabel(){
        return this.$t('gen.email')
      },
      typelabel(){
        return this.$t('participant.emailform.type')
      },
      types(){
        return [
          this.$t('participant.emailform.personal'), 
          this.$t('participant.emailform.work')
          ]
      },
      bools(){
        return [
          {text: this.$t('gen.yes'), value: true},
          {text: this.$t('gen.no'), value: false}
        ]
      }
    }
  }

</script>

<style>

</style>
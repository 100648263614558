<template>
  <v-window-item value="conclusion">
    <v-card-text>
      <p v-html="cardText"></p>
      <div v-html="cardLinks"></div>
    </v-card-text>  
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn  
        class="px-8" 
        color="accent" 
        rounded 
        @click="$router.push('/')"
      >{{ $t('upsit.returnhome') }}</v-btn>
    </v-card-actions>
  </v-window-item>
</template>

<script>

  export default {
    name: "upsitConclusion",
    props: {
      text: {
        type: String,
        required: false,
        default: "Thank you for completing the UPSIT test!  You may discard your completed UPSIT booklets. If you have any questions, please contact us."
      }
    },
    computed: {
      cardText(){
        if(this.$store.state.route.query.task === 'upsit2'){
          return this.$t('upsit.conclusion2')
        }
        return this.$t('upsit.conclusion')
      },
      cardLinks(){
        if(this.$store.state.route.query.task === 'upsit2'){
          return this.$t('upsit.conclusion2links')
        }
        return this.$t('upsit.conclusionlinks')
      }
    }
  }

</script>
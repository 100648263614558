<template>
  <p-data-input-card
    id="phoneNumbersCard"
    :title = "title"
    :subtitle = "subtitle"
    :icon = "icon"
    :state = "state"
    record_type = "phone"
    :updateDialog.sync = "updateDialog"
    :deleteDialog.sync = "deleteDialog"
    :record.sync="record"
    :records="records"
    inputComponent = "pPhoneInput"
    :showTitle="showTitle"
    :flat="!showTitle"
    :blank="$store.getters['participant/blanks'][record_type]"
    @loaded="$emit('loaded', $event)"
    @insert="$emit('insert', $event)"
    @update="$emit('update', $event)"
    @remove="$emit('remove', $event)"
  >
      <v-simple-table>
        <thead>
          <tr>
            <th :style = "{padding: mobile ? '8px' : null}" style = "width: 1%; text-align: center;">{{ $t('participant.phonenumbers.primary') }}</th>
            <th :style = "{padding: mobile ? '8px' : null}">{{ $t('gen.phone') }}</th>
            <th :style = "{padding: mobile ? '8px' : null}" style = "text-align: center">{{ $t('participant.emailform.type') }}</th>
            <th v-if="!mobile" style = "text-align: center">{{ $t('participant.phoneform.allowtext') }}</th>
            <th v-if="!mobile" style = "text-align: center">{{ $t('participant.phoneform.allowvoice') }}</th>
            <th v-if="!mobile" style = "text-align: center;"> </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(phone, index) in records">
          <tr :key="index" v-if="phone !== null">
            <td style = "text-align: center;" :class="{mobile: mobile}">
              <v-icon
                v-if="phone.is_primary"
                color = "success"
              >mdi-checkbox-marked-circle</v-icon>            
              <v-tooltip bottom v-else-if="!phone.is_primary">
                <template v-slot:activator="{on}">
                  <v-btn 
                    :disabled="phone.is_primary" 
                    icon
                    color='grey lighten-2' 
                    v-on="on" 
                    @click="setPrimary(phone)"
                  >
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-btn>    
                </template>
                <span>{{ $t('participant.phonenumbers.markprimary') }}</span>
              </v-tooltip>
            </td>
            <td :class="{mobile: mobile}">
              {{phone.number}}
            </td>
            <td style = "text-align: center" :class="{mobile: mobile}">
              {{phone.type}}
            </td>
            <td style = "text-align: center" v-if="!mobile">
              <v-icon
                v-if="phone.allow_text"
                color = "success"
              >mdi-checkbox-marked-circle</v-icon>  
              <v-icon
                v-else
                color = "error"
              >mdi-close-circle</v-icon>                
            </td>
            <td style = "text-align: center" v-if="!mobile">
              <v-icon
                v-if="phone.allow_vm"
                color = "success"
              >mdi-checkbox-marked-circle</v-icon>  
              <v-icon
                v-else
                color = "error"
              >mdi-close-circle</v-icon>    
            </td>
            <td style="text-align: right" v-if="!mobile">
              <p-action-button
                tooltipLoc="left"
                dark
                :tooltip="'Edit ' + phone.number"
                :icon="$vuetify.breakpoint.mdAndUp ? 'mdi-pencil' : null"
                label="Edit"
                color="accent"
                @click="editRecord(phone)"
              ></p-action-button>
              <p-action-button
                tooltipLoc="right"
                :dark="!phone.is_primary"
                :disabled="phone.is_primary"
                :tooltip="'Delete ' + phone.number"
                :icon="$vuetify.breakpoint.mdAndUp ? 'mdi-delete' : null"
                label="Delete"
                color="error"
                btnClass="ml-3"
                @click="deleteRecord(phone)"
              ></p-action-button>
            </td>
          </tr>
          <tr :key="`b-${index}`" v-if="mobile">
            <td colspan="3" class = "mobile">
              <div style="max-width: 300px; margin: 0 auto;">

                <v-chip
                  :color="phone.allow_text ? 'success' : 'error'"
                  style="width: 48%;"
                >
                  <v-icon class="mr-2" v-text="phone.allow_text ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle'">
                  </v-icon>
                  {{ $t('participant.phonenumbers.text') }}
                </v-chip>

                <v-chip
                  :color="phone.allow_vm ? 'success' : 'error'"
                  class="ml-1"
                  style="width: 48%; text-align: center;"
                >
                  <v-icon class="mr-2" v-text="phone.allow_vm ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle'">
                  </v-icon>
                  {{ $t('participant.phonenumbers.voice') }}
                </v-chip>

              </div>
            </td>
          </tr>
          <tr :key="`c-${index}`" v-if="mobile">
            <td colspan="3">
              <div style="max-width: 350px; margin: 0 auto;">
                <v-btn 
                  color="accent" 
                  width="50%" 
                  @click="editRecord(phone)"
                >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  {{ $t('gen.edit') }}
                </v-btn>
                <v-btn 
                  color="error" 
                  width="50%" 
                  :dark="!phone.is_primary"
                  :disabled="phone.is_primary"
                  @click="deleteRecord(phone)"
                >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  {{ $t('gen.delete') }}
                </v-btn>  
              </div>
            </td>
          </tr>
          </template>
        </tbody>
      </v-simple-table>
  </p-data-input-card>
</template>

<script>

  import {contactPanelMixin} from "../mixins"

  export default {
    name: "phoneNumbersPanel",
    mixins: [contactPanelMixin],
    data() {
      return {
        record_type: "phone",
        icon: "fa-phone",        
      }
    },
    computed: {
      title() {
        return this.$t('participant.phonenumbers.title')
      },
      subtitle(){
        return this.$t('participant.phonenumbers.subtitle')
      },
      recordDescription() {
        return this.$t('gen.phone')
      }
    }
  }

</script>

<style scoped>

  tr:hover{
    background-color: transparent !important;
  }

  td.mobile {
    border-bottom: none !important;
    padding: 8px !important;
  }

</style>
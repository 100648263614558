import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(!_vm.$vuetify.breakpoint.mobile)?_c(VTooltip,{attrs:{"color":"accent","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mb-10",attrs:{"bottom":"","color":"primary","dark":"","fab":"","fixed":"","right":"","aria-label":_vm.asklabel,"id":"questionBoxBtn"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)]}}],null,false,1986939126)},[_c('span',[_vm._v(_vm._s(_vm.$t('questionbox.ask')))])]):_vm._e(),_c(VDialog,{attrs:{"width":"800px","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"800","id":"questionBox"}},[_c('p-card-title',{attrs:{"title":_vm.qntitlelabel,"subtitle":_vm.qnsubtitlelabel,"icon":_vm.icon}}),_c(VCardText,{staticClass:"mt-5"},[_c(VBtn,{staticClass:"mb-5",attrs:{"block":"","color":"accent","x-large":"","href":("tel:" + _vm.phone)}},[_c(VIcon,{staticClass:"mr-3"},[_vm._v("fa-phone")]),_vm._v(" "+_vm._s(_vm.phone))],1),_c(VBtn,{staticClass:"mb-5",attrs:{"block":"","color":"accent","x-large":"","href":("mailto:" + _vm.email)}},[_c(VIcon,{staticClass:"mr-3"},[_vm._v("fa-at")]),_vm._v(" "+_vm._s(_vm.email))],1)],1),_c(VCardActions,{attrs:{"absolute":""}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","id":"questionBoxCancelBtn"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('gen.okay')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
// Vuex module
import store from './store.js'

// Main questionBox Component
import questionBox from "./questionBox.vue"

const questionBoxPlugin = {
  install(Vue, options) {

    let components = []

    Vue.component(questionBox.name, questionBox)
    components.forEach(component => Vue.component(component.name, component))
    
    options.registerModule("questionBox", store)
    
  }
}

export default questionBoxPlugin
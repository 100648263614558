<template>
  <v-tooltip 
    :left="tooltipLoc=='left'" 
    :right="tooltipLoc=='right'" 
    :top="tooltipLoc=='top'" 
    :bottom="tooltipLoc=='bottom'"
    :disabled="!tooltip"
  >
    <template v-slot:activator="{ on, attrs}">
      <v-btn 
        class="px-8"         
        :class="btnClass"
        :dark="dark"
        :color = "color" 
        v-on="on"
        :id="id"
        :disabled="disabled"
        v-bind="attrs"
        @click="$emit('click', $event)" 
      ><v-icon v-if="icon">{{ icon }}</v-icon>{{ label }}</v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
/**
 * General Button Component
 * @displayName Action Button
 */

export default {
  name: "pActionButton",
  props: {
    
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: "secondary"
    },
    dark: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    tooltipLoc: {
      type: String,
      validator: (value) => {return ['left', 'right', 'top', 'bottom'].indexOf(value) !== -1},
      default: "bottom",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btnClass: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    }
  },
  computed: {
    Class() {
      return this.class
    }
  }
}
</script>
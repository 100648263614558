import auth_api from "@/services/axios-auth.js";
import decode from "jwt-decode";
import {generateRandomString, pkceChallengeFromVerifier} from "@/services/auth-helpers"
import axios from 'axios';
import Vue from 'vue'

const state = {
  auth_domain: process.env.VUE_APP_AUTH_DOMAIN,
  auth_href: null,
  client_id: process.env.VUE_APP_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_REDIRECT_URL,
  id_token: null,
  refresh_token: null,
  access_token: null,
  state: null,
  scope: "openid",
  code_verifier: null,
  code_challenge: null,
  code_challenge_method: "S256",
  access_expires: null,
  authorization_code: null,
  refresh_fn: null,
  lastAttempt: Date.now(),
  loggedIn: false,
  loggingIn: false,
  logoutReason: null,
  expSafetyWindow: 120,
  refreshing: false,
  tryCount: 0
}

let mutations = {
  SET_STATE(state, payload) {
    state.state = payload
  },
  SET_CODE_VERIFIER(state, payload) {
    state.code_verifier = payload
  },
  SET_CODE_CHALLENGE(state, payload) {
    state.code_challenge = payload
  },
  SET_AUTH_HREF(state, payload) {
    state.auth_href = payload
  },
  SET_ID_TOKEN(state, payload) {
    state.id_token = payload
  },
  SET_ACCESS_TOKEN(state, payload) {
    state.access_token = payload
  },
  SET_REFRESH_TOKEN(state, payload) {
    state.refresh_token = payload
  },
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_AUTHORIZATION_CODE(state, payload) {
    state.authorization_code = payload
  },
  LOGIN_SUCCESS(state, payload) {
    state.status = 'logged_in';
    state.token = payload.token;
    state.user = payload.user;  
    state.error = null;
  },
  LOGIN_ERROR(state, payload) {
    state.status = 'error';
    state.error = payload;
  },
  LOGOUT(state, payload) {
    state.id_token = null
    state.access_token = null
    state.refresh_token = null
    state.access_expires = 0
    state.loggedIn = false
    state.logoutReason = payload ? payload.reason || null : null
    clearTimeout(state.refresh_fn)
  },
  SET_ACCESS_EXPIRES(state, payload) {
    state.access_expires = Date.now() + (payload * 1000)
  },
  SET_LOGGEDIN(state, payload) {
    state.loggedIn = payload
  },
  SET_LOGGINGIN(state, payload) {
    state.loggingIn = payload
  },
  SET_LOGOUT_REASON(state, payload) {
    state.logoutReason = payload || null
  },
  SET_TRY_COUNT(state, payload) {
    state.tryCount = payload
  },
  INCREMENT_TRY_COUNT(state) {
    state.tryCount++
  }
}

let actions = {

  async LOGIN({commit, dispatch, getters}) {
    try {
      commit("SET_AUTH_HREF", null)
      commit("SET_LOGGINGIN", true)
      commit("SET_TRY_COUNT", 0)
      let auth_state = generateRandomString()
      let code_verifier = generateRandomString()
      let code_challenge = await pkceChallengeFromVerifier(code_verifier)
      let url = getters.auth_url("/authorize")
      let auth_href = new URL(url);
      auth_href.searchParams.append("response_type", 'code')
      auth_href.searchParams.append("client_id", getters['client_id'])
      auth_href.searchParams.append("redirect_uri", getters['redirect_uri'])
      auth_href.searchParams.append("state", auth_state)
      auth_href.searchParams.append("scope", getters['scope'])
      auth_href.searchParams.append("code_challenge", code_challenge)
      auth_href.searchParams.append("code_challenge_method", getters["code_challenge_method"])
      await dispatch("SAVE_AUTH_CODES", {
        auth_state: auth_state,
        code_verifier: code_verifier,
        code_challenge: code_challenge,
        auth_href: auth_href
      })

      setTimeout(() => {
        window.location = auth_href.href
      }, 500)

      return true
    } catch(err) {
      dispatch("snackbar/display", {message: this.$t('router.error'), color: "error"}, {root: true})
      return false
    }

  },
  async SAVE_AUTH_CODES({commit}, payload) {
    commit("SET_STATE", payload.auth_state);
    commit("SET_CODE_VERIFIER", payload.code_verifier)
    commit("SET_CODE_CHALLENGE", payload.code_challenge)    
    commit("SET_AUTH_HREF", payload.auth_href.href) 
    commit("SET_LOGOUT_REASON", null)
    return
  },
  async AUTH_GRANT(context, payload) {
    try {
      context.commit("INCREMENT_TRY_COUNT")
      context.commit("SET_AUTHORIZATION_CODE", payload.code)
      context.commit("SET_LOGGINGIN", false)
      if(payload.state !== context.getters.state) {
        await context.dispatch("FULL_LOGOUT")
        throw new Error("Invalid Login Request")
      } else {
        let req_payload = {
          grant_type: "authorization_code",
          code: payload.code,
          client_id: context.getters.client_id,
          redirect_uri: context.getters.redirect_uri,
          code_verifier: context.getters.code_verifier
        }
        try {
          let resp = await auth_api.post(["oauth", "token"], req_payload, "form")
          Vue.$log.debug("AUTH_GRANT resp", resp)
          if(resp.status === 200) {
            Vue.$log.debug('resp was a 200 for auth0 token')
            let data = resp.data
            context.commit("SET_ID_TOKEN", data.id_token)
            context.commit("SET_LOGGEDIN", true)
            await context.dispatch("ACCESS_TOKEN", data.id_token)
          } else {
            Vue.$log.error("Error with OAuth Service")
            throw new Error("Error with OAuth Service")
          }
        } catch(err) {
          Vue.$log.error(err);
          context.commit("LOGOUT")
          throw new Error("Login Error")
        }
      }
 //     return true
    } catch(err) {
      Vue.$log.error("AUTH_GRANT error", err)
      context.dispatch("snackbar/display", {
        message: this.$t('router.error'), 
        color: "error"
      }, {root: true})
    }
  },
  async ACCESS_TOKEN(context, payload){
    Vue.$log.debug('dispatching access token call with ', payload)
    try {
      let access_header = 'Bearer ' + payload
      let auth_url = process.env.VUE_APP_API_URL + '/validate'
      let resp = await axios.post(auth_url, {}, {
        headers: {
          "Authorization": access_header
        }
      })
      if(resp.status === 200) {
        Vue.$log.debug("got access token response: ", resp)
        context.commit("SET_ACCESS_TOKEN", resp.data.api_token)
      } else {
        Vue.$log.error("Error with validate Service")
        throw new Error("Error with validate Service")
      }
    } catch(err) {
        Vue.$log.error("ACCESS_TOKEN error", err)
        context.dispatch("snackbar/display", {
          message: this.$t('router.error'), 
          color: "error"
        }, {root: true})
    }
  },
  async REFRESH_TOKEN(context) {
    try {
      let auth_url = process.env.VUE_APP_API_URL + '/token/refresh'
      let resp = await axios.post(auth_url, {}, {
        headers: {
          "Authorization": context.getters.authorization_header
        }
      })
      let data = resp.data
      Vue.$log.debug("refresh token response", resp)
      context.commit("SET_ACCESS_TOKEN", data.api_token);
      context.commit("SET_ACCESS_EXPIRES", data.exp);
      context.state.refresh_fn = setTimeout(() => {
        context.dispatch("REFRESH_TOKEN")
      }, (data.expires_in - 30) * 1000) 
      return true     
    } catch(err) {
      Vue.$log.error(err)
      context.commit("LOGOUT")
      throw new Error("Could not refresh token")
    }
  },
  async CHECK_EXP(context) {
    Vue.$log.debug("Checking token expiration")
    let exp = context.getters.access_payload.exp
    let now = Date.now()/1000
    if(now > (exp - context.getters.expSafetyWindow)) {
      Vue.$log.debug("##################### ATTEMPTING TO REFRESH TOKEN ##########################")
      return await context.dispatch("REFRESH_TOKEN") 
    } else {
      return true
    }
  },
  async FULL_LOGOUT(context, payload) {
    context.commit("LOGOUT", payload)
    context.commit("participant/LOGOUT", null, {root: true})
    context.commit("consent/LOGOUT", null, {root: true})
    context.commit("questionnaire/LOGOUT", null, {root: true})
  },
  async AUTH_HEADER(context) {
    let exp = context.getters.access_payload.exp
    let now = Date.now()/1000
    if(now > (exp - context.getters.expSafetyWindow)) {
      if(context.state.refreshing == false) {
        context.state.refreshing = true
        Vue.$log.debug("##################### ATTEMPTING TO REFRESH TOKEN ##########################")
        await context.dispatch("REFRESH_TOKEN")
        context.state.refreshing = false
      } else {
        Vue.$log.debug("###### REFRESH ALREADY CALLED ######")
      }
    }    
    let access_token = await context.getters.accessToken
    access_token
    return context.getters.authorization_header

  },
  async LOGOUT({dispatch, getters}, payload = {reason: "user_initiated"}) {
    await dispatch("FULL_LOGOUT", payload)
      let url = getters.auth_url("/v2/logout")
      let auth_href = new URL(url);
      auth_href.searchParams.append("client_id", getters['client_id'])
      auth_href.searchParams.append("returnTo", process.env.VUE_APP_REDIRECT_URL)
      window.location.replace(auth_href)
  }, 
}

let getters = {
  user: state => state.user,
  client_id: state => state.client_id,
  redirect_uri: state => state.redirect_uri,
  state: state => state.state,
  scope: state => state.scope,
  code_verifier: state => state.code_verifier,
  code_challenge: state => state.code_challenge,
  code_challenge_method:  state => state.code_challenge_method,
  isLoggedIn: state => {
    return !!state.loggedIn // because I don't want to chase bugs... 
  },
  tokenExpired: state => {
    state.access_expires < Date.now()
  },
  auth_domain: state => state.auth_domain,
  auth_url: state => {
    return function(path) { 
      let url = `${state.auth_domain}${path}`
      return url
    }
  },
  auth_href: state => state.auth_href,
  id_payload: state => {
    return decode(state.id_token)
  },
  access_payload: state => {
    return decode(state.access_token)
  },
  user_id: state => {
    let t = decode(state.id_token)
    return t['https://www.ppmi-info.org/ppmi_id']
  },
  authorization_header: state => {return `Bearer ${state.access_token}`},
  authorization_code: state => state.authorization_code,
  access_expires: state => state.access_expires,
  loggedIn: (state) => {
    return state.loggedIn
  },
  loggingIn: state => state.loggingIn,
  logoutReason: state => state.logoutReason,
  expSafetyWindow: state => state.expSafetyWindow,
  accessToken: (state, getters) => {
    return new Promise(function (resolve) {
      (function waitForToken(){
        try {
          let exp = getters.access_payload.exp
          let now = Date.now()/1000
          if (now < (exp - getters.expSafetyWindow)) {
            return resolve(state.access_token)
          }
        } catch(err) {
          err
        }
        setTimeout(waitForToken, 50)
      })();
    });
  },
  tryCount: (state) => {
    return state.tryCount
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

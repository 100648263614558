<template>
  <v-window-item value = "conclusion">
    <v-card-text>
      <p v-html="text"></p>
    </v-card-text>
  </v-window-item>
</template>

<script>

  export default {
    name: "questionnaireConclusion",
    computed: {
      text() {
        if(this.$store.getters["questionnaire/success"]) {
          return this.$store.getters["questionnaire/conclusion"]
        } else {
          return this.$t('qnn.conclusion.problem')
        }
      },
    }
  }

</script>
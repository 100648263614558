import _ from 'lodash'

export const formMixin = {
  props: {
    dense: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      required: true
    },
    valid: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      record: {}
    }
  },
  mounted() {
    this.$refs.form.resetValidation()
  },
  updated() {
    this.record = this.value
  },
  computed: {
    validForm: {
      get() {
        return this.valid
      },
      set(v) {
        this.$emit("update:valid", v)
      }
    }
  },
  methods: {
    async validate() {
      return await this.$refs.form.validate()
    },
    clear() {
      this.$refs.form.resetValidation();
    },
    reset() {
      this.$refs.form.reset();
    }
  },
  watch: {
    value: {
      handler(v) {
        if(!this.value.guid) {
          this.clear()
        }
        this.record = v
      },
      deep: true
    },
    record: {
      handler: _.debounce(function (v) {
        this.$emit("input", v)
      }, 2000),
      deep: true
    }
  }
}
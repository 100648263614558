<template>
  <p-info-card
    :title="title"
    :mobileTitle="mobileTitle"
    titleIcon="mdi-human-greeting"
    id="guestCard"
  >
    <slot>
      <v-row>
        <v-col cols=4 style = "border-right: solid #DDD 2px;" v-if="$vuetify.breakpoint.mdAndUp">
          <img class = "pl-4" alt="vertical logo" :src = "require('@/assets/logo_horiz_md.png')" style="float: left; max-width: 100%; margin-top: 30px; margin-right: 30px; margin-bottom: 30px" >
        </v-col>   
        <v-col cols=12 md=8 class="pl-5">
          <p :style="{'text-align': $vuetify.breakpoint.mobile ? 'left' : 'justify'}" v-html="this.$t('home.guestCard.welcome1')"> 
          </p>
          <p :style="{'text-align': $vuetify.breakpoint.mobile ? 'left' : 'justify'}">
            {{ $t('home.guestCard.welcome2') }}
          </p>
          <v-btn color="accent" min-width="40%" @click="login" large id="loginButton" :style="{left: '50%', transform:'translateX(-50%)'}">
              <v-icon left>mdi-login</v-icon>
              {{ $t('home.guestCard.getstarted') }}
          </v-btn>     
          <h3 class="pt-6">
          {{ $t('home.guestCard.questions') }}
          </h3>
          <p :style="{'text-align': $vuetify.breakpoint.mobile ? 'left' : 'justify'}" v-html="$t('home.guestCard.faqs')">
          </p>
          <p :style="{'text-align': $vuetify.breakpoint.mobile ? 'left' : 'justify'}" v-html="this.$t('home.guestCard.welcome3', {phone: this.phone, email: this.email})">        
          </p>          
        </v-col>     
      </v-row>
      <!-- <v-row>
        <v-col cols=12 class = "mt-10">
          <p class = "ml-10" style = "font-size: 1.1em; font-weight: bold;">If you are a PPMI Remote Participant you may click the button below to log in.</p>      
        </v-col>
      </v-row> -->
    </slot>
  </p-info-card>

</template>

<script>

export default {
  name: "guestCard",
  data() {
    return {}
  },
  computed: {
    phone() { return process.env.VUE_APP_PHONE_NUMBER },
    email() { return process.env.VUE_APP_EMAIL_ADDRESS },
    title() { return this.$t('home.guestCard.title') },
    mobileTitle() { return this.$t('home.guestCard.mobiletitle') }
  },
  methods: {
    login() {
      this.$store.dispatch("auth/LOGIN")
    },
  }
}

</script>
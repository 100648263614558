<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width='1000px' scrollable>
      <p-info-card
        :title="titlelabel"
        titleIcon="fa-exclamation"
        id="disclaimerCard"
      >
        <p class="mt-5">{{ $t('disclaimer.pt1') }}</p>

        <p>{{ $t('disclaimer.pt2') }}</p>

        <p>{{ $t('disclaimer.pt3') }}</p>

        <p v-html="$t('disclaimer.pt4')"></p>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn @click="show=false" id="closeDisclaimerBtn">{{ $t('gen.close') }}</v-btn>
        </template>
      </p-info-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    name: "pDisclaimer",
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      show: {
        get() { return this.value },
        set(v) { this.$emit("input", v)}
      },
      titlelabel() {
        return this.$t('gen.disclaimer')
      }
    }
  }

</script>
const contactPanelMixin = {
  props: {
    records: {
      type: Array,
      default: () => {
        return []
      }
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateDialog: {
        title: "",
        subtitle: "",
        icon: "",
        show: false,
      },
      deleteDialog: {
        show: false,
      },
      record: {}      
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    hasRecords() {
      return this.records[0] !== null
    },
    state() {
      if(this.records.length === 0 || !this.hasRecords) {
        return 'no_records'
      } else {
        return 'ready'
      }
    }
  },
  methods: {
    setPrimary(record) {
      record.is_primary = true
      this.$emit("update", {type: this.record_type, data: record})
    },
    deleteRecord(record) {
      this.record = record
      this.deleteDialog.show = true
    },
    editRecord(record) {
      this.record = record
      this.updateDialog.title = `Edit ${this.recordDescription}`
      this.updateDialog.icon = "mdi-home-edit"
      this.updateDialog.show = true
      this.updateDialog.type = 'update'
    }
  },  
}

export {contactPanelMixin}
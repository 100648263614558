import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"persistent":"","max-width":"1000px","eager":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('consent.declineDialog.declinetitle')))]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('consent.declineDialog.chooseoption'))+" "),_c(VSelect,{attrs:{"items":_vm.decline_reasons},model:{value:(_vm.declined_category),callback:function ($$v) {_vm.declined_category=$$v},expression:"declined_category"}}),_vm._v(" "+_vm._s(_vm.$t('consent.declineDialog.addlcomments'))+" "),_c(VTextarea,{model:{value:(_vm.additional_comments),callback:function ($$v) {_vm.additional_comments=$$v},expression:"additional_comments"}}),_vm._v(" "+_vm._s(_vm.$t('consent.declineDialog.talk'))+" "),_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.staff_contact),callback:function ($$v) {_vm.staff_contact=$$v},expression:"staff_contact"}},[_c(VRadio,{attrs:{"label":"Yes","value":true}}),_c(VRadio,{attrs:{"label":"No","value":false}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(this.$t('gen.cancel')))]),_c(VBtn,{attrs:{"color":"primary darken-1","disabled":_vm.disabled,"text":""},on:{"click":_vm.save}},[_vm._v(_vm._s(this.$t('gen.submit')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
// Vuex module
import tasksStore from './tasksStore.js'

// Main participants Component
import home from "./Home.vue"

// Support Components
let components = ["taskGroup", "guestCard", "taskPanels", 'shipmentInfoCard', 'upsTracking']


const homePlugin = {
  install(Vue, options) {
    //load the main component 
    Vue.component(home.name, home)
    components.forEach(comp => {
      let component = require(`./Components/${comp}.vue`).default
      Vue.component(component.name, component)
    })
    
    //register the vuex module(s)
    options.registerModule("tasks", tasksStore)
  }
}

export default homePlugin

// Automatic installation if Vue has been added to the global scope.
/*
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(MyPlugin)
}
*/
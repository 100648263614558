<template>
  <v-container>
    <v-row v-if="loggedIn" justify="center">
      <v-col :cols="tasksCols">
        <p-task-panels></p-task-panels>
      </v-col>
      <!-- <v-col cols="12" lg=5 v-if="currentTrackingNo">
        <p-shipment-info-card
          :tracking_no="currentTrackingNo"
          :task="shipmentTask"
        ></p-shipment-info-card>
      </v-col> -->
    </v-row>
    <v-row  justify="center" v-else>
      <v-col cols="12" lg=8>
        <guest-card></guest-card>
      </v-col>
    </v-row>      
  </v-container>
</template>

<script>
  export default {
    name: "participantHome",
    data() {
      return {

      }
    },
    computed: {
      loggedIn() {
        this.$log.debug("loggedIn", this.$store.getters["auth/isLoggedIn"])
        return this.$store.getters["auth/isLoggedIn"];
      },
      currentTrackingNo() {
        return this.$store.getters['tasks/currentTrackingNo']
      },
      activeTask() {
        return this.$store.getters['tasks/activeTask']
      },
      shipmentTask() {
        let task
        if(this.activeTask.task_key == "upsit") {
          task = this.$store.getters['tasks/taskData'].UPSIT_receive_upsit
        } else {
          task = this.activeTask
        }
        return task
      },
      tasksCols() {
        let cols = 12 
        switch(this.$vuetify.breakpoint.name) {
          case 'xl': 
            cols = 6
            break
          case 'lg':
            cols = 7
            break
          default:
            cols = 12
        }
        return cols
      }
    },
    watch: {

    },
  }
</script>
import Axios from 'axios'
import Vue from 'vue'
import store from '@/store';

const instance = Axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

function makeURL(url) {
  if(Array.isArray(url)) {
    url = url.join("/");
  }
  return url;
}
 
let axios = {
  instance: instance,
  get: function(url, payload, auth = true) {
    url = makeURL(url);
    Vue.$log.debug("GET " + url + " payload:", payload)
    if(auth) {
      let auth_header = store.getters['auth/authorization_header']
      return instance.get(url, {params: payload, headers: {"Authorization": auth_header}})   
    } else {
      return instance.get(url, {params: payload});    
    }
  },
  post: function(url, payload, auth = true) {
    url = makeURL(url);
    Vue.$log.debug("POST " + url + " payload:", payload)
    if(auth) {
      let auth_header = store.getters['auth/authorization_header']
      return instance.post(url, payload, {headers: {"Authorization": auth_header}})
    } else {
      return instance.post(url, payload);
    }
  },
  put: function(url, payload, auth = true) {
    url = makeURL(url);
    Vue.$log.debug("PUT " + url + " payload:", payload)
    if(auth) {
      let auth_header = store.getters['auth/authorization_header']
      return instance.put(url, payload, {headers: {"Authorization": auth_header}});
    } else {
      return instance.put(url, payload);
    }
  },
  delete: function(url, payload, auth = true) {
    url = makeURL(url);
    Vue.$log.debug("DELETE " + url + " payload:", payload)
    if(auth) {
      let auth_header = store.getters['auth/authorization_header']
      return instance.delete(url, {headers: {"Authorization": auth_header}});
    } else {
      return instance.delete(url, payload);
    }
  }
}

const axiosPlugin = {
  install(Vue) {
    Vue.prototype.$axios = axios
    Vue.$axios = axios
  }
}

export default axiosPlugin
export {axios, axiosPlugin}
<template>
  <v-input
    :rules="validationRules"
    v-model="answer"
    ref="question"
  >
    <v-radio-group 
      v-model="conditional_answer" 
      class = "mr-10" 
      :disabled="disabled"
    >
      <v-radio :label="yeslabel" value = "Yes"></v-radio>
      <v-radio :label="nolabel" value = "No"></v-radio>
    </v-radio-group>
    <v-text-field
      v-show="conditional_answer == question.options.followup_answer"
      v-model="text_answer"
      :label="question.options.text_label"
    ></v-text-field>
    <v-text-field 
      v-model="answer" 
      :rules="validationRules"
      :disabled="disabled"
      style="display: none;"
      :label="answerlabel" 
    ></v-text-field>
  </v-input>
</template>

<script>

import { questionMixin } from "../mixins.js"

export default {
  name: "qConditionalText",
  mixins: [questionMixin],
  data() {
    return {
      default_rules: ['required'],
      conditional_answer: null,
      text_answer: null
    }
  },
  computed: {
    yeslabel() {
      return this.$t('gen.yes')
    },
    nolabel() {
      return this.$t('gen.no')
    },
    answerlabel() {
      return this.$t('qnn.compact.answer')
    }
  },
  methods: {
    preprocessAnswer(a) {
      if(a !== undefined && a !== null) {
        if(this.question.choices.indexOf(a) > -1) {
          this.conditional_answer = a
        } else {
          this.conditional_answer = this.question.options.followup_answer
          this.text_answer = a
        }
      }
      return a
    }
  },
  watch: {
    conditional_answer(v) {
      if(v == this.question.options.followup_answer) {
        this.answer = this.text_answer
      } else {
        this.answer = v
        this.text_answer = null
      }
    },
    text_answer(v) {
      if(this.text_answer !== null) {
        this.answer = v
      }
    }
  }
}
</script>
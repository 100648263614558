<template>
  <v-card>
    <p-card-title
      :title="title"
      :subtitle="subtitle"
      color="primary"
      icon="fa-truck"
      :loading="loading"
      :fontSizeAdjust="false"        
    >
      <template v-slot:action>
        <v-switch
          v-model="fullTracking"
          :label="trackingLabel"
        ></v-switch>  
      </template>
    </p-card-title>  
    <v-card-text class="pa-0">
      <p-ups-tracking 
        :fullTracking="fullTracking"
        :tracking_no="tracking_no"
        @statusChange="setStatus"
      ></p-ups-tracking>
    </v-card-text>
  </v-card>
</template>

<script>

  export default {
    name: "pShipmentInfoCard",
    props: {
      task: {
        type: Object,
        default: () => { return {} }
      },
      tracking_no: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        fullTracking: false,
        loading: false,
        trackingInfo: {},
        trackingStatus: "",
      }
    },
    computed: {
      title() {
        if(this.task.task_key === "receive_upsit") {
          return this.$t('home.shipmentInfo.upsitshipment')
        } else if(this.task.task_key === "return_upsit") {
          return "UPSIT Kit Return"
        } else {
          return null
        }
      },
      subtitle() {
        if(this.task.task_key === "receive_upsit") {
          return this.$t('home.shipmentInfo.shipped')
        } else if(this.task.task_key === "return_upsit") {
          return "Thank you for returning your UPSIT kit"
        } else {
          return null
        }
      },
      completed() {
        return this.trackingStatus == "Delivered"
      },
      subtitleStatus() {
        return this.trackingStatus ? `${this.$t('home.shipmentInfo.currentstatus')}: ${this.trackingStatus}` : this.subtitle
      },
      trackingLabel() { return this.$t('home.shipmentInfo.trackinglabel') }
    },
    methods: {
      setStatus(v) {
        this.trackingStatus = v
      }
    }
  }

</script>

<style>

#todo .v-expansion-panel-content__wrap, #todo .v-expansion-panel-header {
  padding: 0;
  margin: 0;
}

</style>
import data_api from "@/services/axios-data.js";
import Vue from 'vue'

const state = {
  status: [],
  race: [],
  gender: [],
  contact_result: [],
  ethnicity: [],
  preferred_contact: [],
  contact_reason: [],
  state: [],
  province: [],
  country: [],
  email_type: [],
  address_country: [],
  emergency_relationship: [],
  phone_type: [],
  ups_status: []
}

let mutations = {}
let getters = {}

Object.keys(state).forEach(key => {
  
  mutations["SET_" + key.toUpperCase()] = (state, payload) => {
    state[key] = payload
  }
  getters[key] = (state) => {
    return state[key]
  }

})

getters.loaded = (state) => {
  return state.race.length > 0 && state.gender.length > 0
}

let actions = {
  async load({commit}) {
      Vue.$log.debug("calling options")
      let resp = await data_api.get('options')
      let data = resp.data
      Object.keys(data).forEach(key => {
        commit("SET_" + key.toUpperCase(), data[key])
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

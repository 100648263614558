import axios from 'axios';
import store from '@/store';
import Vue from 'vue'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

function makeURL(url) {
  if(Array.isArray(url)) {
    url = url.join("/");
  }
  return url;
}
 
export default {
  instance: instance,
  get: async function(url, payload={}) {
    url = makeURL(url);
    Vue.$log.info("GET " + url + " payload:", payload)
    payload = {...payload, locale: store.getters["userSettings/current_locale"]}
    let auth_header = await store.dispatch("auth/AUTH_HEADER");
    return instance.get(url, {params: payload, headers: {"Authorization": auth_header}})    
  },
  post: async function(url, payload) {
    url = makeURL(url);
    Vue.$log.info("POST " + url + " payload:", payload)
    let auth_header = await store.dispatch("auth/AUTH_HEADER");
    return instance.post(url, payload, {headers: {"Authorization": auth_header}})
  },
  put: async function(url, payload) {
    url = makeURL(url);
    Vue.$log.info("PUT " + url + " payload:", payload)
    let auth_header = await store.dispatch("auth/AUTH_HEADER");
    return instance.put(url, payload, {headers: {"Authorization": auth_header}});
  },
  delete: async function(url, payload) {
    console.log("url start" + url)
    url = makeURL(url);
    console.log("url_end", url)
    Vue.$log.info("DELETE " + url + " payload:", payload)
    let auth_header = await store.dispatch("auth/AUTH_HEADER");
    return instance.delete(url, {headers: {"Authorization": auth_header}});
  }
}
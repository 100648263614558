import Vue from 'vue'
import VueLogger from 'vuejs-logger'
 
const options = {
    isEnabled: true,
    logLevel : process.env.VUE_APP_CONSOLE_LOG_LEVEL || 'error',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : false,
    separator: '|',
    showConsoleColors: true
};
 
Vue.use(VueLogger, options);
<template>
  <v-container>
    <v-row dense v-if="tracking_info">
      <v-col cols=12>
        <v-list two-line>
          <v-list-item>
            <!-- <v-list-item-avatar
              size="50"
              color='primary'
            >
              <v-icon dark>fa-tasks</v-icon>
            </v-list-item-avatar> -->
            <v-list-item-content>
              <v-list-item-subtitle style="font-size: 0.8em">{{ $t('home.upsTracking.status') }}</v-list-item-subtitle>
              <v-list-item-title style="font-size: 2em">{{tracking_info.currentActivity}}</v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action><a href="#" target="_blank">{{tracking_info.trackingNo}}</a></v-list-item-action> -->
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <h3 class="my-2">{{ $t('home.upsTracking.details') }}</h3>
        <v-timeline
          dense
        >
          <v-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            class="mb-4"
            :icon='shortActivities.indexOf(activity.description) > -1 ? icons[activity.description] : ""'
            :color="shortActivities.indexOf(activity.description) == -1 ? 'grey' : colors[activity.description]"
            icon-color="white"
            :small="shortActivities.indexOf(activity.description) == -1"
            :large="shortActivities.indexOf(activity.description) > -1"
          >
            <v-row justify="space-between">
              <v-col cols=7 class="pa-0 ma-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{activity.description}}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{activityPlace(activity)}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="text-right" cols=5>
                {{activity.date}} {{activity.time}}
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>          
      </v-col>
     
    </v-row>
    <v-row v-else>
      <v-col>
        <p>{{ $t('home.upsTracking.unavailable') }}</p>
      </v-col>
    </v-row>
    <v-card-actions>
            <v-spacer></v-spacer>
            
            <p-action-button v-if="status != 'Delivered'" 
              color = "success" 
              btnClass="ml-3"
              dark
              icon = "mdi-check"
              @click="promptedDelivered"
              :label = deliveredlabel
            ></p-action-button>

            <v-spacer></v-spacer>
    </v-card-actions>

    <v-row justify="center">

      <confirmation-dialog
        v-model="confirmDeliveredDialog"
        :title="deliveredconfirmLabel"
        @applyAction="markAsDelivered">
      </confirmation-dialog>

    </v-row> 
  </v-container>
</template>

<script>

  export default {
    name: "pUpsTracking",
    props: {
      fullTracking: {
        type: Boolean,
        default: false
      },
      tracking_no: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        tracking_info: {},
        error_message: "We are unable to load tracking info at this time",
        confirmDeliveredDialog: false,
        shortActivities: [
          "DELIVERED",
          "Delivered",
          "Out For Delivery Today",
          "Out For Delivery",
          "Pickup Scan",
        ],
        colors: {
          Delivered: 'success',
          "Out For Delivery Today": 'accent',
          "Out For Delivery": "accent",
          "Pickup Scan": "primary"
        },
        icons: {
          Delivered: 'mdi-check',
          DELIVERED: 'mdi-check',
          "Out For Delivery Today": 'fa-truck',
          "Out For Delivery": "fa-truck",
          "Pickup Scan": "fa-truck-loading"
        }
      }
    },
    computed: {
      status() {
        return this.tracking_info.currentStatus || ""
      },
      activities() {
        let activities = this.tracking_info.activity || []
        if(!this.fullTracking) {
          activities = activities.filter(activity => {
            return this.shortActivities.indexOf(activity.description) > -1
          })
        }
        return activities
      },
      deliveredLabel() {
        return this.$t('home.upsTracking.deliveredlabel')
      },
      deliveredconfirmLabel() {
        return this.$t('home.upsTracking.deliveredconfirmlabel')
      }
    },
    async mounted() {
      try {
        this.tracking_info = await this.getTracking(this.tracking_no)
      } catch(err) {
        this.$log.error(err)
        this.tracking_info = false
      }
    },
    methods: {
      upsDate(dt) {
        return `${dt.slice(4,6)}/${dt.slice(6)}/${dt.slice(0,4)}`
      },
      upsTime(tm) {
        return `${tm.slice(0,2)}:${tm.slice(2,4)}`
      },
      activityPlace(activity) {
        if(activity.address) {
          if(activity.address.City) {
            return `${activity.address.City}, ${activity.address.StateProvinceCode}`
          }
        }
        return ""
      },
      async getTracking(tracking_no) {
        try {
          if(!tracking_no) return ""
          let resp = await this.$axios.get(["ups", "track", tracking_no])
          this.$log.debug('tracking response', resp)
          let shipment = resp.data.TrackResponse.Shipment
          let activity = shipment.Package.Activity
          if(!Array.isArray(activity)) {
            activity = [activity]
          }
          let payload = {
            trackingNo: shipment.Package.TrackingNumber,
            pickupDate: shipment.PickupDate,
            status: resp.data.TrackResponse.Response.ResponseStatus.Description,
            service: shipment.Service.Description,
//            toAddress: shipment.ShipmentAddress[1].Address || {},
            currentActivity: activity[0].Status.Description,
            activity: activity.map(act => {
              if(!act.ActivityLocation) {
                act.ActivityLocation = {}
              }
              return {
                address: act.ActivityLocation.Address || null,
                description: act.Status.Description || null,
                date: this.upsDate(act.Date) || null,
                time: this.upsTime(act.Time) || null
              }
            })
          }
          this.$log.info("tracking info", resp.data.TrackResponse)
          this.$emit('statusChange', payload.currentActivity)      
          return payload 
        } catch(err) {
          this.$log.error(err)
          throw err
        }
      },

      promptedDelivered() {
        this.confirmDeliveredDialog = true;
      },

      async markAsDelivered() {
        
        let payload = { status: "Delivered" }
          
        await this.$axios.put(['ups/track', this.tracking_no], payload)

        this.tracking_info.currentStatus = "Delivered"
      }
    },
    watch: {
      status(v) {
        this.$emit('statusChange', v)
      }
    }
  }

</script>
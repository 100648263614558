// Vuex module
import store from './store.js'

// Main questionnaire Component
import upsit from "./UPSIT.vue"

// Support Components
import intro from "./components/Intro.vue"
import booklet from "./components/booklet.vue"
import conclusion from "./components/Conclusion.vue"
import bookletCover from "./components/bookletCover.vue"
import bookletReview from "./components/bookletReview.vue"
import bookletPage from "./components/bookletPage.vue"
import upsitDatePicker from "./components/upsitDatePicker.vue"

const upsitPlugin = {
  install(Vue, vuex) {

    let components = [intro, booklet, conclusion, bookletCover, bookletReview, bookletPage, upsitDatePicker]

    Vue.component(upsit.name, upsit)
    components.forEach(component => Vue.component(component.name, component))

    vuex.registerModule("upsit", store)

  }
}

export default upsitPlugin
<template>
  <p-data-input-card
    id="postalAddressesCard"
    :title = "title"
    :subtitle = "subtitle"
    :icon = "icon"
    :state = "state"
    :record_type = "this.record_type"
    :updateDialog.sync = "updateDialog"
    :deleteDialog.sync = "deleteDialog"
    :record.sync="record"
    :records="records"
    inputComponent = "pAddressInput"
    :showTitle="showTitle"
    :flat="!showTitle"
    :blank="$store.getters['participant/blanks'][record_type]"
    @loaded="$emit('loaded', $event)"
    @insert="$emit('insert', $event)"
    @update="$emit('update', $event)"
    @remove="$emit('remove', $event)"
  >
    <!-- <p-postal-address-table
      v-model = "records"
      :activeGuid.sync="active_guid"
    ></p-postal-address-table> -->
    <v-simple-table :dense="$vuetify.breakpoint.mobile">
      <thead>
        <tr>
          <th :style = "{width: '1%', 'text-align': 'center', padding: $vuetify.breakpoint.mobile ? '0' : null}">{{ $t('participant.postaladdresses.primary') }}</th>
          <th :style = "{padding: $vuetify.breakpoint.mobile ? '8px' : null}">{{ $t('participant.postaladdresses.address') }}</th>
          <th style = "text-align: center;" v-if="!$vuetify.breakpoint.mobile"> </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(address, index) in records">
        <tr :key="index" v-if="address !== null">
          <td :style = "{width: '1%', 'text-align': 'center', padding: $vuetify.breakpoint.mobile ? '8px' : null}">
            <v-icon
              v-if="address.is_primary"
              color = "success"
            >mdi-checkbox-marked-circle</v-icon>            
            <v-tooltip bottom v-else-if="!address.is_priPostalAddressTablemary">
              <template v-slot:activator="{on}">
                <v-btn 
                  :disabled="address.is_primary" 
                  icon
                  color='grey lighten-2' 
                  v-on="on" 
                  @click="setPrimary(address)"
                >
                  <v-icon>mdi-checkbox-marked-circle</v-icon>
                </v-btn>    
              </template>
              <span>{{ $t('participant.postaladdresses.markprimary') }}</span>
            </v-tooltip>
          </td>
          <td v-if="$vuetify.breakpoint.lgAndUp">
            <span class="">{{address.line1}}</span>, <span v-if="address.line2">{{address.line2}},</span> {{address.city}}, {{address.state_prov}}, <span v-if="address.state_prov !== 'USA'">{{address.country}}</span> {{address.zipcode}}
          </td>
          <td v-else :style = "{padding: $vuetify.breakpoint.mobile ? '8px' : null}">
            <v-list two-line>
              <v-list-item style = "padding: 0">
                <v-list-item-content>
                  <v-list-item-title>{{address.line1}}</v-list-item-title>
                  <v-list-item-subtitle>{{address.line2}}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{address.city}}, {{address.state_prov}}, {{address.country}}, {{address.zipcode}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div style="max-width: 350px; margin: 0 auto;">
              <v-btn 
                color="accent" 
                width="50%" 
                @click="editRecord(address)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                {{ $t('gen.edit') }}
              </v-btn>
              <v-btn 
                color="error" 
                width="50%" 
                :dark="!address.is_primary"
                :disabled="address.is_primary"
                @click="deleteRecord(address)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                {{ $t('gen.delete') }}
              </v-btn>              
            </div>

          </td>
          <td style="text-align: right" v-if="$vuetify.breakpoint.mobile == false">
            <p-action-button
              tooltipLoc="left"
              :tooltip="editlabel + ' ' + address.line1"
              icon="mdi-pencil"
              :label="editlabel"
              dark
              color="accent"
              @click="editRecord(address)"
            ></p-action-button>
            <p-action-button
              tooltipLoc="right"
              :dark="!address.is_primary"
              :disabled="address.is_primary"
              :tooltip="deletelabel + ' ' + address.line1"
              icon="mdi-delete"
              :label="deletelabel"
              color="error"
              btnClass="ml-3"
              @click="deleteRecord(address)"
            ></p-action-button>
          </td>
        </tr>
        </template>
      </tbody>
    </v-simple-table>
  </p-data-input-card>
</template>

<script>

  import {contactPanelMixin} from "../mixins"

  export default {
    name: "postalAddressesPanel",
    mixins: [contactPanelMixin],
    data() {
      return {
        record_type: "address",
        icon: "fa-home",        
      }
    },
    computed: {
      title(){
        return this.$t('participant.postaladdresses.title')
      },
      recordDescription(){
        return this.$t('gen.postal')
      },
      subtitle(){
        return this.$t('participant.postaladdresses.subtitle')
      },
      editlabel(){
        return this.$t('gen.edit')
      },
      deletelabel(){
        return this.$t('gen.delete')
      }
    }
  }

</script>

<style scoped>

  tr:hover{
    background-color: transparent !important;
  }

</style>
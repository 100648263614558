import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.downloadLink,"target":!!_vm.downloadLink ? _vm.newTab ? '_blank' : null : null},on:{"click":function($event){return _vm.$emit('click')}}},[_c(VList,{staticClass:"pa-0",staticStyle:{"cursor":"pointer"},attrs:{"two-line":""}},[_c(VListItem,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}]},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary lighten-1 white--text",domProps:{"textContent":_vm._s(_vm.leftIcon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(_vm.title)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(_vm.subtitle)}})],1),_c(VListItemAvatar,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.rightIcon)}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-window-item value="intro">
    <v-card-text>
      <span v-if='loading'>
        <p>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </p>
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </span>
      <span v-else>
        <p v-html="intro">
          {{ $t('qnn.intro.thanks') }} 
        </p>
        <p v-html="$t('qnn.intro.begin')"></p>
      </span>
    </v-card-text>
  </v-window-item>
</template>

<script> 

  export default {
    name: "questionnaireIntro",
    computed: {
      intro() {
        return this.$store.getters['questionnaire/intro']
      },
      loading() {
        return this.$store.getters['questionnaire/loading']
      }
    }
  }
</script>
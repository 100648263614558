<template>
  <v-window-item value="intro">
    <v-card-text>
      <div v-html="$t('upsit.introtext1')"></div>
      <v-form v-model='ready'>
        <v-row>
          <v-col cols=12 md=6>
            <v-text-field
              :append-icon="kit_no_icon"
              v-model="prt_kit_no"
              v-mask="kit_no_mask"
              :rules="[v => v.length == 7]"
              counter=7
              outlined
              label="UPSIT ID"
              :hint="kit_no_hint"
              :color="kit_no_color"
              :disabled="loading"
            >
            </v-text-field>         
          </v-col>    
          <v-col cols=12 md=6>
            <p-upsit-date-picker
              outlined
              :dense="false"
              :label="completionLabel"
              :rules="[v => !!v || 'Required']"
              :maxDate="today"
              :minDate="deliver_date"
              v-model="completion_date"
              :disabled="loading"
            ></p-upsit-date-picker>
          </v-col>      
        </v-row>
      </v-form>
      <template v-if="prt_kit_no.length === 7">
        <div v-html="$t('upsit.introtext2')"></div>
        <!-- <p class="mt-2">
          The UPSIT test consists of 40 scratch-and-sniff questions divided between 4 
          booklets. On each page you will complete the following steps:
        </p>
        <ol>
          <li>use the provided pencil to <strong>scratch</strong> the small brown label,</li>
          <li><strong>sniff</strong> the label,</li>
          <li><strong>fill</strong> in the circle in the booklet corresponding to your smell experience,</li>
          <li><strong>click</strong> the option on the webpage corresponding to the option you chose in the booklet.</li>
        </ol>
        <h4>NOTE:</h4>
        <ul class = "mt-4">
          <li>Be sure to mark your choices in the <strong>physical booklet AND on the webpage</strong>.</li>
          <li>Be sure to <strong>answer all questions</strong>. Even if none of the choices match the smell or you cannot smell anything.</li>
        </ul>
        <p class = "mt-4">
          When you are ready to begin click the <strong>Continue</strong> button below.
        </p> -->
      </template> 
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn 
        :disabled="!readyToContinue" 
        class="px-8" 
        color="accent" 
        rounded 
        @click="submitKitNo"
      >{{ $t('gen.continue') }}</v-btn>
    </v-card-actions>    
    <v-img :src = "require('@/assets/upsit_id.jpg')" max-width="600" :style = "{position: 'fixed', top: mousey, left: mousex, display: showUPSITID ? 'block' : 'none'}" ></v-img> 
  </v-window-item>
</template>

<script>

  import { mask } from 'vue-the-mask'

  export default {
    name: "upsitIntro",
    directives: {
      mask,
    },
    data: function() {
      return {
        kit_no_mask: "AAA-AAA",
        kit_no_icon: "",
        kit_no_hint: "",
        kit_no_color: "primary",
        ready: false,
        showUPSITID: false,
        mousex: 0,
        mousey: 0
      }
    },
    computed: {
      loading() {
        return this.$store.getters['upsit/loading']
      },
      deliver_date() {
        return this.$store.getters['upsit/deliver_date'];
      },
      completion_date: {
        get() { return this.$store.getters['upsit/completion_date'] || new Date().toISOString().substr(0,10) },
        set(v) { this.$store.commit("upsit/SET_COMPLETION_DATE", v)}
      },
      kit_no() {
        let x = this.$store.getters['upsit/kit_no']
        return x
      },
      prt_kit_no: {
        get() { return this.$store.getters['upsit/prt_kit_no'] || ""},
        set(v) { this.$store.commit("upsit/SET_PRT_KIT_NO", v)}
      },
      readyToContinue() {
        return this.ready && this.nextBooklet !== null
      },
      today() {
        return new Date().toISOString().substr(0,10)
      },
      nextPage() {
        return this.$store.getters["upsit/next_unanswered"]
      },
      nextBooklet() {
        return this.$store.getters['upsit/next_booklet']
      },
      completionLabel() {
        return this.$t('upsit.datelabel')
      }
    },
    methods: {
      submitKitNo() {
        try {
          let prt_kit_no_payload = {
            ques_guid: this.$store.getters["upsit/prt_kit_no_guid"],
            answer: this.prt_kit_no
          }
          let completion_date_payload = {
            ques_guid: this.$store.getters["upsit/completion_date_guid"],
            answer: this.completion_date         
          }
          this.$store.dispatch("upsit/setAnswer", prt_kit_no_payload)
          this.$store.dispatch("upsit/setAnswer", completion_date_payload)
          this.$emit('continue')
        } catch(err) {
          this.$log.error(err)
        }
      },
      showUPSITimg(ev) {
        this.mousex = ev.pageX + "px"
        this.mousey = (ev.pageY + 10) + "px"
        this.showUPSITID = true
      }
    },
    watch: {
      prt_kit_no: function () {
        this.kit_no_icon = "";
        this.kit_no_hint = "";
        this.kit_no_ready = false;
        if(this.prt_kit_no === null) {
          this.kit_no_color =  "blue";
        } else if(this.prt_kit_no.length < 7) {
          this.kit_no_color =  "blue";
        } else if (this.prt_kit_no.length > 7) {
          this.kit_no_color =  "red";
        } else if(this.prt_kit_no === this.kit_no) {
          this.kit_no_icon = "mdi-check";
          this.kit_no_hint = this.$t('upsit.codematch')
          this.kit_no_color =  "green";
          this.kit_no_ready = true;
          // this.$emit("ready", true)
        } else {
          this.kit_no_icon = "mdi-alert-circle-outline";
          this.kit_no_hint = this.$t('upsit.codemismatch')
          this.kit_no_color =  "red";
          this.kit_no_ready = true;
          // this.$emit("ready", true)
        }      
      },
    },
  }

</script>

<style>

</style>
<template>
  <v-tooltip bottom>
    <template v-slot:activator="{on, attrs}">
      <v-select 
        v-model="lang"
        :dense="dense"
        :label="label"
        v-on="on"
        v-bind="attrs"
        validate-on-blur
        :outlined="outlined"
        :readonly="readonly"
        :rules="[required]"
        :items="locales"
        item-text="name"
        item-value="code"
      >
      </v-select>
    </template>
    <span>{{ $t('participant.generalinfo.languagesaved') }}</span>
  </v-tooltip>
</template>

<script>
import { getSupportedLocales } from "../../../../../../src/services/supported-locales.js"

  export default {
    name: "pPreferredLanguage",
    props: {
      value: {
        required: false,
        default: ""
      },
      editing: {
        type: Boolean,
        required: false,
        default: true
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      },
      dense: {
        type: Boolean,
        required: false,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      validateOnBlur: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      locales: getSupportedLocales(),
      stored_lang: false
    }),
    computed: {
      required() {
        return (v) => {
          return !!v || this.$t('gen.fieldreq')
        }
      },
      readonly() {
        return !!this.stored_lang
      },
      lang: {
        get() {
          if(this.value) {
            return this.value
          } else {
            return ""
          }
        },
        set(v) {
          this.$emit("input", v)
        },
      },
      label() {
        return this.$t('participant.generalinfo.preferredlang')
      }
    },
    mounted() {
      if(this.$store.getters['participant/generalInfo'].preferred_lang){
        this.stored_lang = true
        }
      }
  }
</script>

<style scoped>

  .not-editable {
    pointer-events: none;
  }

</style>
<template>
  <span>
  <v-form ref="form" v-model="validForm">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="record.number"
            :label="phonelabel"
            :rules="rules.line1"
            :dense="dense"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select
            v-model="record.type"
            :items="types"
            item-text='translation'
            item-value='value'
            :label="typelabel"
            :rules="rules.type"
            :dense="dense"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select
            v-model="record.allow_text"
            :items="bools"
            :label="textlabel"
            :rules="rules.allow_text"
            :dense="dense"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select
            v-model="record.allow_vm"
            :items="bools"
            :label="voicelabel"
            :rules="rules.allow_vm"
            :dense="dense"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  </span>
</template>

<script>

  import { formMixin } from "./mixin.js"

  export default {
    name: "pPhoneInput",
    mixins: [formMixin],
    data: function() {
      let not_empty = (v) => {
        return !!v || this.$t('gen.fieldreq')
      }
      let is_bool = (v) => {
        return typeof v === "boolean" || this.$t('gen.notempty')
      }
      return {
        rules: {
          number: [not_empty],
          type: [not_empty],
          allow_vm: [is_bool],
          allow_text: [is_bool],
        }
      }
    },
    computed: {
      types() {
        return this.$store.getters['options/phone_type']
      },
      phonelabel(){
        return this.$t('gen.phone')
      },
      typelabel(){
        return this.$t('participant.emailform.type')
      },
      textlabel(){
        return this.$t('participant.phoneform.allowtext')
      },
      voicelabel(){
        return this.$t('participant.phoneform.allowvoice')
      },
      bools(){
        return [
          {text: this.$t('gen.yes'), value: true},
          {text: this.$t('gen.no'), value: false}
        ]
      }
    }
  }

</script>

<style>

</style>
<template>
  <v-form 
    ref="form" 
    lazy-validation 
    v-model="valid_form" 
    :class="{'no-edit': !editing}" 
  >
    <div :class="{'hidden': !hintVisible}" :style="{top: hinty, left: hintx}" class="hint">{{ $t('participant.generalinfo.clickedit')}}</div>
    <div 
      style='position: absolute; left: 0; right: 0; top: 0; bottom: 50px; z-index: 250;'
      :class="{hidden: editing}"
      @mouseover="showHint"
      @mouseout="hideHint"    
    ></div>
      <v-row dense class = "name_row">
        <v-col id="first_name" cols="12" md="3" lg="3">
          <v-text-field
            v-model="info.first_name"
            dense
            :label="firstnamelabel"
            validate-on-blur
            :rules="[required]"
            :class="{'not-editable': !editing}"
            ref="first_name"
            data-id="first_name"
          ></v-text-field>        
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-text-field
            v-model="info.middle_name"
            dense
            :label="middlenamelabel"
            validate-on-blur
            hint=""
            :class="{'not-editable': !editing}"
            data-id='middle_name'
          ></v-text-field>        
        </v-col>        
        <v-col cols="12" md="3" lg="3">
          <v-text-field
            v-model="info.last_name"
            dense
            validate-on-blur
            :rules="[required]"
            :label="lastnamelabel"
            :class="{'not-editable': !editing}"
            data-id='last_name'
          ></v-text-field>        
        </v-col>
        <v-col cols="12" lg="3">
          <v-checkbox 
            dense 
            v-model="info.name_different" 
            class="mt-0" 
            id="name_different"
            :label="namedifferentlabel"
            :class="{'not-editable': !editing}"
            @change="handle_name_different"
            data-id="name_different"
          ></v-checkbox>
        </v-col>          
      </v-row>
      <v-row dense v-show="info.name_different==true">
        <v-col cols="12" md="2" lg="3">
          <v-text-field
            v-model="info.birth_first_name"
            :rules="info.name_different ? [required] : []"
            dense
            validate-on-blur
            :label="firstatbirthlabel"
            :class="{'not-editable': !editing}"
            data-id='birth_first_name'
          ></v-text-field>        
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-text-field
            v-model="info.birth_middle_name"
            dense
            validate-on-blur
            :label="middleatbirthlabel"
            :class="{'not-editable': !editing}"
            data-id='birth_middle_name'
          ></v-text-field>        
        </v-col>        
        <v-col cols="12" md="3" lg="3">
          <v-text-field
            v-model="info.birth_last_name"
            dense
            validate-on-blur
            :rules="info.name_different ? [required] : []"
            :label="lastatbirthlabel"
            :class="{'not-editable': !editing}"
            data-id='birth_last_name'
          ></v-text-field>        
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-select
            v-model="info.sex_at_birth"
            dense
            validate-on-blur
            :items='sex'
            item-text='translation'
            item-value='value'
            :label="birthsexlabel"
            :rules="[required]"
            :class="{'not-editable': !editing}"
            data-id="gender"
          ></v-select>        
        </v-col>
        <v-col cols="12" md="6">
          <p-birthdate-input
            v-model = "info.birth_date"
            dense
            validate-on-blur
            data-id='birth_date'
          >
          </p-birthdate-input>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-select
            v-model="info.race"
            dense
            :items='race'
            item-text='translation'
            item-value='value'
            :label="racelabel"
            validate-on-blur
            :rules="[required_multi]"
            multiple
            :menu-props="{'maxHeight': 500}"
            :class="{'not-editable': !editing}"
            data-id='race'
            @change="updateRace"
          ></v-select>        
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="info.ethnicity"
            dense
            validate-on-blur
            :items='ethnicity'
            item-text='translation'
            item-value='value'
            :label="ethnicitylabel"
            :rules="[required]"
            :class="{'not-editable': !editing}"
            data-id='ethnicity'
          ></v-select>        
        </v-col>
        <v-col cols="12" md="4">
          <p-preferred-language
            v-model = "info.preferred_lang"
            dense
            validate-on-blur
            data-id='preferred_lang'
          >
          </p-preferred-language>     
        </v-col>
      </v-row>  
      <v-row dense>
          <v-col cols="12" md="4">
          <v-text-field
            v-model="info.birth_city"
            dense
            validate-on-blur
            :label="birthcitylabel"
            :rules="[required]"
            :class="{'not-editable': !editing}"
            data-id='birth_city'
          ></v-text-field>        
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="info.birth_country"
            dense
            :items='countries'
            item-text='translation'
            item-value='value'
            validate-on-blur
            :label="birthcountrylabel"
            persistent-hint
            :rules="[required]"
            hint=""
            :class="{'not-editable': !editing}"
            data-id="birth_country"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="info.preferred_contact"
            dense
            validate-on-blur
            :items='preferred_contact'
            item-text='translation'
            item-value='value'
            :rules="[required]"
            :label="prefercontactlabel"
            :class="{'not-editable': !editing}"
            data-id="preferred_contact_method"
          ></v-select>        
        </v-col>
      </v-row>        
  </v-form>
</template>

<script>

  export default {
    name: "generalInfoForm",
    props: {
      value: {
        type: Object
      },
      editing: {
        type: Boolean,
        required: true
      },
      valid: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        hintx: "0px",
        hinty: "0px",
        valid_form: true,
        hintVisible: false,
        multipleRace: true,
      }
    },
    computed: {
      required() {
        return (v) => {
          return !!v || this.$t('gen.fieldreq')
        }
      },
      required_multi() {
        return (v) => {
          return v.length != 0 || this.$t('gen.fieldreq')
        }
        },
      selectedRace: {
        get() {
          return this.info.race || []
        },
        set(v) {
          this.info.race = v
        }
      },
      countries() {
        const fullList = this.$store.getters['options/country']
        const listHead = [
          fullList.find( ({ value }) => value === "United States of America"),
          fullList.find( ({ value }) => value === "Canada"),
          fullList.find( ({ value }) => value === "Mexico")]

        // concat with sorted array
        let servedList = listHead.concat(fullList.sort((a, b) => {
          const valA = a.translation
          const valB = b.translation
          if (valA < valB) {
              return -1;
            }
            if (valA > valB) {
              return 1;
            }
          return 0;
          }
          ))
        return servedList
      },
      race() {
        let race = this.$store.getters['options/race'].filter(r => ["Other", "More than One Race", "Do Not Know", "Decline to Answer"].indexOf(r) == -1)
        race.push("Do Not Know")
        race.push("Decline to Answer")
        if(this.selectedRace.length === 0) return race
        if(!(this.selectedRace.includes('Do Not Know') || this.selectedRace.includes("Decline to Answer"))) {
          race = race.filter(r => r !== "Do Not Know" && r !== "Decline to Answer")
        }
        return race
      },
      sex() {
        return this.$store.getters['options/gender']
      },
      preferred_contact() {
        return this.$store.getters['options/preferred_contact']
      },
      ethnicity() {
        return this.$store.getters['options/ethnicity']
      },
      preferred_lang() {
        return this.$store.getters['options/preferred_lang']
      },
      info: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
      firstnamelabel(){
        return this.$t('participant.generalinfo.firstname')
      },
      middlenamelabel(){
        return this.$t('participant.generalinfo.middlename')
      },
      lastnamelabel(){
        return this.$t('participant.generalinfo.lastname')
      },
      namedifferentlabel(){
        return this.$t('participant.generalinfo.namedifferent')
      },
      firstatbirthlabel(){
        return this.$t('participant.generalinfo.firstatbirth')
      },
      middleatbirthlabel(){
        return this.$t('participant.generalinfo.middleatbirth')
      },
      lastatbirthlabel(){
        return this.$t('participant.generalinfo.lastatbirth')
      },
      birthsexlabel(){
        return this.$t('participant.generalinfo.birthsex')
      },
      racelabel(){
        return this.$t('participant.progressdialog.labelsObj.race')
      },
      ethnicitylabel(){
        return this.$t('participant.generalinfo.ethnicity')
      },
      birthcitylabel(){
        return this.$t('participant.generalinfo.birthcity')
      },
      birthcountrylabel(){
        return this.$t('participant.generalinfo.birthcountry')
      },
      prefercontactlabel(){
        return this.$t('participant.generalinfo.prefercontact')
      },
    },
    methods: {
      handle_name_different(v) {
        this.$refs.form.resetValidation()
        if(!v) {
          this.info.birth_first_name = null
          this.info.birth_middle_name = null
          this.info.birth_last_name = null
        }
      },
      mouseFollow(ev) {
        this.hintx = `${ev.layerX + 6}px`
        this.hinty = `${ev.layerY + 6}px`
      },
      showHint() {
        if(!this.editing) {
          window.addEventListener("mousemove", this.mouseFollow)
          this.hintVisible = true
        }
      },
      hideHint() {
        window.removeEventListener("mousemove", this.mouseFollow)
        this.hintVisible = false
      },
      validate() {
        this.$refs.form.validate()
      },
      updateRace(v) {
        let lastSelected = v[v.length-1]
        if(lastSelected == "Do Not Know" || lastSelected == "Decline to Answer") {
          this.selectedRace = [lastSelected]
        } else {
          this.selectedRace = v.filter(r => r !== "Do Not Know" && r !== "Decline to Answer")
        }
      }
    },
    watch: {
      valid_form(v) {
        this.$emit("update:valid", v)
      }
    }
  }

</script>

<style scoped>

  .hint {
    position: absolute;
    padding: 8px;
    color: white;
    font-weight: bold;
    background-color: #444;
    border-radius: 6px;
    opacity: 1;
    z-index: 250;
  }

  .hidden {
    display: none;
  }

  .no-edit {
    cursor: not-allowed;
  }

  .bd-tooltip {
    vertical-align: top;
    color: red;
    font-size: .7rem;
  }
</style>
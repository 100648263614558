<template>
   <v-window-item value="review">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <p v-html="$t('upsit.pagereview', {bookNo: book.bookNo})"></p>
        </v-col>
        <v-col cols="12" class = "d-flex flex-row-reverse">
        <v-radio-group v-for="(page, i) in book.pages" v-model="page.answer" :key="i">
            <v-list dense :style="'background-color: ' + colors[page.color]">
            <v-list-item class = "page_no" style="padding-left: 4px; padding-right: 4px;">
                <v-list-item-content>
                <div>{{page.pageNo}}</div>
                </v-list-item-content>
            </v-list-item>
            <v-list-item 
                v-for="(option, j) in page.options" 
                :key="j"
                style="padding-left: 10px; padding-right: 10px;"
            >
                <v-list-item-content :key="j"  style="overflow: visible; margin-right: -8px;">
                <v-radio
                color='black' 
                :value = 'letters[j]' 
                :key="option" 
                :on-icon="get_icon(j, true)" 
                :off-icon="get_icon(j, false)"
                class="page_option"
                ></v-radio>
                </v-list-item-content>              
            </v-list-item>
            </v-list>
        </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
  </v-window-item>
</template>

<script>
export default {
  name: "upsitBookletReview",
  props: {
    bookNo: {
      type: Number,
      required: true
    },
  },
  data: function() {
    return {
      
    }
  },
  computed: {
    letters() {
      return this.$store.getters['upsit/letters']
    },
    colors() {
      return this.$store.getters['upsit/colors']
    },
    book() {
      let x = this.$store.getters['upsit/book'](this.bookNo)
      return x
    },
  },
  methods: {
    get_icon: function(i, on = false) {
      let icon = `mdi-alpha-${this.letters[i]}-circle`
      if(!on) {
        icon = icon + "-outline";
      }
      return icon;
    },
  }
}
</script>

<style scoped>

  .page_no {
    text-align: center !important;
  }
  .page_option > .v-input--selection-controls__input {
    margin-right: 0px !important;
  }
  .v-input--selection-controls__ripple {
    margin: 0 !important;
  }
</style>

<template>
  <v-dialog v-model='show' :fullscreen="$vuetify.breakpoint.mobile" persistent max-width="1000px" eager>
    <v-card >
      <v-card-title class = "headline">{{ $t('consent.declineDialog.declinetitle') }}</v-card-title>
      <v-card-text>
        {{ $t('consent.declineDialog.chooseoption') }}
        <v-select
          :items = "decline_reasons"
          v-model = "declined_category"
        ></v-select>
        {{ $t('consent.declineDialog.addlcomments') }}
        <v-textarea v-model="additional_comments"></v-textarea>                 
       {{ $t('consent.declineDialog.talk') }}
        <v-radio-group v-model="staff_contact" row>
          <v-radio label = "Yes" :value = 'true'></v-radio>
          <v-radio label = "No" :value = 'false'></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="cancel">{{ this.$t('gen.cancel') }}</v-btn>
        <v-btn color="primary darken-1" :disabled="disabled" text @click="save">{{ this.$t('gen.submit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
 
<script>

  export default {
    name: "consentDialogDecline",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      allowSubmit: {
        type: Boolean,
        default: true
      },
    },
    data: function() {
      return {
       
        declined_category: null,
        additional_comments: "",
        staff_contact: false,
      }
    },
    computed: {
      disabled: function() {
        return !this.allowSubmit
      },
      decline_reasons(){
        return [
          this.$t('consent.declineDialog.decline1'),
          this.$t('consent.declineDialog.decline2'),
          this.$t('consent.declineDialog.decline3'),
          this.$t('consent.declineDialog.decline4')
        ]
       },
      yes(){
        return this.$t('gen.yes')
       },
      no(){
        return this.$t('gen.no')
       }
    },
    methods: {
      clearSig: function() {
        this.$refs.signaturePad.undoSignature();
      },
      save: function() {
        this.$emit("response", {
          declined_category: this.declined_category,
          additional_comments: this.additional_comments,
          staff_contact: this.staff_contact ? this.$t('gen.yes') : this.$t('gen.no')
        })
        // this.$emit("closeDialog")
      },
      cancel: function() {
        this.$emit("closeDialog")
      }
    },
  }

</script>
<template>
  <p-data-input-card
    :title = "title"
    id="emailAddressesCard"
    :subtitle = "subtitle"
    :icon = "icon"
    :state = "state"
    record_type = "email"
    :updateDialog.sync = "updateDialog"
    :deleteDialog.sync = "deleteDialog"
    :record.sync="record"
    :records="records"
    inputComponent = "pEmailInput"
    :showTitle="showTitle"
    :flat="flat"
    :blank="$store.getters['participant/blanks'][record_type]"
    @loaded="$emit('loaded', $event)"
    @insert="$emit('insert', $event)"
    @update="$emit('update', $event)"
    @remove="$emit('remove', $event)"
  >
    <v-simple-table>
      <thead>
        <tr>
          <th :style = "{padding: mobile ? '8px' : null}">{{ $t('gen.email') }}</th>
          <th :style = "{padding: mobile ? '8px' : null, 'text-align': 'center'}">{{ $t('participant.emailform.type') }}</th>
          <th style = "text-align: center;" v-if="!mobile"> </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(email) in records">
          <tr :key="email.guid">
            <td :class = "{mobile: mobile}">
              {{email.email}}
            </td>
            <td :class = "{mobile: mobile}" :style="{'text-align': 'center'}">
              {{email.type}}
            </td>
            <td style="text-align: right" v-if="!mobile">
              <p-action-button
                tooltipLoc="left"
                :tooltip="'Edit ' + email.email"
                :disabled="email.is_primary"
                :dark="!email.is_primary"
                icon="mdi-pencil"
                :label="editLabel"
                color="accent"
                @click="editRecord(email)"
              ></p-action-button>
              <p-action-button
                tooltipLoc="right"
                :dark="!email.is_primary"
                :disabled="email.is_primary"
                :tooltip="'Delete ' + email.email"
                icon="mdi-delete"
                :label="deleteLabel"
                color="error"
                btnClass="ml-3"
                @click="deleteRecord(email)"
              ></p-action-button>
            </td>
          </tr>
          <tr :key="`m-${email.guid}`" v-if="mobile">
            <td colspan = 2 style="text-align: center">
              <div style="max-width: 350px; margin: 0 auto;">
                <v-btn 
                  color="accent" 
                  width="50%" 
                  :disabled="email.is_primary"
                  :dark="!email.is_primary"
                  @click="editRecord(email)"
                >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  {{ $t('gen.edit') }}
                </v-btn>
                <v-btn 
                  color="error" 
                  width="50%" 
                  :dark="!email.is_primary"
                  :disabled="email.is_primary"
                  @click="deleteRecord(email)"
                >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  {{ $t('gen.delete') }}
                </v-btn>  
              </div>            
            </td>  
          </tr>          
        </template>

      </tbody>
    </v-simple-table>
  </p-data-input-card>
</template>

<script>

  import {contactPanelMixin} from "../mixins"

  export default {
    name: "emailAddressesPanel",
    mixins: [contactPanelMixin],
    data() {
      return {
        record_type: 'email',
        icon: "fa-at",        
      }
    },
    computed: {
      editLabel(){
        return this.$t('gen.edit')
      },
      deleteLabel(){
        return this.$t('gen.delete')
      },
      title(){
        return this.$t('participant.emailAddresses.addresses')
      },
      recordDescription(){
        return this.$t('gen.email')
      },
      subtitle(){
        return this.$t('participant.emailAddresses.subtitle')
      },
    }
  }

</script>

<style scoped>

  tr:hover{
    background-color: transparent !important;
  }

  .hidden {
    display: none;
  }

  td.mobile {
    border-bottom: none !important;
    padding: 8px !important;
  }

</style>
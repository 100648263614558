<template>
  <div :style="{height: mapHeight, width: '100%'}">
    <l-map
      ref="map"
      style="height: 100%; width: 100%"
      :zoom="zoom"
      :center="center" 
      :bounds="bounds"
      @ready="mapReady=true"

    >
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker :lat-lng="homeLocation" :icon='homeIcon'>
        <l-popup>
          {{ $t('shipping.primary-address') }}
        </l-popup>
      </l-marker>
      <template v-for="(dropoff, index) in dropoffLocations">
        <l-marker :lat-lng="dropoff.latlng" :key="index" :icon="dropoffIcon" :ref="`marker${index}`">
          <l-popup>
            <v-card-title class="px-0 pt-0 pb-3">{{dropoff.name}}</v-card-title>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon class="my-5 mr-2"><v-icon>mdi-map-marker mdi-36px</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{dropoff.address}}</v-list-item-title>
                  <v-list-item-subtitle>{{dropoff.city}}, {{dropoff.state}} {{dropoff.zip}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon class="my-5 mr-2"><v-icon>mdi-truck-delivery mdi-36px</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('shipping.last-pickup') }}</v-list-item-title>
                  <v-list-item-subtitle>{{dropoff.latestPickup}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>    
              <v-list-item v-if='dropoff.notes'>
                <v-list-item-icon class="my-5 mr-2"><v-icon>mdi-information-outline mdi-36px</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('shipping.special-instructions') }}</v-list-item-title>
                  {{dropoff.notes}}
                </v-list-item-content>
              </v-list-item>             
            </v-list>
          </l-popup>
        </l-marker>
      </template>    
    </l-map>
  </div>
</template>

<script>

  import L from 'leaflet'
  import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';

  export default {
    name: 'pShippingMap',
    props: ['homeLocation', 'dropoffLocations', 'selectedLocation'],
    components: {LMap, LTileLayer, LMarker, LPopup},
    data () {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 5,
        center: [35,-100],
        mapHeight: "800px",
        bounds: L.latLngBounds([[24.12, -129.55], [48.16, -66.96]]),
        marker: L.latLng(35,-100),
        homeIcon: L.divIcon({
          html: '<i style="color: #246d8f" class="mdi-home-map-marker mdi-36px mdi"></i>',
          iconSize: [36, 36],
          iconAnchor: [18,36],
          className: 'myDivIcon'          
        }),
        dropoffIcon: L.divIcon({
          html: '<i style="color: #f2a900" class="mdi-map-marker mdi-36px mdi"></i>',
          iconSize: [36,36],
          iconAnchor: [18,36],
          className: 'myDivIcon'
        }),
      }
    },
    computed: {
      
    },
    updated() {
      this.redrawMap()
    },
    mounted() {
      this.redrawMap()
    },
    methods: {
      async redrawMap() {
        this.$nextTick(() => {
          window.setTimeout(() => {
            this.$refs.map.mapObject.invalidateSize();
          }, 100)
        })
      },
      getBounds(v) {
        let lats = v.map(loc => {
          return loc.latlng.lat
        })
        let lngs = v.map(loc => {
          return loc.latlng.lng
        })
        let ne = [Math.max(...lats), Math.max(...lngs)]
        let sw = [Math.min(...lats), Math.min(...lngs)]
        let latinset = Math.abs(ne[0] - sw[0]) * 0.03
        let lnginset = Math.abs(ne[1] - sw[1]) * 0.03
        ne = [ne[0]+latinset, ne[1]+lnginset]
        sw = [sw[0]-latinset, sw[1]-lnginset]
        return L.latLngBounds([ne,sw])        
      },
      updateCenter(center) {
        this.center = center
      },
      updateBounds(bounds) {
        this.bounds = bounds
      },
      updateZoom(zoom) {
        this.zoom = zoom
      }
    },
    watch: {
      selectedLocation(v) {
        if(typeof v === 'undefined') {
          this.bounds = this.getBounds(this.dropoffLocations)
        } else {
          let loc = this.dropoffLocations[v].latlng
          let b = [[loc.lat+0.01, loc.lng+0.01],[loc.lat-0.01, loc.lng-0.01]]
          this.bounds = b
          let ref = `marker${v}`
          this.$refs[ref][0].mapObject.openPopup()
        }
      },
      dropoffLocations(v) {
        this.bounds = this.getBounds(v)
        this.redrawMap()
      }
    }
  }
</script>

<style scoped>
  @import "~leaflet/dist/leaflet.css";
</style>
// Vuex module
import store from './store.js'

// Main questionnaire Component
import participant from "./Participant.vue"

// Support Components
import general from "./Components/generalInfo.vue"
import generalInfoForm from "./Components/generalInfoForm.vue"
import postalAddresses from "./Components/postalAddresses.vue"
import birthdateInput from "./Components/birthdateInput.vue"
import preferredLanguage from "./Components/preferredLanguage.vue"
import emailAddresses from "./Components/emailAddresses.vue"
import phoneNumbers from "./Components/phoneNumbers.vue"
import confirmDeleteDialog from "./Components/confirmDeleteDialog.vue"
import dataUpdateDialog from "./Components/dataUpdateDialog.vue"
import dataInputCard from "./Components/dataInputCard.vue"
import progressDialog from "./Components/progressDialog.vue"
import travelPreferences from "./Components/travelPreferences.vue"

import addressForm from "./Components/contactForms/addressForm.vue"
import emailForm from "./Components/contactForms/emailForm.vue"
import phoneForm from "./Components/contactForms/phoneForm.vue"

const participantPlugin = {
  install(Vue, options) {
    let components = [progressDialog, general, generalInfoForm, phoneNumbers, phoneForm, birthdateInput, preferredLanguage, postalAddresses, 
      emailAddresses, addressForm, emailForm, confirmDeleteDialog, dataUpdateDialog, travelPreferences,
      dataInputCard]

    Vue.component(participant.name, participant)
    components.forEach(component => Vue.component(component.name, component))

    options.registerModule("participant", store)
  }
}

export default participantPlugin
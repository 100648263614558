import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VWindowItem,{attrs:{"value":"review"}},[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('upsit.pagereview', {bookNo: _vm.book.bookNo}))}})]),_c(VCol,{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"12"}},_vm._l((_vm.book.pages),function(page,i){return _c(VRadioGroup,{key:i,model:{value:(page.answer),callback:function ($$v) {_vm.$set(page, "answer", $$v)},expression:"page.answer"}},[_c(VList,{style:('background-color: ' + _vm.colors[page.color]),attrs:{"dense":""}},[_c(VListItem,{staticClass:"page_no",staticStyle:{"padding-left":"4px","padding-right":"4px"}},[_c(VListItemContent,[_c('div',[_vm._v(_vm._s(page.pageNo))])])],1),_vm._l((page.options),function(option,j){return _c(VListItem,{key:j,staticStyle:{"padding-left":"10px","padding-right":"10px"}},[_c(VListItemContent,{key:j,staticStyle:{"overflow":"visible","margin-right":"-8px"}},[_c(VRadio,{key:option,staticClass:"page_option",attrs:{"color":"black","value":_vm.letters[j],"on-icon":_vm.get_icon(j, true),"off-icon":_vm.get_icon(j, false)}})],1)],1)})],2)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
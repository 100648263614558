import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.title=='')?_c(VSkeletonLoader,{attrs:{"type":"list-item-avatar-two-line","width":"50%"}}):_c(VListItem,[_c(VListItemAvatar,{attrs:{"color":_vm.iconColor,"size":"48"}},[_c(VIcon,{attrs:{"dark":"","size":"24"}},[_vm._v(_vm._s(_vm.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline pb-1",staticStyle:{"text-transformation":"capitalize","line-height":"1em"},style:({}),domProps:{"innerHTML":_vm._s(_vm.shownTitle)}}),(_vm.subtitle)?_c(VListItemSubtitle,{staticStyle:{"font-weight":"bold"},domProps:{"innerHTML":_vm._s(_vm.subtitle)}}):_vm._e()],1),_c(VListItemAction,[_vm._t("action",[(_vm.fontSizeAdjust)?_c('div',[_c('div',{staticClass:"d-none d-md-flex"},[_vm._v(_vm._s(_vm.$t('gen.fontsize'))+":")]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.setFont(-1)}}},[_c(VIcon,[_vm._v("mdi-format-font-size-decrease")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.setFont(1)}}},[_c(VIcon,[_vm._v("mdi-format-font-size-increase")])],1)],1):_vm._e()])],2)],1),_c(VProgressLinear,{attrs:{"active":_vm.showProgressBar,"indeterminate":_vm.loading},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),(!_vm.showProgressBar)?_c(VDivider):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
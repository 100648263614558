import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"persistent":"","max-width":"1000px","eager":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('consent.questionDialog.title')))]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('consent.questionDialog.contacting'))+" "),_c(VTextarea,{model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('gen.cancel')))]),_c(VBtn,{attrs:{"color":"primary darken-1","disabled":_vm.disabled,"text":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('gen.submit')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>    
  <v-row justify='center'>
    <v-col cols="12" lg="10" xl="8">
      <general-info-panel
        @editing="editingGeneral = $event"
        v-model="generalInfo"
        :loading="loading"
      ></general-info-panel>
    </v-col>
    <v-col cols="12" lg="10" xl="8">
      <postal-addresses-panel
        :records="addresses"
        @update='update'
        @insert='insert'
        @remove='remove'
      ></postal-addresses-panel>
    </v-col>
    <v-col cols="12" lg="10" xl="8">
      <phone-numbers-panel
        :records="phoneNumbers"
        @update='update'
        @insert='insert'
        @remove='remove'     
      ></phone-numbers-panel>
    </v-col>    
    <v-col cols="12" lg="10" xl="8">
      <email-addresses-panel
        :records="emailAddresses"
        @update='update'
        @insert='insert'
        @remove='remove'    
      ></email-addresses-panel>
    </v-col>
    <!-- <v-col cols="12" lg="10" xl="8">
      <p-travel-preferences-dialog
        id="travel_preferences"
        v-if="travel_eligible"
        :travel="travel_preferences"
        :travel_task = "travel_task"
        @insert='insert'
      ></p-travel-preferences-dialog>
    </v-col> -->
    <progress-dialog
      :visible.sync = "showHelpDialog"
      :editingGeneral = "editingGeneral"
    ></progress-dialog>
  </v-row>
</template>

<script>

  export default {
    name: "participantPage",
    data() {
      return {
        editingGeneral: false,
        showHelpDialog: false,
        participant: {},
        travel_task: "none"
      }
    },
    async mounted() {
      if(this.$store.state.route.query.task) {
        let task = this.$store.state.route.query.task
        if(task == "demog") {
          this.showHelpDialog = true
        } else if(task == "travel_screening") {
          this.travel_task = "travel_screening"
          document.getElementById("travel_preferences").scrollIntoView();
        }
      }
    },
    computed: {
      loading: {
        get() {
          return this.$store.getters['participant/loading'].general
        },
        set(v) {
          this.$store.commit("participant/SET_LOADING)", {type: "general", loading: v})
        }
      },
      generalInfo: {
        get() { return this.$store.getters['participant/generalInfo']},
        set(v) { 
          this.$store.dispatch("participant/updateGeneral", {data: v}) 
        }
      },
      travel_eligible() {
        return this.$store.getters['participant/travel_eligible'] || false
      },
      travel_preferences() { return this.$store.getters['participant/travel_preferences'] },
      addresses() { return this.$store.getters['participant/address'] },
      phoneNumbers() { return this.$store.getters['participant/phone'] },
      emailAddresses() { return this.$store.getters['participant/email'] }
    },
    methods: {
      update(v) {
        this.$store.dispatch("participant/updateContact", v)
      },
      insert(v) {
        this.$store.dispatch("participant/addContact", v)
      },
      remove(v) {
        this.$store.dispatch("participant/deleteContact", v)
      },
    },
    watch: {  

    }
  } 
</script>
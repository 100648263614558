<template>
  <div class="mr-5 locale-switcher">
    <select v-model="$i18n.locale" @change="updateLocale()">
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.name}}
      </option>
    </select>
  </div>
</template>
<script>
import { getSupportedLocales } from "@/services/supported-locales.js"
export default {
  data: () => (
    { locales: getSupportedLocales() }
  ),
  methods: {
updateLocale(){
  console.log('setting current locale to ', this.$i18n.locale)
  this.$store.commit("userSettings/SET_CURRENT_LOCALE", this.$i18n.locale)
  if(this.$store.getters['auth/isLoggedIn']){
    this.$store.dispatch("options/load", true)
    this.$store.dispatch("tasks/load")
    if(this.$store.getters['consent/qnn_guid']){
      this.$store.dispatch("consent/load", this.$store.getters['consent/qnn_guid'])
    }
  }
}
  },
  mounted() {
    // needed? maybe not
  }
}
</script>
<style scoped>

.locale-switcher select {
  color: white;
  background-color: #f7931e;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 0.5rem;
  text-transform: uppercase;
}
</style>
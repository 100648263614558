<template>
  <v-expansion-panels v-model="open" id="todo">
    <template
      v-for="(taskGroup, index) in taskGroups"
    >
      <p-task-group
        v-if="taskGroup.visible"
        :key="index"
        :taskGroup="taskGroup"
        :visible="open == index"
      ></p-task-group>      
    </template>
  </v-expansion-panels>
</template>

<script>

  export default {
    name: "pTaskPanels",
    data() {
      return {
        open: -1,
      }
    },
    computed: {
      taskGroups() {
        let tasks =  this.$store.getters['tasks/taskGroups']
        return tasks
      },
      activeTask() {
        return this.$store.getters['tasks/activeTask']
      }
    },
    mounted() {
      this.open = this.pickOpenPanel()
    },
    updated() {
   
    },
    methods: {
      pickOpenPanel() {
        for(let i = 0; i < this.taskGroups.length; i++) {
          if(this.taskGroups[i].active == true) {
            return i
          }
        }
        return this.taskGroups.length - 1
      }
    },
    watch: {
      taskGroups() {
        this.open = this.pickOpenPanel()
        if(!this.activeTask) {
          if(this.$store.getters['participant/status'] == "Active") {
            this.$store.dispatch("snackbar/display", {"message": this.$t('home.taskPanel.done'), color: 'success'})
          } else {
            this.$store.dispatch("snackbar/display", {"message": this.$t('home.taskPanel.locked'), color: 'warning'})
          }        
        }       
      },
    }
  }

</script>
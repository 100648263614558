<template>
  <v-window-item class="pa-0 ma-0">
    <v-card-text align='center' class="pa-0">
      <v-radio-group v-model="radio_answer" class = "pa-0 ma-0">
        <v-card :color="colors[page.color]" flat class = "rounded-0">
          <v-card-title class = "d-flex flex-row-reverse">
            {{page.pageNo}}. {{ $t('upsit.pagetitle') }}
          </v-card-title>
          <v-card-text class = "page-text d-flex flex-row-reverse">
            <v-list dense color="transparent" class rounded>
              <v-list-item 
                v-for="(o, i) in page.options" 
                :key="'item'+i" 
                @click="setAnswer(i)"
                :style="answerColor(letters[i])"
              >
                <v-list-item-content class="pr-8">
                  {{letters[i]}}. {{o}}
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-radio 
                    color='black' 
                    :label="null"
                    :value = 'letters[i]' 
                    :key="o" 
                    :on-icon="get_icon(i, true)" 
                    :off-icon="get_icon(i, false)"
                  ></v-radio>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>          
          </v-card-text>
        </v-card>
      </v-radio-group>
    </v-card-text>
  </v-window-item> 
</template>

<script>

  export default {
    name: "upsitBookletPage",
    props: {
      bookNo: {
        type: Number,
        required: true
      },
      pageNo: {
        type: Number,
        required: true
      }
    },
    data: function() {
      return {
      }
    },
    computed: {
      radio_answer: {
        get() { return this.$store.getters['upsit/answer'](this.bookNo, this.pageNo)},
        set(v) { 
          this.$store.commit("upsit/SET_ANSWER", {question_guid: this.page.guid, answer: v})
          this.$emit("answered", v)
        }
      },
      letters() {
        return this.$store.getters['upsit/letters']
      },
      colors() {
        return this.$store.getters['upsit/colors']
      },
      page() {
        return this.$store.getters['upsit/page'](this.bookNo, this.pageNo)
      },
      answer: {
        get() {
          let ans = this.$store.getters['upsit/answer'](this.bookNo, this.pageNo)
          return ans
        },
        async set(x) {
          this.$store.dispatch("upsit/setAnswer", {bookNo: this.bookNo, pageNo: this.pageNo, qnn_guid: this.page.guid, ques_guid: this.page.guid, answer: x})
          this.$emit("answered", x)
        }
      }
    },
    methods: {
      get_icon: function(i, on = false) {
        let icon = `mdi-alpha-${this.letters[i]}-circle`
        if(!on) {
          icon = icon + "-outline";
        }
        return icon;
      },
      answerColor: function(x) {
        if(this.answer === x) {
          return "background-color: rgba(0,0,0,0.25)"
        } else {
          return ""
        }
      },
      setAnswer(i) {
        this.answer = this.letters[i]
      }
    },
    updated() {
      if(['a', 'b', 'c', 'd'].indexOf(this.radio_answer) > -1) {
        this.$emit("answered", this.radio_answer)
      }
    },
    watch: {
      radio_answer(v) {
        this.$emit("answered", v)
      },
      answer(v) {
        this.radio_answer = v
      }
    }
  }
</script>

<style scoped>

  .v-radio .v-icon {
    font-size: 32px;
    color: black !important;
  }

</style>
// Vuex module
import store from './store.js'

// Main snackbar Component
import snackbar from "./snackbarAlerts.vue"

const snackbarPlugin = {
  install(Vue, options) {

    let components = []
    Vue.component(snackbar.name, snackbar)
    components.forEach(component => Vue.component(component.name, component))
    
    options.registerModule("snackbar", store)
    
  }
}

export default snackbarPlugin
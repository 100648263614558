<template>
  <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="400">
      <v-card>
        <p-card-title
          :title="deletelabel"
          icon="mdi-alert"
          iconColor="warning"
        ></p-card-title>
        <v-card-text>
          <p>{{ $t('participant.confirmdelete.sure') }}</p> 
          <p><strong>{{ description }}</strong></p>
          <p>{{ $t('participant.confirmdelete.notundone') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <p-action-button
            color = "error" 
            dark
            icon="mdi-close"
            @click="close"
            :label = "cancellabel"
          ></p-action-button>            
          <p-action-button 
            color = "success" 
            dark
            btnClass="ml-3"
            @click="deleteData" 
            icon = "mdi-check"
            :label = "confirmlabel"
          ></p-action-button> 
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</template>

<script>
export default {
  name: "pConfirmDeleteDialog",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      required: false,
      default: () => {return {}}
    },
    record_type: {
      type: String,
      required: true
    },
    record_description_field: {
      type: String,
      required: true
    }
  },
  computed: {
    Record: {
      get() { return this.record },
      set(v) { this.$emit('update:record', v)}
    },
    description() {
      if(this.record !== null) {
        return this.record[this.record_description_field]
      } else {
        return ""
      }
    },
    cancellabel(){
      return this.$t('gen.cancel')
    },
    confirmlabel(){
      return this.$t('gen.confirm')
    },
    deletelabel(){
      return this.$t('gen.delete')
    }
  },
  methods: {
    close() {
      this.Record = {}
      this.$emit('input', false)
    },
    deleteData() {
      this.$emit('remove', {type: this.record_type, data: this.Record})
      this.$emit("input", false)
    }
  }
}
</script>
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c('p-card-title',{attrs:{"title":_vm.title,"icon":_vm.icon}}),_c(VDivider),_c(VCardText,[_c(_vm.inputComponent,{ref:"formComponent",tag:"component",attrs:{"valid":_vm.validForm},on:{"update:valid":function($event){_vm.validForm=$event}},model:{value:(_vm.Record),callback:function ($$v) {_vm.Record=$$v},expression:"Record"}})],1),_c(VCardActions,[_c(VSpacer),_c('p-action-button',{attrs:{"color":"error","dark":"","icon":"mdi-close","label":_vm.cancelLabel},on:{"click":_vm.discardChanges}}),_c('p-action-button',{attrs:{"color":"success","dark":_vm.validForm,"btnClass":"ml-3","disabled":!_vm.validForm,"icon":"mdi-check","label":_vm.saveLabel},on:{"click":_vm.saveChanges}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
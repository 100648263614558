import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VWindowItem,{attrs:{"value":"attest"}},[_c(VCardText,{style:({'min-height': '300px', 'font-size': _vm.fontSize + 'em', 'line-height': _vm.lineHeight + 'em', 'text-align': _vm.$vuetify.breakpoint.mobile ? 'left' : 'justify'})},[_c('p',[_vm._v(_vm._s(_vm.$t('consent.attest.agree')))]),_c('ul',{staticClass:"ml-5 mb-12"},_vm._l((_vm.attestations),function(a,i){return _c('li',{key:i,domProps:{"innerHTML":_vm._s(a)}})}),0),_c(VRow,{attrs:{"justify":"center"}},[_c(VSpacer),_c(VBtn,{staticClass:"mb-5",attrs:{"color":"accent","large":"","id":"reviewConsentBtn"},on:{"click":function($event){return _vm.$emit('show-consent')}}},[_vm._v(" "+_vm._s(_vm.$t('consent.attest.review'))+" ")]),_c(VSpacer)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('p',[_vm._v(_vm._s(_vm.$t('consent.attest.select-choice')))]),_c(VRadioGroup,{model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c(VRadio,{attrs:{"value":"consented","id":"consentConsented"}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.choices.consent)}})])],2),_c(VRadio,{attrs:{"value":"declined","id":"consentDeclined"}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.choices.decline)}})])],2),_c(VRadio,{attrs:{"value":"questions","id":"consentQuestions"}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.choices.questions)}})])],2)],1),_c(VBtn,{attrs:{"block":"","color":"accent","disabled":_vm.submitDisabled,"id":"submitBtn"},on:{"click":function($event){return _vm.$emit('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('gen.submit'))+" ")])],1),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <!-- <a :href="!!downloadLink ? downloadLink : null" :target="!!downloadLink ? '_blank' : null" style="text-decoration: none" @click="!!downloadLink ? null : $emit('click')"> -->
    <a :href="downloadLink" :target="!!downloadLink ? newTab ? '_blank' : null : null" style="text-decoration: none" @click="$emit('click')">
      <v-list two-line style="cursor: pointer;" class="pa-0">
        <v-list-item v-ripple="{ center: true }">
          <v-list-item-avatar>
            <v-icon class="primary lighten-1 white--text" v-text="leftIcon"></v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="title"></v-list-item-title>
            <v-list-item-subtitle v-html="subtitle"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon v-text="rightIcon"></v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </a>
  </v-card>
</template>

<script>
export default {
  name: "downloadTile",
  props: {
    downloadLink: {
      type: String,
      required: false
    },
    newTab: {
      type: Boolean,
      default: true
    },
    leftIcon: {
      type: String,
      default: "mdi-file-pdf-outline"
    },
    rightIcon: {
      type: String,
      default: "mdi-download"
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    }
  }
}
</script>
<template>
  <p-info-card
    :title="title"
    :subtitle="subtitle"
    icon="fa-id-card"
    :loading="loading"
    id="generalInfo"
    :showTitle="showTitle"
    :flat="flat"
  >
    <template v-slot:default>
      <general-info-form
        ref="form"
        v-model="generalInfoUpdated"
        :valid.sync="valid"
        :editing="editing"
      ></general-info-form>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <p-action-button
        v-if="editing==false"
        color = "secondary"
        :label = "editlabel + ' ' + title"
        icon="mdi-pencil"
        btnClass="editBtn"
        id="generalEditBtn"
        @click="startEditing"
      ></p-action-button>
      <p-action-button 
        v-if="editing==true" 
        color = "success" 
        btnClass="ml-3"
        icon = "mdi-check"
        @click="saveChanges" 
        :label="savelabel"
        id="saveGeneralInfo"
      ></p-action-button>      
      <p-action-button
        v-if="editing==true" 
        color = "error" 
        icon = "mdi-close"
        @click="discardChanges"
        :label="cancellabel"
        id="cancelGeneralInfo"
      ></p-action-button>

      <v-spacer v-if="$vuetify.breakpoint.mdAndDown"></v-spacer>    
    </template>
  </p-info-card>
</template>

<script>
  
  import Vue from "vue"

  export default {
    name: "generalInfoPanel",
    data() {
      return {
        editing: !this.editGuard,
        valid: true,
        loaded: false,
        generalInfoUpdated: {},
      } 
    },
    props: {
      value: {
        type: Object
      },
      loading: {
        type: Boolean,
        default: false
      },
      showTitle: {
        type: Boolean,
        default: true
      },
      flat: {
        type: Boolean,
        default: false
      },
      editGuard: {
        type: Boolean,
        default: true
      }
    },
    mounted() {
      if(this.$vuetify.breakpoint.mobile) {
        this.editing = true
      }
      this.cloneInfo(this.generalInfo)
    },
    computed: {
      generalInfo() {
        return this.value
      },
      title(){
        return this.$t('participant.generalinfo.title')
      },
      subtitle() { 
        return this.$t('participant.generalinfo.subtitle')
      },
      editinfolabel(){
        return this.$t('participant.generalinfo.editlabel')
      },
      editlabel(){
        return this.$t('gen.edit')
      },
      savelabel(){
        return this.$t('gen.save')
      },
      cancellabel(){
        return this.$t('gen.cancel')
      }
    },
    methods: {
      cloneInfo(v) {
        this.generalInfoUpdated = Object.assign({}, v)
      },
      startEditing() {
        this.editing=true
      },
      discardChanges() {
        this.$refs.form.$refs.form.resetValidation()
        this.generalInfoUpdated = this.generalInfo
        if(this.editGuard) this.editing = false
      },
      saveChanges() {
        this.$refs.form.validate()
        Vue.nextTick(() => {
          if(this.$refs.form.valid_form) {
            this.$emit('input', this.generalInfoUpdated)
            if(this.editGuard) this.editing = false
          }            
        })
      }
    },
    watch: {
      editing(v) {
        this.$emit("editing", v)
      },
      generalInfo: {
        handler(v) {
          this.cloneInfo(v)
        },
        deep: true
      }
    }
  }
</script>

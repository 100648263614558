<template>
  <v-window-item value = "conclusion">
    <v-card-text id="consentConclusionCard" :style="{'min-height': '300px','font-size': fontSize + 'em', 'line-height': lineHeight + 'em', 'text-align': 'justify'}">
      <span v-if="genetic" v-html="$t('consent.conclusion.consented_gt')"></span>
      <span v-else v-html="message[status]"></span><br />
      <div class="d-flex mt-10" v-if="(status ==='consented') || (status ==='consented_no_gt')">
        <v-spacer></v-spacer>
        <download-tile
          @click = "showSignedConsent"
          rightIcon="mdi-file-outline"
          leftIcon="mdi-download"
          :title="title"
          :subtitle="subtitle"
        ></download-tile>
        <v-spacer></v-spacer>
      </div>
    </v-card-text>
    <p-full-consent
      v-model="signedConsentDialog"
      :consent_body="consent_body"
      :full_name="full_name"
      :date="date"
      :signature="signature"
    >
    </p-full-consent>
  </v-window-item>
</template>

<script>
  export default {
    name: "consentConclusion",
    props: {
      fontSize: {
        type: Number,
        required: true
      },
      lineHeight: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        signedConsentDialog: false,
        signed_consent_html: ""
      }
    },
    computed: {
      status() {
        let status = this.$store.getters['consent/status']
        return status
      },
      full_name() {
        return this.$store.getters['consent/full_name']
      },
      date() {
        return this.$store.getters['consent/sign_date']
      },
      signature() {
        return this.$store.getters['consent/signature']
      },
      consent_body() {
        return this.$store.getters['consent/consent_body']
      },
      message() {
        return {
          consented: this.$t('consent.conclusion.consented'),
          consented_no_gt: this.$t('consent.conclusion.consented_no_gt'),
          declined: this.$t('consent.conclusion.declined'),
          questions: this.$t('consent.conclusion.questions'),
          error: this.$t('consent.conclusion.error')
        }
      },
      genetic() {
        let guid = this.$store.getters['consent/qnn_guid']
        return guid === "7687247a-d82b-4921-879c-3479f7bd8ea7"
      },
      title() {
        return this.$t('consent.conclusion.title')
      },
      subtitle() {
        return this.$t('consent.conclusion.subtitle')
      }
    },
    methods: {
      returnHome() {
        this.$router.push({name: "home", params: {complete: true, task: this.$store.state.route.query.task}})   
      },
      async showSignedConsent() {
        let html = await this.$store.dispatch("consent/getHTMLConsent")
        this.signed_consent_html = html
        this.signedConsentDialog = true
      }
    }
  }
</script>
import html2canvas from "html2canvas"

const state = {
  name: "",
  email: "",
  description: "",
  canvas: null,
  image: "",
  data: {}
}

const mutations = {}
const getters = {}

Object.keys(state).forEach((key) => {
  getters[key] = (state) => { return state[key] }
  mutations[key] = (state, payload) => { state[key] = payload }
})

getters.clientData = () => {

  let {appCodeName, appName, appVersion, language, ospcu, platform, product, userAgent, vendor} = window.navigator
  let width = window.innerWidth
  let height = window.innerHeight  
  let client = {appCodeName, appName, appVersion, language, ospcu, platform, product, userAgent, vendor, width, height}
   
  return client

}

getters.loggingPayload = (state, getters) => {

    let payload = {
      name: getters.name,
      email: getters.email,
      description: getters.description,
      data: getters.debugData,
      screenshot: getters.image
    }

    return payload

}

getters.debugData = (state, getters, rootState) => {
  
  let {questionnaire, consent, upsit, participant, tasks, route} = rootState 
  let client = getters.clientData
  return {participant, route, tasks, upsit, consent, questionnaire, client}

}

const actions = {
  async takeScreenshot({state, commit}, payload = {}) {
    commit("canvas", await html2canvas(payload.target || document.body))
    commit("image", await state.canvas.toDataURL())
  },
  sendReport({getters}) {
    let payload = getters.loggingPayload
    console.log(payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
import Vue from 'vue'

import cardTitle from "./ppmi-portal-components/src/components/cardTitle.vue"
import actionButton from "./ppmi-portal-components/src/components/actionButton.vue"
import infoCard from "./ppmi-portal-components/src/components/infoCard.vue"
import privacyNotice from "./ppmi-portal-components/src/components/privacyNotice.vue"
import disclaimer from "./ppmi-portal-components/src/components/disclaimer.vue"
import expClock from    "./ppmi-portal-components/src/components/expClock.vue"
import shippingMap from './ppmi-portal-components/src/components/shippingMap.vue'
import participantReset from "./ppmi-portal-components/src/components/participantReset.vue"


let components = [
  cardTitle,
  actionButton,
  infoCard,
  privacyNotice,
  disclaimer,
  expClock,
  shippingMap,
  participantReset
]

components.forEach(x => Vue.component(x.name, x))

<template>
  <span>Token Expires In: {{countdown}}</span>
</template>

<script>
  export default {
    name: "pExpClock",
    data() {
      return {
        now: Date.now()/1000
      }
    },
    computed: {
      countdown() {
        let exp = this.$store.getters['auth/access_payload'].exp - this.$store.getters['auth/expSafetyWindow']
        let now = this.now
        let dif = exp - now
        let hrs = ('0' + Math.floor(dif / 3600).toString()).slice(-2)
        let min = ('0' + Math.floor((dif % 3600) / 60 ).toString()).slice(-2)
        let sec = ('0' + Math.floor((dif % 3600) % 60 ).toString()).slice(-2)
        if(dif > 0) {
          return `${hrs}:${min}:${sec}`
        } else {
          return "00:00:00"
        }
      }
    },
    mounted() {
      window.setInterval(() => {
        this.now = Date.now() / 1000
      }, 1000)
    }
  }
</script>
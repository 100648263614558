<template>
    <v-dialog v-model="value" scrollable max-width="1000px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card id="fullConsentCard">
        <p-card-title
          :title="title"
          icon="fa-signature"
        >
          <template v-slot:action>
            <v-btn
              icon
              @click="printConsent"
            ><v-icon>fa-print</v-icon></v-btn>
          </template>
        </p-card-title>
        <v-divider></v-divider>
        <v-card-text id='consent' class = "pt-6" height = "80%"> 
          <span class = "full-consent mb-5" v-html="consent_body"></span>
          <!-- <table width = "100%" style="margin-bottom: 30px; cell-spacing: 20px;">
            <tr>
              <td style="font-size: 1.5em; font-weight: bold; border-bottom: 1px solid black; width: 85%; vertical-align: bottom;" v-text="full_name" :style = "{height: full_name ? '50px' : '30px'}"></td>
              <td style="font-size: 1.5em; font-weight: bold; border-bottom: 1px solid black; text-align: center; vertical-align: bottom;" v-text="date" :style = "{height: full_name ? '50px' : '30px'}"></td>
            </tr>
            <tr>
              <td style="font-weight: bold; text-align: left;">{{ $t('gen.fullname') }}</td>
              <td style="font-weight: bold; text-align: left; text-align: center;">{{ $t('gen.date') }}</td>
            </tr>
            <tr>
              <td colspan=2 style="font-weight: normal; border-bottom: 1px solid black; width: 85%">
                <img :src="signature" :style = "{height: signature ? '100px' : '50px'}" />
              </td>
            </tr>
            <tr>
              <td colspan=2 style="font-weight: bold; text-align: left;">{{ $t('gen.signature') }}</td>
            </tr>
          </table> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="px-8" 
            color="accent" 
            rounded  
            @click="closeDialog"
            id="fullConsentCloseBtn"
          >{{ $t('gen.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
  name: "pFullConsent",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    consent_body: {
      required: true
    },
    full_name: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    signature: {
      type: String,
      required: false
    }
  },
  computed: {
    visible: {
      get() {return this.value},
      set(v) {this.$emit('input', v)}
    },
    title() {
      return this.$t('consent.fullConsent.fullconsenttitle')
    },
    attestAgree() {
      return this.$t('consent.attest.agree')
    },
    attestations() {
      return this.$store.getters['consent/attestations']
    },
    choices() {
      return this.$store.getters['consent/choices']
    },
    attestationList() {
      let htmlString = `<p>${this.attestAgree}</p><ul>`
      for(let i=0; i<this.attestations.length; i++) {
        htmlString += `<li>${this.attestations[i]}</li>`
        }
      htmlString += "</ul>"
      let selection = `<input type="radio" id="consented" value="consented" checked />
                      <label for="consented">${this.choices.consent}</label>`
      htmlString += selection
      return htmlString
      }
  },
  methods: {
    printConsent() {
      // Get HTML to print from element
      let prtHtml = document.querySelector('.full-consent').outerHTML;

      let signature
      if(this.signature) {
        signature = `<img src="${this.signature}" style = "height: 100px" />`
      } else {
        signature = "<span></span>"
      }

      const WinPrint = window.open('', '', 'left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <style>
            body {
              font-family: sans-serif;
            }

            table td:first-child{
              font-weight: bold;
              font-size: 1.2em;
              padding-right: 5px;
              text-align: right;
            }

            table td:last-child {
              font-size: 1.2em;
            }
            table.header {
              margin-bottom: 20px;
            }
            ol {
              font-weight: bold;
            }
            ol ul {
              font-weight: normal !important;
            }
            ol p {
              font-weight: normal !important;
            }
            p {
              margin-top: 20px;
              margin-bottom: 20px;
            }
            h2 {
              margin-bottom: 20px;
              font-size: 1.2em;
            }
            ul, ol {
              margin-bottom: 20px;
            }
          </style>
        </head>
        <body style="padding: 20px;>
          ${prtHtml}
          ${this.attestationList}
          <p></p>
          <table width = "100%" style="margin-bottom: 30px; cell-spacing: 20px;">
            <tr>
              <td style="text-align: left; height: 50px; font-size: 1.5em; font-weight: bold; border-bottom: 1px solid black; width: 85%; vertical-align: bottom;">${this.full_name}</td>
              <td style="min-height: 50px; font-size: 1.5em; font-weight: bold; border-bottom: 1px solid black; text-align: center; vertical-align: bottom;">${this.date}</td>
            </tr>
            <tr>
              <td style="font-size: 0.9em; font-weight: bold; text-align: left;">Full Name</td>
              <td style="font-size: 0.9em; font-weight: bold; text-align: left; text-align: center;">Date</td>
            </tr>
            <tr>
              <td colspan=2 style="text-align: left; height: 100px; font-weight: normal; border-bottom: 1px solid black; width: 85%">
                ${signature}
              </td>
            </tr>
            <tr>
              <td colspan=2 style="font-size: 0.9em; font-weight: bold; text-align: left;">Signature</td>
            </tr>
          </table>
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();

    },
    closeDialog() {
      this.$emit("close")
      this.visible = false
    }
  }
}
</script>

<style>
  .full-consent {
    color: rgba(0,0,0,0.9) !important;
  }
  .full-consent table td:first-child{
    font-weight: bold;
    font-size: 1.2em;
    padding-right: 5px;
    text-align: right;
  }
  .full-consent table td:last-child {
    font-size: 1.2em;
  }
  .full-consent table.header {
    margin-bottom: 20px
  }
  .full-consent ol {
    font-weight: bold;
  }
  .full-consent ol ul {
    font-weight: normal !important;
  }
  .full.consent ol p {
    font-weight: normal !important;
  }
  .full-consent p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .full-consent h2 {
    margin-bottom: 20px;
    font-size: 1.2em;
  }
  .full-consent ul, .full-consent ol {
    margin-bottom: 20px;
  }
</style>
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VForm,{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.phonelabel,"rules":_vm.rules.line1,"dense":_vm.dense},model:{value:(_vm.record.number),callback:function ($$v) {_vm.$set(_vm.record, "number", $$v)},expression:"record.number"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4","lg":"4"}},[_c(VSelect,{attrs:{"items":_vm.types,"item-text":"translation","item-value":"value","label":_vm.typelabel,"rules":_vm.rules.type,"dense":_vm.dense},model:{value:(_vm.record.type),callback:function ($$v) {_vm.$set(_vm.record, "type", $$v)},expression:"record.type"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4","lg":"4"}},[_c(VSelect,{attrs:{"items":_vm.bools,"label":_vm.textlabel,"rules":_vm.rules.allow_text,"dense":_vm.dense},model:{value:(_vm.record.allow_text),callback:function ($$v) {_vm.$set(_vm.record, "allow_text", $$v)},expression:"record.allow_text"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4","lg":"4"}},[_c(VSelect,{attrs:{"items":_vm.bools,"label":_vm.voicelabel,"rules":_vm.rules.allow_vm,"dense":_vm.dense},model:{value:(_vm.record.allow_vm),callback:function ($$v) {_vm.$set(_vm.record, "allow_vm", $$v)},expression:"record.allow_vm"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
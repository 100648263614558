<template>
  <div class = "d-flex flex-no-wrap justify-space-between">
    <div>
      <v-card-title 
        v-html="page.header"
        class="pl-10 text-h4"
      ></v-card-title>
      <v-card-text
        class="px-10"
        v-html="page.body" 
        :style="{'min-height': '300px', 'font-size': fontSize + 'em', 'line-height': lineHeight + 'em', 'text-align': $vuetify.breakpoint.mobile ? 'left' : 'justify'}"
      > 
      </v-card-text>
    </div>
    <v-avatar tile size=300 class="ma-3">
      <v-img :src="page.image"></v-img>
    </v-avatar>  
  </div>
</template>

<script>
  export default {
    name: "pageImageRight",
    props: {
      page: {
        type: Object,
        required: true
      },
      fontSize: {
        type: Number,
        required: true
      },
      lineHeight: {
        type: Number,
        required: true
      }
    }
  }
</script>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VWindowItem,{attrs:{"value":"intro"}},[_c(VCardText,[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('upsit.introtext1'))}}),_c(VForm,{model:{value:(_vm.ready),callback:function ($$v) {_vm.ready=$$v},expression:"ready"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.kit_no_mask),expression:"kit_no_mask"}],attrs:{"append-icon":_vm.kit_no_icon,"rules":[function (v) { return v.length == 7; }],"counter":"7","outlined":"","label":"UPSIT ID","hint":_vm.kit_no_hint,"color":_vm.kit_no_color,"disabled":_vm.loading},model:{value:(_vm.prt_kit_no),callback:function ($$v) {_vm.prt_kit_no=$$v},expression:"prt_kit_no"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('p-upsit-date-picker',{attrs:{"outlined":"","dense":false,"label":_vm.completionLabel,"rules":[function (v) { return !!v || 'Required'; }],"maxDate":_vm.today,"minDate":_vm.deliver_date,"disabled":_vm.loading},model:{value:(_vm.completion_date),callback:function ($$v) {_vm.completion_date=$$v},expression:"completion_date"}})],1)],1)],1),(_vm.prt_kit_no.length === 7)?[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('upsit.introtext2'))}})]:_vm._e()],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"px-8",attrs:{"disabled":!_vm.readyToContinue,"color":"accent","rounded":""},on:{"click":_vm.submitKitNo}},[_vm._v(_vm._s(_vm.$t('gen.continue')))])],1),_c(VImg,{style:({position: 'fixed', top: _vm.mousey, left: _vm.mousex, display: _vm.showUPSITID ? 'block' : 'none'}),attrs:{"src":require('@/assets/upsit_id.jpg'),"max-width":"600"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-tooltip bottom>
      <template v-slot:activator="{on, attrs}">
        <v-text-field 
          v-model="date"
          type="date"
          :dense="dense"
          :label="label"
          v-on="on"
          v-bind="attrs"
          validate-on-blur
          :outlined="outlined"
          :rules="[required]"
        ></v-text-field>
      </template>
    </v-tooltip>
  </template>
  
  <script>
    export default {
      name: "pUpsitDatePicker",
      props: {
        label: {
          type: String,
          default: "Completion Date"
        },
        value: {
          required: false,
          default: ""
        },
        editing: {
          type: Boolean,
          required: false,
          default: true
        },
        outlined: {
          type: Boolean,
          required: false,
          default: false
        },
        dense: {
          type: Boolean,
          required: false,
          default: true
        },
        maxDate: {
          type: String,
          default: new Date(new Date().setFullYear(new Date().getFullYear() - 17)).toISOString().substr(0,10)
        },
        minDate: {
          type: String,
          default: "1900-01-01"
        },
        disabled: {
          type: Boolean,
          default: false
        },
        validateOnBlur: {
          type: Boolean,
          default: false
        }
      },
      data: () => ({
        required: function(v) {
          return !!v || "This field is required"
        },
      }),
      computed: {
        
        date: {
          get() {
            if(this.value) {
              return this.value.substr(0,10)
            } else {
              return ""
            }
          },
          set(v) {
            this.$emit("input", v)
          },
        },

      },
      
    }
  </script>
  
  <style scoped>
    .not-editable {
      pointer-events: none;
    }
  </style>
// Vuex module
import store from './store.js'

// Main questionnaire Component
import consent from "./Consent.vue"

// Support Components
import pageLeft from "./components/pageLeft.vue"
import pageRight from "./components/pageRight.vue"
import pageNone from "./components/pageNone.vue"
import conclusion from "./components/Conclusion.vue"
import attestation from "./components/attestationPage.vue"
import bottomSheet from "./components/bottomSheet.vue"
import consentDialog from "./components/consentDialog.vue"
import declineDialog from "./components/declineDialog.vue"
import questionDialog from "./components/questionDialog.vue"
import downloadTile from "./components/downloadTile.vue"
import fullConsent from "./components/fullConsent.vue"

const consentPlugin = {
  install(Vue, options) {
    let components = [pageLeft, pageRight, pageNone, fullConsent, attestation, conclusion, bottomSheet, consentDialog, declineDialog, questionDialog, downloadTile]

    Vue.component(consent.name, consent)
    components.forEach(component => Vue.component(component.name, component))

    options.registerModule("consent", store)
  }
}

export default consentPlugin
<template>
  <v-window-item :value = "question.id" :disabled="skipped">
    <v-card-text>
      <v-card flat>
        <v-card-title v-html="`${question.label}. ${question.text}`"></v-card-title>
        <v-card-text v-html="question.additional_info" v-if="question.additional_info"></v-card-text>
        <v-form class="ml-12">
          <component 
            :is="questionType"
            ref="question"
            :disabled="disabled"
            :question="question"
            @answer="$emit('answer')"
          ></component>
        </v-form>
      </v-card>
    </v-card-text>
  </v-window-item>
</template>

<script>

  import camelCase from 'lodash/camelCase'

  export default {
    name: "questionnaireQuestion",
    props: {
      question: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
      }
    },
    computed: {
      questionType() {
        let t = camelCase("q" + this.question.type.toUpperCase())
        return t
      },
      skipped() {
        return this.$store.getters['questionnaire/skipped'](this.question.id)
      }
    },
    methods: {

    },
    watch: {

    },
  }

</script>

<style scoped>

  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }

</style>
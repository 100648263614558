import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBottomSheet,{staticClass:"pa-5",staticStyle:{"background-color":"white"},attrs:{"no-click-animation":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VSheet,{staticClass:"pa-8"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.$t('consent.attest.select-choice'))+" "),_c(VRadioGroup,{model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c(VRadio,{attrs:{"value":"consented"}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('consent.attest.consent'))}})])],2),_c(VRadio,{attrs:{"value":"declined"}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('consent.attest.decline'))}})])],2),_c(VRadio,{attrs:{"value":"questions"}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('consent.attest.questions'))}})])],2)],1),_c(VBtn,{attrs:{"block":"","color":"accent","disabled":_vm.status===null},on:{"click":function($event){return _vm.$emit('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('gen.submit'))+" ")])],1),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
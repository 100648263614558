<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width='1000px' scrollable>
      <p-info-card
        :title="titlelabel"
        titleIcon="fa-lock"
        subtitle="Effective: 2020-10-23"
        id="privacyNotice"
      >
      <div v-html="$t('privacy.applicability', {phone: this.phone, email: this.email})">
      </div>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn @click="show=false" id="closePrivacyBtn">{{ $t('gen.close') }}</v-btn>
        </template>
      </p-info-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    name: "pPrivacyNotice",
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      show: {
        get() { return this.value },
        set(v) { this.$emit("input", v)}
      },
      phone() { return process.env.VUE_APP_PHONE_NUMBER },
      email() { return process.env.VUE_APP_EMAIL_ADDRESS },
      titlelabel() {
        return this.$t('gen.privacy')
      }
    }
  }

</script>
<template>
  <span>
  <v-form ref="form" v-model="validForm">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="record.line1"
            :label="address1label"
            :rules="rules.line1"
            :dense="dense"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="record.line2"
            :label="address2label"
            :rules="rules.line2"
            :dense="dense"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            v-model="record.city"
            :label="citylabel"
            :rules="rules.city"
            :dense="dense"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <v-select
            v-model="record.country"
            :items="countries"
            item-text='translation'
            item-value='value'
            @change="updateStateProv"
            :label="countrylabel"
            :rules="rules.country"
            :dense="dense"
          ></v-select>          
        </v-col>
        <v-col cols="6" md="2" lg="2">
          <v-select
            v-model="record.state_prov"
            :items="curCountry === 'USA' ? states : provs"
            item-text='translation'
            item-value='value'
            :label="curCountry === 'USA' ? 'State' : 'Province'"
            :rules="rules.state_prov"
            :dense="dense"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2" lg="2">
          <v-text-field
            v-model="record.zipcode"
            :label="ziplabel"
            :rules="rules.zipcode"
            :dense="dense"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  </span>
</template>

<script>

  import { formMixin } from "./mixin.js"

  export default {
    name: "pAddressInput",
    mixins: [formMixin],
    updated() {
      this.curCountry = this.value.country
    },
    data: function() {
      let not_empty = (v) => {
        return !!v || this.$t('gen.fieldreq')
      }
      return {
        curCountry: null,
        // countries: ["USA", "Canada"],
        rules: {
          line1: [not_empty],
          line2: [],
          city: [not_empty],
          country: [not_empty],
          state_prov: [not_empty],
          zipcode: [not_empty]
        }
      }
    },
    computed: {
      countries() {
        return this.$store.getters['options/address_country']
      },
      states() {
        return this.$store.getters['options/state']
      },
      provs() {
        return this.$store.getters['options/province']
      },
      address1label(){
        return this.$t('participant.addressform.address1')
      },
      address2label(){
        return this.$t('participant.addressform.address2')
      },
      countrylabel(){
        return this.$t('participant.addressform.country')
      },
      citylabel(){
        return this.$t('participant.addressform.city')
      },
      ziplabel(){
        return this.$t('participant.addressform.zip')
      }
    },
    methods: {
      updateStateProv(v) {
        this.curCountry = v
      },
    },
  }

</script>

<style>

</style>
<template>
  <span>
    <v-window v-model="pageNo" touchless ref="bookletWindow">
      <upsit-booklet-cover 
        :img="book.image" 
        :bookNo="bookNo" 
        @ready="pageIndex++"
      ></upsit-booklet-cover>
      <upsit-booklet-page 
        v-for="(page, index) in book.pages"
        :key = "index"
        :bookNo="bookNo"
        :pageNo="page.pageNo"
      ></upsit-booklet-page>
      <upsit-booklet-review
        :bookNo="bookNo"
      ></upsit-booklet-review>
    </v-window>
    <v-card-actions class = "mb-4 mr-4">
      <v-spacer></v-spacer>
      <v-btn 
        ref="forwardButton"
        :disabled="disableForward || loading" 
        class="px-8" 
        color="accent" 
        rounded 
        @click="nextPage"
      >{{forwardButtonLabel}}</v-btn>
    </v-card-actions>     
  </span>
</template>

<script>

  export default {
    name: "upsitBooklet",
    props: {
      bookNo: {
        type: Number,
        required: true
      }
    },
    data: function() {
      return {
        loading: false
      }
    },
    computed: {
      disableForward() {
        if(this.$store.getters['upsit/bookNo'] !== this.bookNo) {
          return true
        } else {
          switch(this.pageNo) {
            case 'cover':
              return false
            case 'review':
              return false
            default:
              return ['a', 'b', 'c', 'd'].indexOf(this.$store.getters['upsit/answer'](this.bookNo, ((this.bookNo - 1) * 10) + (this.pageNo))) == -1
          }   
        }     
      },
      book() {
        let x = this.$store.getters['upsit/book'](this.bookNo)
        return x
      },
      pageNo: {
        get() {
          return this.$store.getters['upsit/pageNo']
        },
        set(x) {
          this.$store.commit("upsit/SET_PAGENO", x)
        }
      },
      forwardButtonLabel: function() {
        let label
        switch(this.pageNo) {
          case 'cover':
            label = this.$t('gen.continue')
            break
          case 'review':
            label = this.$t('gen.submit')
            break
          default:
            label = this.$t('gen.next')
        }
        return label
      },
    },
    methods: {
      nextPage: async function() {
        if(this.pageNo == "review") {
          try {
            this.loading = true
            await this.$store.dispatch("upsit/submitBooklet", this.bookNo)
            this.$emit("complete", this.bookNo)
            this.loading = false
          } catch(err) {
            this.$store.dispatch("snackbar/display", {message: this.$t('upsit.submissionerror'), color: 'error'})
            this.$router.push({name: "home"})
          }
        } else {
          this.$refs.bookletWindow.next()
        }
      }
    }
  }

</script>
import axios from 'axios';
import qs from 'querystring'
import Vue from 'vue'

const instance = axios.create({
    baseURL: process.env.VUE_APP_AUTH_DOMAIN
})

function makeURL(url) {
  if(Array.isArray(url)) {
    url = url.join("/");
  }
  return url;
}
 
export default {
  instance: instance,
  get: function(url, payload) {
    url = makeURL(url);
    Vue.$log.debug("GET " + url + " payload:", payload)
    return instance.get(url, {params: payload});    
  },
  post: function(url, payload, encoding = 'json') {
    url = makeURL(url);
    let config = {}
    if(encoding === "form") {
      payload = qs.stringify(payload)
      config.headers = {'Content-Type': 'application/x-www-form-urlencoded'}
    }
    Vue.$log.debug("POST " + url + " payload:", payload)
    return instance.post(url, payload, config)
  },
  put: function(url, payload) {
    url = makeURL(url);
    Vue.$log.debug("PUT " + url + " payload:", payload)
    return instance.put(url, payload);
  },
  delete: function(url, payload) {
    url = makeURL(url);
    Vue.$log.debug("DELETE " + url + " payload:", payload)
    return instance.delete(url, payload);
  }
}
<template>
  <v-text-field
    :rules="validationRules"
    v-model="answer"
    ref="question"
    :disabled="disabled"
    @keypress.enter="nextPage"
  ></v-text-field>
</template>

<script>

import { questionMixin } from "../mixins.js"

export default {
  name: "qText",
  mixins: [questionMixin],
  data: function() {
    return {
      default_rules: ['required']
    }
  },
  computed: {

  },
  methods: {
    nextPage(ev) {
      ev.preventDefault()
    }
  },
  watch: {
    
  }
}
</script>
<template>
  <v-snackbar v-model="snackbarVisible" 
    :color='color'
    :timeout='timeout'
    top
    style="margin-top: 75px;"
  >
    <v-container class='pr-0'>
      <v-row no-gutters>
        <v-col class="flex-grow-1 flex-shrink-0" style="margin: auto 0;" v-html="snackbarMessage"></v-col>
        <v-col class="flex-grow-0 flex-shink-1 ml-5">    
          <v-btn
            text
            @click="closeSnackbar()"
          >
            {{  $t('gen.close') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-snackbar>
</template>

<script>
  export default {
    name: "snackbarAlerts",
    methods: {
      closeSnackbar: function() {
        this.$store.dispatch('snackbar/close');
      }
    },
    computed: {
      snackbarMessage() {return this.$store.getters["snackbar/message"]},
      color() {return this.$store.getters["snackbar/color"]},
      timeout() {return this.$store.getters['snackbar/timeout']},
      snackbarVisible: {
        get() {return this.$store.getters["snackbar/visible"]},
        set(v) {this.$store.commit("snackbar/SET_VISIBLE", v)}
      }
    }
  }

</script>
